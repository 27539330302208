import React, { useState } from "react";
import { Row, Col, Modal, ModalBody, Tooltip } from "reactstrap";

const LinkedinInfoModal = ({
  setProspectInfoToggle,
  prospectInfoModel,
  openProspectInfo,
}) => {
  console.log(openProspectInfo)
  const [copyTooltip, setCopyTooltip] = useState({
    emailInfo: false,
    contactInfo: false,
  });
  const [copyTooltipText, setCopyTooltipText] = useState("Click to Copy");
  const copyToClipboard = (str) => {
    const el = document.createElement("textarea");
    el.value = str;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };
  return (
    <Modal id="likedinprops"
      toggle={() => {
        setProspectInfoToggle({});
      }}
      isOpen={prospectInfoModel}
    >
      <div className=" modal-header" style={{ display: "inline-flex !important" }}>
        {openProspectInfo.imageurl ? (
          <img style={{ maxWidth: 100 }} src={openProspectInfo.imageurl} alt="" />
        ) : (
          <img style={{ maxWidth: 100 }}
            src={require("assets/img/placeholder.jpg")}
            alt=""
          />
        )}
        <h5 className=" modal-title" id="exampleModalLabel" style={{ padding: 35 }}>
          {openProspectInfo.title}        </h5>

        <span
          aria-label="Close"
          className=" close"
          type="button"
          onClick={() => {
            setProspectInfoToggle({});
          }}
        >
          <span aria-hidden={true}>×</span>
        </span>
      </div>
      <ModalBody style={{ maxWidth: 800 }}>

        <Row>
          <Col xs={4}>
            <b>Email</b>
          </Col>
          <Col xs={8}>
            <span>{openProspectInfo.email}</span> &nbsp;&nbsp;&nbsp;
              <i
              id="emailInfo"
              className="nc-icon nc-single-copy-04"
              onClick={() => {
                copyToClipboard(openProspectInfo.email);
                setCopyTooltipText("copied!");
              }}
              onMouseLeave={() => {
                setCopyTooltipText("Click to Copy");
                setCopyTooltip({
                  ...copyTooltip,
                  emailInfo: false,
                });
              }}
              onMouseEnter={() => {
                setCopyTooltip({
                  ...copyTooltip,
                  emailInfo: true,
                });
              }}
            ></i>
            <Tooltip
              placement="top"
              delay={1}
              target="emailInfo"
              isOpen={copyTooltip.emailInfo}
              autohide={false}
            >
              {copyTooltipText}
            </Tooltip>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <b>HeadLine</b>
          </Col>
          <Col xs={8}>
            <span>{openProspectInfo.headline}</span>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <b>Profile Link</b>
          </Col>
          <Col xs={8}>
            <span>{openProspectInfo.profileLink}</span>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <b>Last Record</b>
          </Col>
          <Col xs={8}>
            <span>{openProspectInfo.currentDay}</span>
          </Col>
        </Row>
        {openProspectInfo.currentVisits && (<Row>
          <Col xs={4}>
            <b>Visits</b>
          </Col>
          <Col xs={8}>
            <span>limit:  {openProspectInfo.settings.VISIT[1]}, done:{openProspectInfo.currentVisits[1]} </span>
          </Col>
        </Row>)}
        {openProspectInfo.currentVisits && (<Row>
          <Col xs={4}>
            <b>Requests</b>
          </Col>
          <Col xs={8}>
            <span>limit:  {openProspectInfo.settings.CONNECT[1]}, done:{openProspectInfo.currentRequests[1]} </span>
          </Col>
        </Row>)}
        {openProspectInfo.currentVisits && (<Row>
          <Col xs={4}>
            <b>Messages</b>
          </Col>
          <Col xs={8}>
            <span>limit:  {openProspectInfo.settings.MESSAGE[1]}, done:{openProspectInfo.currentMessage[1]} </span>
          </Col>
        </Row>)}
        <Row>
          <Col xs={4}>
            <b>Connections</b>
          </Col>
          <Col xs={8}>
            <span>{openProspectInfo.connections} </span>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <b>SalesNav?</b>
          </Col>
          <Col xs={8}>
            <span>{openProspectInfo.isSalesAccount ? "Yes" : "No"} </span>
          </Col>
        </Row>
        {openProspectInfo.settings && <Row>
          <Col xs={4}>
            <b>Timezone</b>
          </Col>
          <Col xs={8}>
            <span>{openProspectInfo.settings.timezone} </span>
          </Col>
        </Row>}
        {openProspectInfo.memberSince && <Row>
          <Col xs={4}>
            <b>Added At</b>
          </Col>
          <Col xs={8}>
            <span>{openProspectInfo.memberSince.substring(0, 10)} </span>
          </Col>
        </Row>}
        <Row>
          <Col xs={4}>
            <b>Subscription ID</b>
          </Col>
          <Col xs={8}>
            <span>{openProspectInfo.stripeSubscriptionID} </span>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <b>Campaigns</b>
          </Col>
          <Col xs={8}>
            <span>{openProspectInfo.campaignCounts} </span>
          </Col>
        </Row>
        {openProspectInfo.camps &&
          openProspectInfo.camps.map((step, index) => (
            <Row key={step.name + index}>
              <Col xs={4}>
                <b style={{ color: "green" }}>{step.name.split(" -> ")[1].replace(/\W/g, "-")}</b>
              </Col>
              <Col xs={8}>
                <p style={{ marginBottom: 0 }}>prospects :<b>{step.totalProspects}</b> </p>
                <p style={{ marginBottom: 0 }}>Running   :<b>{step.isRunning ? "YES" : "NO"}</b></p>
                <p style={{ marginBottom: 0 }}>Type      :<b>{step.type} </b></p>
                <p style={{ marginBottom: 0 }}>Created At:<b>{step.createdAt.substring(0, 10)}</b> </p>
                <p style={{ marginBottom: 0 }}>actions   :<b>{step.actions}</b> </p>
              </Col>
            </Row>
          ))
        }
      </ModalBody >
    </Modal >
  );
};

export default LinkedinInfoModal;

