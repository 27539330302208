import React, { useState, useEffect, useRef } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  Card,
  Input,
  Row,
  Col,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  ButtonGroup,
  InputGroup,
  InputGroupAddon,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
} from "reactstrap";

import ReactBSAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import { CSVLink } from "react-csv";
import styled from "styled-components";

import ProspectsContainer from "./ProspectsContainer";

import {
  excludeTarget,
  requestExport,
} from "../../../../actions/campaignActions";

import { actionToActivity } from "../../../../utils";
import { readMessage } from "../../../../actions/messageActions";
import { resetError } from "../../../../actions/errrorActions";
import CampaignProspectsMessagingModal from "../../../components/campaigns/ProspectMessagingModal/CampaignProspectsMessagingModal";
import CampaignSyncGooglesheetModal from "../../../components/campaigns/CampaignSyncGooglesheetModal";
import CampaignTags from "../../../components/CampaignTags";
import ProspectContactInfoModal from "../../../components/campaigns/ProspectContactInfoModal/prospectContactInfoModal";

const LoadingSpinner = (props) => {
  return (
    <StyledLoadingSpinner>
      <div className={`${props.asOverlay && "loading-spinner__overlay"}`}>
        <div className="lds-dual-ring"></div>
      </div>
    </StyledLoadingSpinner>
  );
};

// TODO: extarct custom spinner and overlay
const StyledLoadingSpinner = styled.div`
  .lds-dual-ring {
    display: inline-block;
    width: 64px;
    height: 64px;
  }

  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 60px;
    height: 60px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid #ffffff;
    border-color: #ffffff transparent #ffffff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  .loading-spinner__overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #2c2c2c;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const StyledTempOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #2c2c2c;
  opacity: 0.8;
  animation: load8 1.1s infinite linear;
  transition: opacity 0.3s;
  z-index: 100;

  .centered-text {
    position: absolute;
    top: 54%;
    left: 50%;
    z-index: 101;
    color: white;
    font-size: 20px;
    transform: translateX(-50%);
  }
`;

let syncType = "all";

const BlackListPage = (props) => {
  const {
    campaigns,
    liaccount,
    toggleTagsModal,
    toggleAssignModal,
    excludeTarget,
    requestExport,
    tag,
    message,
    readMessage,
    setSelectedCampaignId,
    resetError,
    sleepMode,
  } = props;

  const defaultTag = [
    "Replied",
    "Accepted",
    "Visited",
    "Requested",
    "Messaged",
  ];

  const [init, setInit] = useState(null);
  const [prospectInfoModel, setProspectInfoModel] = useState(false);
  const [openProspectInfo, setOpenProspectInfo] = useState({});

  const filteredTag = defaultTag.concat(tag.map((item) => item.title));
  const default_campaign = props.location.state?.campIdentifier;

  const default_replied = props.location.state?.replied;

  const location = useLocation();

  const identifier = location.pathname.split("/")[2];

  const csvRef = useRef(null);

  const [openFilterTag, setOpenFilterTag] = useState(false);
  const [openFilterCampaign, setOpenFilterCampaign] = useState(false);
  const [openSortDropdown, setOpenSortDropdown] = useState(false);
  const [openExport, setOpenExport] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");

  const [selectedTag, setSelectedTag] = useState([]);
  const [campaignsList, setCampaignsList] = useState(["All"]);
  const [filteredCampaignsList, setFilteredCampaignsList] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(
    setSelectedCampaignId === "" ? [] : [setSelectedCampaignId]
  );
  const [selectedStatus, setSelectedStatus] = useState([]);

  const [prospects, setProspects] = useState([]);
  const [filteredProspects, setFilteredProspects] = useState([]);

  const [targetToModal, setTargetToModal] = useState();
  const isMessagingModalOpened = true;

  const [exportSelect, setExportSelect] = useState(false);
  const [
    isSyncGooglesheetModalOpened,
    setIsSyncGooglesheetModalOpened,
  ] = useState(false);

  const [exportCampaign, setExportCampaign] = useState(null);

  const [alert, setAlert] = useState(null);

  const setProspectInfoToggle = (data) => {
    let contactInfo = {};
    try {
      contactInfo = JSON.parse(data.contactInfo);
    } catch (error) { }
    setOpenProspectInfo({
      title: data.name,
      ...data,
      ...contactInfo,
    });
    setProspectInfoModel(!prospectInfoModel);
  };

  const toggleOpenFilterTag = () => {
    setOpenFilterTag((prev) => !prev);
  };

  const toggleOpenFilterCampaign = () => {
    setOpenFilterCampaign((prev) => !prev);
  };

  const toggleOpenSortDropdown = () => {
    setOpenSortDropdown((prev) => !prev);
  };

  const toggleOpenExport = () => {
    setOpenExport((prev) => !prev);
  };

  const toggleExportSelect = () => {
    setExportSelect((prev) => !prev);
  };

  const toggleSyncGooglesheetModal = () => {
    setIsSyncGooglesheetModalOpened((prev) => !prev);
  };

  const checkRepliedState = (name) => {
    const { repliedData } = liaccount;
    let index = repliedData.findIndex((item) => {
      return `${item.firstName} ${item.lastName}` === name;
    });
    if (index > -1) return true;
    return false;
  };

  const getLongestMessagedData = (filtered) => {
    if (
      filtered.length === 2 &&
      ((filtered[0].isSales && !filtered[1].isSales) ||
        (!filtered[0].isSales && filtered[1].isSales))
    )
      return {
        ...filtered[1],
        messages: [...filtered[0].messages, ...filtered[1].messages],
      };
    return filtered.sort((a, b) => b.messages.length - a.messages.length)[0]
      .messages.length > 1
      ? filtered[0]
      : null;
  };

  const checkMessageDataAvailable = (name) => {
    const { allMessageData } = liaccount;
    let filteredTargetMessages = []
    if (allMessageData)
      filteredTargetMessages = allMessageData.filter(
        (item) => `${item.firstName} ${item.lastName}` === name
      );
    return {
      messages:
        filteredTargetMessages.length > 0
          ? getLongestMessagedData(filteredTargetMessages)
            ? [getLongestMessagedData(filteredTargetMessages)]
            : []
          : [],
      isUnRead:
        filteredTargetMessages.length > 0 &&
          getLongestMessagedData(filteredTargetMessages)
          ? getLongestMessagedData(filteredTargetMessages).isUnRead
          : false,
    };
  };

  const getAction = (name, campaign) => {
    const campData = campaign.data.filter(
      (campData) => campData.name === name
    )[0];

    const actions = [];
    if (
      campaign.connectionData.findIndex((dat) => dat.targetName === name) !== -1
    ) {
      actions.push("Accepted");
    }

    if (campData) {
      actions.push(
        ...campData.actions
          .filter(Boolean)
          .map(({ name }) => actionToActivity(name))
      );
    }
    if (
      checkMessageDataAvailable(name).messages.length > 0 &&
      actions.indexOf("Messaged") < 0
    ) {
      actions.push("Messaged");
    }

    if (checkRepliedState(name) && actions.indexOf("Messaged") > -1) {
      actions.push("Replied");
    }

    return actions.length ? actions : ["None"];
  };

  const getContactInfo = (name) => {
    const { connectionData } = liaccount;
    let filtered = connectionData.filter(
      (connect) => `${connect.firstName} ${connect.lastName}` === name
    );
    if (filtered.length > 0) return filtered[0].contactInfo;
    else return null;
  };

  const getCustomTags = (tagIds) => {
    const assignedTags = tag.filter((item) =>
      tagIds.some((id) => id === item._id)
    );
    return assignedTags;
  };

  const initLoad = (campaigns) => {
    if (!campaigns || campaigns.length === 0) {
      setInit(true);
      return;
    }
    let sortedCampaigns = campaigns.sort((a, b) => {
      const keyA = new Date(a.createdAt),
        keyB = new Date(b.createdAt);
      // Compare the 2 dates
      if (keyA > keyB) return -1;
      if (keyA < keyB) return 1;
      return 0;
    });
    let campaignsList = [];
    let prospects = [];
    sortedCampaigns.map((campaign) => {
      campaignsList = [...campaignsList, campaign.identifier];
      campaign.targets.map((target, index) => {
        if (prospects.filter((pr) => pr.name === target.name).length < 1)
          if (target.name && target.name.trim() !== "") {
            let prospect = {
              ...target,
              targetIndex: index,
              actions: getAction(target.name, campaign),
              contactInfo: getContactInfo(target.name),
              customTags: getCustomTags(target.tags),
              browserKey: campaign.browserKey,
              campaignId_HASH: campaign._id,
              campaignId: campaign.identifier,
              campaignName: campaign.name,
              account: props.match.params.account,
              messaged: checkMessageDataAvailable(target.name).messages,
              isUnRead: checkMessageDataAvailable(target.name).isUnRead,
            };
            prospects.push(prospect);
          }
        return true;
      });
      return true;
    });

    // string ab sort
    // prospects.sort((a, b) => {
    //   let fa = a.name.toLowerCase(),
    //     fb = b.name.toLowerCase();
    //   if (fa < fb) return -1;
    //   if (fa > fb) return 1;
    //   return 0;
    // });

    // date ab sort.
    // prospects.sort((a, b) => {
    //   const keyA =
    //     a.messaged.length > 0
    //       ? new Date(
    //         a.messaged[0].messages[a.messaged[0].messages.length - 1].msg
    //       )
    //       : null,
    //     keyB =
    //       b.messaged.length > 0
    //         ? new Date(
    //           b.messaged[0].messages[b.messaged[0].messages.length - 1].msg
    //         )
    //         : null;
    //   if (keyA && !keyB) return -1;
    //   if (!keyA && keyB) return 1;
    //   // Compare the 2 dates
    //   if (keyA > keyB) return -1;
    //   if (keyA < keyB) return 1;
    //   return 0;
    // });


    // update_at sort
    // prospects.sort((a, b) => {
    //   console.log(a)
    //   let x = a.checkedTime,
    //     y = b.checkedTime;
    //   return x < y ? -1 : 1;
    // });
    prospects.sort((a, b) => {
      const keyA =
        a.messaged.length > 0
          ? a.messaged[a.messaged.length - 1].checkedTime
          : null,
        keyB =
          b.messaged.length > 0
            ? b.messaged[b.messaged.length - 1].checkedTime
            : null;
      if (keyA && !keyB) return -1;
      if (!keyA && keyB) return 1;
      // Compare the 2 dates
      if (keyA > keyB) return -1;
      if (keyA < keyB) return 1;
      return 0;
    });
    prospects.sort((a, b) => {
      let x = a.isReplied,
        y = b.isReplied;
      return x === y ? 0 : x ? -1 : 1;
    });
    //unread ab sort.
    prospects.sort((a, b) => {
      let x = a.isUnRead,
        y = b.isUnRead;
      return x === y ? 0 : x ? -1 : 1;
    });

    setCampaignsList([...campaignsList]);
    setFilteredCampaignsList([...campaignsList]);
    setFilteredProspects([...prospects]);
    setProspects([...prospects]);
    try {
      const campaignName = new URLSearchParams(props.location.search).get(
        "campaign"
      );
      if (campaignName) {
        addSelectedList(campaignName);
      }
    } catch (e) { }
    if (targetToModal) {
      setTargetToModal(
        prospects.filter((prospect) => prospect._id === targetToModal._id)[0]
      );
    }
    setInit(true);
  }; //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // initLoad(campaigns);
    setTimeout(() => initLoad(campaigns), 50);
    console.log('---------------- campaigns update by saga-redux---------');

  }, [campaigns]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!message) return;
    const sortProspect = () => {
      let updatedProspects = [];
      prospects.map((data) => {
        let prospect = {
          ...data,
          messaged: data.messaged.map((msgData) => {
            if (msgData._id === message._id) return message;
            return msgData;
          }),
        };

        prospect.isUnRead =
          prospect.messaged.filter((msg) => msg.isUnRead === true).length > 0;
        updatedProspects.push(prospect);
        return true;
      });

      // updatedProspects.sort((a, b) => {
      //   const keyA =
      //     a.messaged.length > 0
      //       ? new Date(
      //         a.messaged[0].messages[a.messaged[0].messages.length - 1].msg
      //       )
      //       : null,
      //     keyB =
      //       b.messaged.length > 0
      //         ? new Date(
      //           b.messaged[0].messages[b.messaged[0].messages.length - 1].msg
      //         )
      //         : null;
      //   if (keyA && !keyB) return -1;
      //   if (!keyA && keyB) return 1;
      //   // Compare the 2 dates
      //   if (keyA > keyB) return -1;
      //   if (keyA < keyB) return 1;
      //   return 0;
      // });
      updatedProspects.sort((a, b) => {
        const keyA =
          a.messaged.length > 0
            ? a.messaged[a.messaged.length - 1].checkedTime
            : null,
          keyB =
            b.messaged.length > 0
              ? b.messaged[b.messaged.length - 1].checkedTime
              : null;
        if (keyA && !keyB) return -1;
        if (!keyA && keyB) return 1;
        // Compare the 2 dates
        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;
        return 0;
      });
      updatedProspects.sort((a, b) => {
        let x = a.isReplied,
          y = b.isReplied;
        return x === y ? 0 : x ? -1 : 1;
      });
      updatedProspects.sort((a, b) => {
        let x = a.isUnRead,
          y = b.isUnRead;
        return x === y ? 0 : x ? -1 : 1;
      });
      if (targetToModal)
        setTargetToModal(
          updatedProspects.filter(
            (prospect) => prospect._id === targetToModal._id
          )[0]
        );
      setProspects([...updatedProspects]);
    };
    sortProspect();
  }, [message]); //eslint-disable-line react-hooks/exhaustive-deps
  // query search
  useEffect(() => {
    let filtered = prospects;
    if (searchQuery) {
      filtered = filtered.filter((prospect) => {
        return (
          prospect.name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
          prospect.headline.toLowerCase().indexOf(searchQuery.toLowerCase()) >
          -1
        );
      });
    }
    if (selectedTag.length > 0) {
      const checkArrayInArray = (customTags, mainArray, checkArray) => {
        let isFalse = null;
        let tags = []
        for (let x = 0; x < customTags.length; x++) {
          const element = customTags[x];
          tags.push(element.title);
        }
        checkArray.forEach((item) => {
          if (isFalse != null && isFalse == false) {
            return;
          } else {
            if ([...tags, ...mainArray].includes(item)) {
              isFalse = true;
            } else {
              isFalse = false;
            }
          }
        });
        return isFalse ? isFalse : false;
      }

      filtered = filtered.filter(
        (prospect) =>
          checkArrayInArray(prospect.customTags, prospect.actions, selectedTag) == true
      );
    }
    if (selectedCampaign.length > 0) {
      filtered = filtered.filter(
        (prospect) => selectedCampaign.indexOf(prospect.campaignId) > -1
      );
    }

    if (selectedStatus.length > 0) {
      filtered = filtered.filter(
        (prospect) =>
          (selectedStatus.indexOf("unread") > -1 && prospect.isUnRead) ||
          (selectedStatus.indexOf("INMAIL") > -1 &&
            prospect.actions.indexOf("INMAIL") > -1)
      );
    }
    setFilteredProspects(filtered);
  }, [searchQuery, selectedTag, selectedCampaign, selectedStatus, prospects]); //eslint-disable-line react-hooks/exhaustive-deps

  //
  const handleSwitch = (
    key,
    targetLink,
    salesLink,
    identifier,
    targetIndex,
    e,
    status
  ) => {
    excludeTarget(key, targetLink, salesLink, identifier, targetIndex, status);
  };

  const toggleMessagingModal = (prospect) => {
    if (targetToModal && targetToModal._id === prospect._id) {
      // setIsMessagingModalOpened(false);
      setTargetToModal(null);
      return;
    }
    // setIsMessagingModalOpened(true);
    setTargetToModal(prospect);
  };

  const searchCampaigns = (filterQuery) => {
    let filtered = campaignsList;
    filtered = filtered.filter(
      (camapaign) =>
        camapaign.toLowerCase().indexOf(filterQuery.toLowerCase()) > -1
    );
    setFilteredCampaignsList([...filtered]);
  };

  const addSelectedList = (campaignId) => {
    if (selectedCampaign.indexOf(campaignId) < 0)
      setSelectedCampaign([...selectedCampaign, campaignId]);
  };

  const addActionSelectedList = (actionTag) => {
    if (selectedTag.indexOf(actionTag) < 0)
      setSelectedTag([...selectedTag, actionTag]);
  };

  const addStatusSelectedList = (statusTag) => {
    if (selectedStatus.indexOf(statusTag) < 0)
      setSelectedStatus([...selectedStatus, statusTag]);
  };

  const removeFromSelected = (campaignId) => {
    let listOfSelected = selectedCampaign;
    let index = selectedCampaign.indexOf(campaignId);
    listOfSelected.splice(index, 1);
    setSelectedCampaign([...listOfSelected]);
  };

  const removeTagFromSelected = (actionTag) => {
    let listOfActionTags = selectedTag;
    let index = selectedTag.indexOf(actionTag);
    listOfActionTags.splice(index, 1);
    setSelectedTag([...listOfActionTags]);
  };

  const removeStatusFromselected = (statusTag) => {
    let listOfStatusTags = selectedStatus;
    let index = selectedStatus.indexOf(statusTag);
    listOfStatusTags.splice(index, 1);
    setSelectedStatus([...listOfStatusTags]);
  };

  const clearAll = () => {
    setSearchQuery("");
    setSelectedTag([]);
    setSelectedCampaign([]);
    setSelectedStatus([]);
  };

  const exportData = async (type, sync = "all") => {
    syncType = sync;
    syncGoogle();
  };

  const syncGoogle = () => {
    requestExport(exportCampaign._id, syncType);
  };

  const downloadCSV = () => {
    csvRef.current.link.click();
  };

  const showConfirmDlg = (spreadsheetId) => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block" }}
        title="Success"
        onConfirm={() => {
          setAlert(null);
        }}
      >
        <span>
          <strong>
            {exportCampaign && exportCampaign.name.split(" -> ")[1]}
          </strong>{" "}
          data has been synced to your Google sheet.
        </span>
        <br />
        <a
          href={`https://docs.google.com/spreadsheets/d/${spreadsheetId}`}
          style={{ fontSize: "0.9rem" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          View Google Sheet
        </a>
      </ReactBSAlert>
    );
  };

  const updateReadStatus = (msgId) => {
    readMessage(msgId);
  };

  const getExportData = (data) => {
    let filtered = filteredProspects.filter(
      (el) =>
        el.campaignId === data.identifier &&
        el.contactInfo &&
        el.contactInfo !== ""
    );
    if (filtered.length > 0) {
      let exportedData = [];
      filtered.map((el) => {
        if (el.name && el.name !== "") {
          let tmp = {
            "Email Address": JSON.parse(el.contactInfo).email,
            "First Name": el.name.split(" ")[0],
            "Last Name": el.name.split(" ")[1],
            "Job Title": el.headline,
            Phone: JSON.parse(el.contactInfo).phone,
            Profile: el.targetLink,
            Websites: JSON.stringify(
              JSON.parse(el.contactInfo).website,
              null,
              2
            ),
            Twitter: JSON.parse(el.contactInfo).twitter,
            Skype: JSON.parse(el.contactInfo).skype,
            WeChat: JSON.parse(el.contactInfo).wechat,
            Birthday: JSON.parse(el.contactInfo).birthday,
            Address: JSON.parse(el.contactInfo).address,
            Connected: JSON.parse(el.contactInfo).connected_date,
            Interests: "",
            "Last Message": el.messaged[0]
              ? el.messaged[0].messages[el.messaged[0].messages.length - 1].msg
              : "",
            "Last Messaged Time": el.messaged[0]
              ? moment
                .unix(
                  el.messaged[0].messages[el.messaged[0].messages.length - 1]
                    .msgTime
                )
                .format("MM/DD/YYYY")
              : "",
          };
          // exportedData.push(tmp);
          if (tmp["Email Address"]) exportedData.push(tmp);
        }
      });
      return exportedData;
    } else return [];
  };

  /**
   * default filter navigated from..
   */
  useEffect(() => {
    if (default_campaign && default_campaign !== "")
      setSelectedCampaign([default_campaign]);
    if (default_replied) setSelectedTag(["Replied"]);
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  if (!init) {
    return (
      <StyledTempOverlay>
        <LoadingSpinner asOverlay={true} />
        <div className="centered-text">Loading...</div>
      </StyledTempOverlay>
    );
  } else
    return (
      <React.Fragment>
        {alert}
        <Modal
          isOpen={exportSelect}
          toggle={toggleExportSelect}
          className="export-select-modal"
        >
          <ModalHeader>Export Campaign Data</ModalHeader>
          <ModalBody>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <Button
                onClick={() => {
                  toggleExportSelect();
                  toggleSyncGooglesheetModal();
                }}
              >
                Sync w/Googlesheet
              </Button>
              <Button
                onClick={() => {
                  toggleExportSelect();
                  downloadCSV();
                }}
              >
                Download CSV
              </Button>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={isSyncGooglesheetModalOpened}
          toggle={toggleSyncGooglesheetModal}
          className="google-sync-modal"
        >
          <ModalHeader>Sync w/ Googlesheet</ModalHeader>
          <ModalBody>
            <CampaignSyncGooglesheetModal
              isOpen={isSyncGooglesheetModalOpened}
              toggleModal={toggleSyncGooglesheetModal}
              showConfirmDlg={showConfirmDlg}
              callback={exportData}
            />
          </ModalBody>
        </Modal>
        {sleepMode && (
          <Modal isOpen={true}>
            <ModalHeader>Your server is in sleep mode</ModalHeader>
            <ModalBody>
              <p>
                If you need to create new campaign/sequence or send reply
                messages, please change your working hours on the settings page
                and wait a minute for the system to update.
              </p>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button color="primary" onClick={() => resetError()}>
                  Cancel
                </Button>
                <Button
                  color="success"
                  onClick={() => {
                    resetError();
                    props.history.push(`/admin/${identifier}/settings`);
                  }}
                >
                  Update Settings
                </Button>
              </div>
            </ModalBody>
          </Modal>
        )}
        {exportCampaign && (
          <CSVLink
            data={getExportData(exportCampaign)}
            filename={`${identifier + moment().format("_YYYY_MM_DD_HH_mm")
              }.csv`}
            className="hidden"
            ref={csvRef}
            target="_blank"
          />
        )}
        <div className="page-prospect__filter d-flex align-items-center my-auto">
          <div className="mr-2">
            <div className="controls mt-2">
              <InputGroup>
                <Input
                  id="appendedInputButton"
                  size="16"
                  type="text"
                  placeholder="Global filter"
                  value={searchQuery}
                  onChange={(ev) => setSearchQuery(ev.target.value)}
                />
                <InputGroupAddon addonType="append">
                  <Button color="secondary" onClick={() => setSearchQuery("")}>
                    <i className="fa fa-close" />
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </div>
          </div>
          <div className="d-flex mr-2">
            <ButtonGroup>
              <ButtonDropdown
                isOpen={openFilterTag}
                toggle={toggleOpenFilterTag}
              >
                <DropdownToggle caret color="">
                  Filter By Tags
                </DropdownToggle>
                <DropdownMenu
                  style={{
                    zIndex: '10000',
                  }}
                >
                  {filteredTag &&
                    filteredTag.map((tag, index) => (
                      <DropdownItem
                        key={`tag-${index}`}
                        onClick={() => addActionSelectedList(tag)}
                      >
                        {tag}
                        &nbsp;
                        {selectedTag.indexOf(tag) > -1 && (
                          <i className="nc-icon nc-check-2" />
                        )}
                      </DropdownItem>
                    ))}
                </DropdownMenu>
              </ButtonDropdown>
              <Button className="btn-icon" color="" onClick={toggleTagsModal}>
                <i className="nc-icon nc-simple-add" id="tooltip-add" />
              </Button>
              <UncontrolledTooltip
                placement="bottom"
                delay={0}
                target={`tooltip-add`}
              >
                Add a new tag
              </UncontrolledTooltip>
            </ButtonGroup>
          </div>
          <div className="mr-2">
            <ButtonDropdown
              isOpen={openFilterCampaign}
              toggle={toggleOpenFilterCampaign}
            >
              <DropdownToggle caret color="">
                Filter by campaign
              </DropdownToggle>
              <DropdownMenu
                style={{
                  zIndex: '10000',
                  maxHeight: '400px',
                  overflowY: 'scroll'
                }}
              >
                <Input
                  placeholder="Search Campagins"
                  style={{ margin: 10, width: "calc(100% - 20px)" }}
                  onChange={(ev) => searchCampaigns(ev.target.value)}
                />
                {filteredCampaignsList &&
                  filteredCampaignsList.map((campaignId, index) => (
                    <DropdownItem
                      key={`campaignId-${index}`}
                      onClick={() => {
                        addSelectedList(campaignId);
                      }}
                    >
                      {campaignId.replace("camp-", "")}
                      &nbsp;
                      {selectedCampaign.indexOf(campaignId) > -1 && (
                        <i className="nc-icon nc-check-2" />
                      )}
                    </DropdownItem>
                  ))}
              </DropdownMenu>
            </ButtonDropdown>
          </div>
          <div>
            <ButtonDropdown
              isOpen={openSortDropdown}
              toggle={toggleOpenSortDropdown}
            >
              <DropdownToggle caret color="">
                Filter By Status
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => addStatusSelectedList("unread")}>
                  Unread
                </DropdownItem>
                {/* <DropdownItem onClick={() => addStatusSelectedList('read')}>Read</DropdownItem> */}
                <DropdownItem onClick={() => addStatusSelectedList("INMAIL")}>
                  INMAIL
                </DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
          </div>
          {(searchQuery ||
            selectedTag.length > 0 ||
            selectedCampaign.length > 0 ||
            selectedStatus.length > 0) && (
              <div>
                <Button
                  color="danger"
                  className="ml-2 mr-2"
                  onClick={() => clearAll()}
                >
                  Clear all
                </Button>
              </div>
            )}
          <div className="ml-auto">
            <ButtonDropdown isOpen={openExport} toggle={toggleOpenExport}>
              <DropdownToggle caret color="">
                Export
              </DropdownToggle>
              <DropdownMenu>
                {campaigns &&
                  campaigns.map((campaign, index) => (
                    <DropdownItem
                      key={`export-${campaign.identifier}`}
                      onClick={() => {
                        toggleExportSelect();
                        setExportCampaign(campaign);
                      }}
                    >
                      {campaign.identifier.replace("camp-", "")}
                    </DropdownItem>
                  ))}
              </DropdownMenu>
            </ButtonDropdown>
          </div>
        </div>
        <CampaignTags
          key={"action-tag"}
          selectedCampaign={selectedTag}
          removeFromSelected={removeTagFromSelected}
          color={"primary"}
        />
        <CampaignTags
          key={"campaign-tag"}
          selectedCampaign={selectedCampaign}
          removeFromSelected={removeFromSelected}
          color={"default"}
        />
        <CampaignTags
          key={"status-tag"}
          selectedCampaign={selectedStatus}
          removeFromSelected={removeStatusFromselected}
          color={"info"}
        />
        <Row>
          <Col lg={isMessagingModalOpened ? 7 : 12}>
            {filteredProspects.length > 0 ? (
              <div>
                <ProspectsContainer
                  setProspectInfoToggleFun={setProspectInfoToggle}
                  prospects={filteredProspects}
                  handleSwitch={handleSwitch}
                  toggleMessagingModal={toggleMessagingModal}
                  toggleAssignModal={toggleAssignModal}
                  updateReadStatus={updateReadStatus}
                />
              </div>
            ) : (
              <h4 className="text-center py-2">No prospects found.</h4>
            )}
          </Col>
          <Col lg={5} style={{ marginTop: '6em' }} key={targetToModal?.targetName}  >
            {targetToModal && isMessagingModalOpened ? (
              <Card style={{
                position: 'sticky',
                top: 0,
              }} >
                <CampaignProspectsMessagingModal
                  key={targetToModal.name + targetToModal.isUnRead}
                  target={targetToModal}
                  liaccount={liaccount}
                  onContactInfoModelIconClick={setProspectInfoToggle}
                  toggleAssignModal={toggleAssignModal}
                  showContactIcon={true}
                />
              </Card>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Col lg={11} offset={1}>
                  <div className="card" style={{ marginTop: "88px" }}>
                    <div className="card-body" style={{ padding: "20px" }}>
                      <i
                        className="nc-icon nc-diamond"
                        style={{ fontSize: "42px" }}
                      ></i>
                      <h4 style={{ marginTop: "10px" }}>Growth Hacks 101</h4>
                      <p style={{ lineHeight: "1.5rem" }}>
                        Scrape Facebook groups and convert the names into
                        Linkedin profileURLs to import into Social Prospector and
                        run a highly-targeted outreach campaign. <br />
                        <br />
                        <a
                          className="btn btn-primary"
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://chrome.google.com/webstore/detail/social-prospector-faceboo/kebcmijnkmcdlmdihaickdkbdkldpejg"
                        >
                          {" "}
                          Start hacking
                        </a>
                      </p>
                    </div>
                  </div>
                </Col>
              </div>
            )}
          </Col>
          <ProspectContactInfoModal
            setProspectInfoToggle={setProspectInfoToggle}
            prospectInfoModel={prospectInfoModel}
            openProspectInfo={openProspectInfo}
          />
        </Row>
      </React.Fragment>
    );
};

const mapStateToProps = (state) => {
  return {
    liaccount: state.liaccounts.liaccount,
    campaigns: state.campaigns.tmpCampaigns,
    tag: state.tag.tag,
    message: state.message.messageData,
    sleepMode: state.exception.sleepMode,
  };
};

const mapDispatchToProps = (dispatch) => ({
  excludeTarget: (
    browserKey,
    targetLink,
    salesLink,
    campIdentifier,
    targetIndex,
    status
  ) =>
    dispatch(
      excludeTarget(
        browserKey,
        targetLink,
        salesLink,
        campIdentifier,
        targetIndex,
        status
      )
    ),
  requestExport: (id, syncType) => dispatch(requestExport(id, syncType)),
  readMessage: (msgId) => dispatch(readMessage(msgId)),
  resetError: () => dispatch(resetError()),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps)(BlackListPage)
);
