import React, { useRef, useEffect, useState } from "react";
import _ from "lodash";
import emojis from "./emoji.json";
import axios from "axios";

const EmojiPicker = ({ isOpen, togglePicker, handleInputChange, sendGif, showGif }) => {
  const node = useRef();
  const btnNode = useRef();

  const [searchValue, setSearchValue] = useState("");
  const [activeTab, setActiveTab] = useState("tab1");
  const [giphyResults, setGiphyResults] = useState([]);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const handleClickOutside = (e) => {
    if (node.current && node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    isOpen && !btnNode.current.contains(e.target) && togglePicker();
  };

  const queryGiphy = (query) => {
    const url = `https://api.giphy.com/v1/gifs/search?api_key=dc6zaTOxFJmzC&q=${query}&limit=25`;

    return axios.get(url);
  };

  const debounceSearch = useRef(
    _.debounce((searchValue) => {
      queryGiphy(searchValue)
        .then((resp) => {
          if (resp.status === 200 && resp.data) {
            let filtered = resp.data.data.map((it) => {
              return {
                src: it.images.downsized.url,
                slug: it.slug,
              };
            });
            return setGiphyResults(filtered);
          }
          setGiphyResults([]);
        })
        .catch((err) => setGiphyResults([]));
    }, 1000)
  );

  const renderEmojiList = (group) => {
    return group.map((emoj) => {
      return (
        <span
          key={emoj.codes}
          className="intercom-emoji-picker-emoji"
          title={emoj.name}
          onClick={() => {
            handleInputChange(emoj.char);
            togglePicker();
          }}
        >
          {emoj.char}
        </span>
      );
    });
  };

  const renderEmojiGroup = () => {
    let filteredEmojis =
      searchValue !== ""
        ? emojis.filter((it) => it.name.indexOf(searchValue) > -1)
        : emojis;
    let groupedEmojis = _.groupBy(filteredEmojis, "group");

    return Object.keys(groupedEmojis).map((it, index) => {
      return (
        <div key={index} className="intercom-emoji-picker-group">
          <div className="intercom-emoji-picker-group-title">{it}</div>
          {renderEmojiList(groupedEmojis[it])}
        </div>
      );
    });
  };

  const renderGifList = () => {
    return giphyResults.map((it, index) => {
      return (
        <img
          key={index}
          className="intercom-emoji-picker-gif"
          src={it.src}
          onClick={() => {
            sendGif(it.src);
            togglePicker();
          }}
        />
      );
    });
  };

  return (
    <React.Fragment>
      <a
        ref={btnNode}
        id="emoji-picker"
        className="emoji-wrapper"
        onClick={() => togglePicker(!isOpen)}
      >
        <svg
          preserveAspectRatio="xMidYMid"
          viewBox="0 0 24 24"
          style={{
            width: "18px",
            height: "18px",
          }}
        >
          <path
            d="M12 24C5.38 24 0 18.62 0 12S5.38 0 12 0s12 5.38 12 12-5.38 12-12 12zm0-22C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm0 18c-2.9 0-5.56-1.75-6.9-4.57-.24-.5-.03-1.1.47-1.33.5-.24 1.1-.03 1.33.47C7.9 16.67 9.86 18 12 18c2.15 0 4.1-1.3 5.1-3.43.23-.5.83-.7 1.33-.47.5.23.7.83.47 1.33C17.58 18.25 14.93 20 12 20zm4-8c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm-8 0c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"
            fill="#fff"
          ></path>
        </svg>
      </a>

      <div
        className={`intercom-composer-popover intercom-composer-emoji-popover ${isOpen ? "active" : ""
          }`}
        ref={node}
      >
        <div className="intercom-emoji-picker">
          <div className="intercom-composer-popover-header">
            {<input
              className="intercom-composer-popover-input"
              placeholder="Search"
              value={searchValue}
              onChange={(evt) => {
                setSearchValue(evt.target.value);
                if (activeTab === "tab2") {
                  debounceSearch.current(searchValue);
                }
              }}
            />}
          </div>
          <div className="gif-picker-container">
            <input
              id="tab-1"
              type="radio"
              name="tabs"
              onClick={() => setActiveTab("tab1")}
            />
            <label
              htmlFor="tab-1"
              className={`${activeTab === "tab1" && "active"}`}
            >
              Emojis
            </label>

            {showGif && <><input
              id="tab-2"
              type="radio"
              name="tabs"
              onClick={() => setActiveTab("tab2")}
            />
              <label
                htmlFor="tab-2"
                className={`${activeTab === "tab2" && "active"}`}
              >
                GIFs
            </label></>}
          </div>

          <div className="intercom-composer-popover-body-container">
            <div className="intercom-composer-popover-body">
              <div className="intercom-emoji-picker-groups">
                {activeTab === "tab1" ? (
                  renderEmojiGroup()
                ) : (
                    <div className="intercom-emoji-picker-group">
                      {renderGifList()}
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className="intercom-composer-popover-caret"></div>
      </div>
    </React.Fragment>
  );
};

export default EmojiPicker;
