import { takeLatest, all, put } from "redux-saga/effects";
import { types, notesActions } from "../actions";
import { backendUrl } from "../utils";
import getError from "./getError";
import axios from "axios";

const { NOTES } = types;
const { updateNotesSuccess, updateNotesError } = notesActions;

const noteUrl = backendUrl + "/target/";

function* requestNotesUpdate(action) {
  const { target_id, notes } = action;
  try {
    yield axios.post(noteUrl + target_id + "/notes", {
      note: notes,
    });
    yield put(updateNotesSuccess());
  } catch (e) {
    const error = getError(e);
    yield put(updateNotesError(error));
  }
}

function* notesSaga() {
  yield all([takeLatest(NOTES.REQUEST_NOTES_UPDATE, requestNotesUpdate)]);
}

export default notesSaga;
