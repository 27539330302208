import React from "react";
import defaultImage from "assets/img/image_placeholder.jpg";

export function SmoothImageLoad({ src, alt }) {
  const [isValidSrc, setIsValidSrc] = React.useState(!!src);

  return (
    <>
      {isValidSrc ? (
        <img
          style={{
            maxWidth: "26px",
            borderRadius: "50%",
          }}
          src={src}
          alt={alt}
          onError={() => setIsValidSrc(false)}
        />
      ) : (
        <img
          style={{
            maxWidth: "26px",
            borderRadius: "50%",
          }}
          src={defaultImage}
          alt={alt}
        />
      )}
    </>
  );
}
