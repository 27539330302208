import { types } from "../actions";

const { INTEGRATION: ACTION_HEADER } = types;

const initialState = {
  integrations: [],
  loading: false,
  error: "",
  fetched: false,
  integrationsAccount: [],
  successMessage: "",
};

function integrationReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_HEADER.REQUEST_UPDATE: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ACTION_HEADER.SUCCESS_UPDATE: {
      const integrations = state.integrations;
      const { _id, campaign_name } = action.integration;
      const identifier = "inte-" + campaign_name.replace(/\W/g, "-");
      const index = integrations.findIndex((seq) => seq._id === _id);
      if (index === -1) {
        integrations.unshift({ ...action.integration, identifier });
      } else {
        integrations[index] = { ...action.integration, identifier };
      }
      return { ...state, loading: false, integrations };
    }

    case ACTION_HEADER.REQUEST_DELETE:
      return { ...state, loading: true, error: "" };
    case ACTION_HEADER.SUCCESS_DELETE: {
      const integrations = state.integrations.slice(0);
      const { _id } = action;
      const index = integrations.findIndex((seq) => seq._id === _id);
      if (index !== -1) {
        integrations.splice(index, 1);
      }

      return { ...state, loading: false, integrations };
    }
    case ACTION_HEADER.SUCCESS_DELETE_ACCOUNT: {
      const integrations = state.integrationsAccount.slice(0);
      const { _id } = action;
      const index = integrations.findIndex((seq) => seq._id === _id);
      if (index !== -1) {
        integrations.splice(index, 1);
      }
      return { ...state, loading: false, integrationsAccount: integrations };
    }

    case ACTION_HEADER.REQUEST_GET:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case ACTION_HEADER.SUCCESS_GET:
      return {
        ...state,
        loading: false,
        integrations: action.data,
        fetched: true,
      };

    case ACTION_HEADER.REQUEST_GET_ACCOUNT:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case ACTION_HEADER.SUCCESS_GET_ACCOUNT:
      return {
        ...state,
        loading: false,
        integrationsAccount: action.data,
        fetched: true,
      };

    case ACTION_HEADER.FAIL:
      return { ...state, loading: false, error: action.error };
    case ACTION_HEADER.NEED_FETCH:
      return { ...state, fetched: false };
    case ACTION_HEADER.SHOW_SUCCESS_MESSAGE:
      return { ...state, successMessage: action.message };
    case ACTION_HEADER.HIDE_SUCCESS_MESSAGE:
      return { ...state, successMessage: "" };
    case ACTION_HEADER.RESET_INTEGRATION:
      return {
        ...state,
        integrations: [],
        integrationsAccount: [],
        fetched: false,
      };
    default: {
      return state;
    }
  }
}

export default integrationReducer;
