import React from "react";

const UserAvatar = (props) => {
  const { imageUrl, imageAlt } = props;

  return (
    <div className="user-info">
      <div className="user-avatar-wrapper">
        <img
          className="avatar"
          alt={imageAlt}
          src={
            imageUrl ? imageUrl : require("assets/img/image_placeholder.jpg")
          }
        />
      </div>
      {/*<div className="user-name">{userName}</div>*/}
    </div>
  );
};

export default UserAvatar;
