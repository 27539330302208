import { takeLatest, all, put } from "redux-saga/effects";
import { types, messageActions, errorActions, liaccountActions } from "../actions";
import { backendUrl } from "../utils";
import axios from "axios";
import getError from "./getError";

const { MESSAGE } = types;
const {
  successMessageSend,
  failMessageSend,
  successReadStatus,
  successUnReadStatus,
} = messageActions;

const {
  updateLiaccountInfo
} = liaccountActions;

const { sleepModeException } = errorActions;
const baseURL = backendUrl + "/message";

function* requestMessageSend(action) {
  const {
    liEmail,
    msg,
    profileUrl,
    name,
    isSales,
    useCred,
    isInMail,
    subject,
  } = action;
  try {
    const resp = yield axios.post(baseURL, {
      liEmail,
      msg,
      profileUrl,
      name,
      isSales,
      useCred,
      isInMail,
      subject,
    });
    console.log("resp: ===>", resp.data);
    yield put(successMessageSend(resp.data));
  } catch (e) {
    const error = getError(e);
    if (error.message && error.message.indexOf("sleep mode") > -1) {
      yield put(sleepModeException());
    } else {
      yield put(
        failMessageSend(error === "IS_EMPTY" ? "Target Link is Empty" : error)
      );
    }
  }
}

function* messageSaga() {
  yield all([
    takeLatest(MESSAGE.REQUEST_MESSAGE_SEND, requestMessageSend),
    takeLatest(MESSAGE.READ_MESSAGE, readMessage),
    takeLatest(MESSAGE.UNREAD_MESSAGE, unreadMessage),
  ]);
}

function* readMessage(action) {
  const { message_id } = action;
  try {
    const resp = yield axios.post(`${baseURL}/read`, {
      messageId: message_id,
    });
    // console.log("readMessageStatus----->", resp);
    yield put(successReadStatus(resp.data));
    yield put(updateLiaccountInfo(resp.data));
  } catch (e) {
    // const error = getError(e);
    // console.log(e.response);
  }
}

function* unreadMessage(action) {
  console.group("api calling");
  const { message_id } = action;
  try {
    const resp = yield axios.post(`${baseURL}/unread`, {
      messageId: message_id,
    });
    // console.log("readMessageStatus----->", resp);
    yield put(successUnReadStatus(resp.data));
    yield put(updateLiaccountInfo(resp.data));
  } catch (e) { }
}

export default messageSaga;
