import React, { useEffect, useState } from "react";
import { Row, Col, Modal, ModalBody, Tooltip, Input, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import { notesActions } from "../../../../actions";

const ProspectContactInfoModal = ({
  setProspectInfoToggle,
  prospectInfoModel,
  openProspectInfo,
  notesRedux,
  loading,
  updateNotes,
}) => {
  const [copyTooltip, setCopyTooltip] = useState({
    emailInfo: false,
    contactInfo: false,
  });
  const [copyTooltipText, setCopyTooltipText] = useState("Click to Copy");
  const [notes, setNotes] = useState([]);
  const [noteInput, setNoteInput] = useState("");
  useEffect(() => {
    setNoteInput("");
    if (openProspectInfo._id) {
      let notesTmp = [];
      if (notesRedux[openProspectInfo._id]) {
        notesTmp = notesRedux[openProspectInfo._id];
      } else {
        notesTmp = openProspectInfo.note;
      }
      if (notesTmp) {
        let fetchNotes = [];
        for (let s = 0; s < notesTmp.length; s++) {
          const element = notesTmp[s];
          if (element) {
            fetchNotes.push({
              text: element,
              disabled: true,
            });
          }
        }
        setNotes(fetchNotes);
      }
    }
  }, [openProspectInfo._id]); //eslint-disable-line react-hooks/exhaustive-deps
  const addNotes = () => {
    setNotes([
      ...notes,
      {
        text: noteInput,
        disabled: true,
      },
    ]);
    updateNotesApi([
      ...notes,
      {
        text: noteInput,
        disabled: true,
      },
    ]);
    setNoteInput("");
  };
  const editNotesTrigger = (index) => {
    const myNotes = notes;
    myNotes[index] = {
      text: notes[index].text,
      disabled: false,
    };
    setNotes([...myNotes]);
  };

  const updateNotesApi = (notesArray) => {
    if (openProspectInfo._id) {
      if (notesArray.length > 0) {
        let noteArray = [];
        for (let x = 0; x < notesArray.length; x++) {
          const element = notesArray[x];
          noteArray.push(element.text);
        }
        updateNotes(openProspectInfo._id, noteArray);
      } else {
        updateNotes(openProspectInfo._id, notesArray);
      }
    }
  };

  const editNotes = (index) => {
    const myNotes = notes;
    myNotes[index] = {
      text: myNotes[index].text,
      disabled: true,
    };
    setNotes([...myNotes]);
    updateNotesApi(myNotes);
  };

  const deleteNotes = (index) => {
    notes.splice(index, 1);
    setNotes([...notes]);
    updateNotesApi(notes);
  };

  const copyToClipboard = (str) => {
    const el = document.createElement("textarea");
    el.value = str;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };
  return (
    <Modal
      toggle={() => {
        setProspectInfoToggle({});
      }}
      isOpen={prospectInfoModel}
    >
      <div className=" modal-header">
        <h5 className=" modal-title" id="exampleModalLabel">
          {openProspectInfo.title}
          <span
            aria-label="Close"
            className=" close"
            type="button"
            onClick={() => {
              setProspectInfoToggle({});
            }}
          >
            <span aria-hidden={true}>×</span>
          </span>
        </h5>
      </div>
      <ModalBody>
        {openProspectInfo.campaignName ? (
          <Row>
            <Col xs={3}>
              <b>Campaign</b>
            </Col>
            <Col xs={9}>
              {openProspectInfo.campaignName ? (
                <Link
                  to={`/admin/${openProspectInfo.account}/campaigns/details-${openProspectInfo.campaignId_HASH}`}
                >
                  {openProspectInfo.campaignName.split("->")[1]}
                </Link>
              ) : (
                  ""
                )}
            </Col>
            <hr />
          </Row>
        ) : (
            ""
          )}
        {openProspectInfo.email && (
          <Row>
            <Col xs={3}>
              <b>Email</b>
            </Col>
            <Col xs={9}>
              <span>{openProspectInfo.email}</span> &nbsp;&nbsp;&nbsp;
              <i
                id="emailInfo"
                className="nc-icon nc-single-copy-04"
                onClick={() => {
                  copyToClipboard(openProspectInfo.email);
                  setCopyTooltipText("copied!");
                }}
                onMouseLeave={() => {
                  setCopyTooltipText("Click to Copy");
                  setCopyTooltip({
                    ...copyTooltip,
                    emailInfo: false,
                  });
                }}
                onMouseEnter={() => {
                  setCopyTooltip({
                    ...copyTooltip,
                    emailInfo: true,
                  });
                }}
              ></i>
              <Tooltip
                placement="top"
                delay={1}
                target="emailInfo"
                isOpen={copyTooltip.emailInfo}
                autohide={false}
              >
                {copyTooltipText}
              </Tooltip>
            </Col>
            <hr />
          </Row>
        )}
        {openProspectInfo.phone && openProspectInfo.phone.length > 0 && (
          <Row>
            <Col xs={3}>
              <b>Phone</b>
            </Col>
            <Col xs={9}>
              <span>{openProspectInfo.phone[0].phone}</span> &nbsp;&nbsp;&nbsp;
              <i
                className="nc-icon nc-single-copy-04"
                id="phoneInfo"
                onClick={() => {
                  copyToClipboard(
                    openProspectInfo.phone
                      ? openProspectInfo.phone[0].phone
                      : ""
                  );
                  setCopyTooltipText("copied!");
                }}
                onMouseLeave={() => {
                  setCopyTooltipText("Click to Copy");
                  setCopyTooltip({
                    ...copyTooltip,
                    phoneInfo: false,
                  });
                }}
                onMouseEnter={() => {
                  setCopyTooltip({
                    ...copyTooltip,
                    phoneInfo: true,
                  });
                }}
              ></i>
              <Tooltip
                placement="top"
                delay={1}
                target="phoneInfo"
                isOpen={copyTooltip.phoneInfo}
                autohide={false}
              >
                {copyTooltipText}
              </Tooltip>
            </Col>
            <hr />
          </Row>
        )}
        {openProspectInfo.skype && (
          <Row>
            <Col xs={3}>
              <b>Skype</b>
            </Col>
            <Col xs={9}>
              <span>{openProspectInfo.skype}</span> &nbsp;&nbsp;&nbsp;
              <i
                className="nc-icon nc-single-copy-04"
                id="skypeInfo"
                onClick={() => {
                  copyToClipboard(openProspectInfo.skype);
                  setCopyTooltipText("copied!");
                }}
                onMouseLeave={() => {
                  setCopyTooltipText("Click to Copy");
                  setCopyTooltip({
                    ...copyTooltip,
                    skypeInfo: false,
                  });
                }}
                onMouseEnter={() => {
                  setCopyTooltip({
                    ...copyTooltip,
                    skypeInfo: true,
                  });
                }}
              ></i>
              <Tooltip
                placement="top"
                delay={1}
                target="skypeInfo"
                isOpen={copyTooltip.skypeInfo}
                autohide={false}
              >
                {copyTooltipText}
              </Tooltip>
            </Col>
            <hr />
          </Row>
        )}
        {openProspectInfo.wechat && (
          <Row>
            <Col xs={3}>
              <b>Wechat</b>
            </Col>
            <Col xs={9}>
              <span>{openProspectInfo.wechat}</span> &nbsp;&nbsp;&nbsp;
              <i
                className="nc-icon nc-single-copy-04"
                id="wechatInfo"
                onClick={() => {
                  copyToClipboard(openProspectInfo.wechat);
                  setCopyTooltipText("copied!");
                }}
                onMouseLeave={() => {
                  setCopyTooltipText("Click to Copy");
                  setCopyTooltip({
                    ...copyTooltip,
                    wechatInfo: false,
                  });
                }}
                onMouseEnter={() => {
                  setCopyTooltip({
                    ...copyTooltip,
                    wechatInfo: true,
                  });
                }}
              ></i>
              <Tooltip
                placement="top"
                delay={1}
                target="wechatInfo"
                isOpen={copyTooltip.wechatInfo}
                autohide={false}
              >
                {copyTooltipText}
              </Tooltip>
            </Col>
            <hr />
          </Row>
        )}
        {openProspectInfo.twitter && (
          <Row>
            <Col xs={3}>
              <b>Twitter</b>
            </Col>
            <Col xs={9}>
              <span>{openProspectInfo.twitter}</span> &nbsp;&nbsp;&nbsp;
              <i
                className="nc-icon nc-single-copy-04"
                id="twitterInfo"
                onClick={() => {
                  copyToClipboard(openProspectInfo.twitter);
                  setCopyTooltipText("copied!");
                }}
                onMouseLeave={() => {
                  setCopyTooltipText("Click to Copy");
                  setCopyTooltip({
                    ...copyTooltip,
                    twitterInfo: false,
                  });
                }}
                onMouseEnter={() => {
                  setCopyTooltip({
                    ...copyTooltip,
                    twitterInfo: true,
                  });
                }}
              ></i>
              <Tooltip
                placement="top"
                delay={1}
                target="twitterInfo"
                isOpen={copyTooltip.twitterInfo}
                autohide={false}
              >
                {copyTooltipText}
              </Tooltip>
            </Col>
            <hr />
          </Row>
        )}
        {openProspectInfo.address && (
          <Row>
            <Col xs={3}>
              <b>Address</b>
            </Col>
            <Col xs={9}>
              <span>{openProspectInfo.address}</span>
            </Col>
            <hr />
          </Row>
        )}
        {openProspectInfo.birthday && (
          <Row>
            <Col xs={3}>
              <b>Birthday</b>
            </Col>
            <Col xs={9}>
              <span>{openProspectInfo.birthday}</span>
            </Col>
            <hr />
          </Row>
        )}
        {loading && "loading"}
        {!loading && (
          <Row>
            <Col xs={12}></Col>
            <Col xs={12}>
              <Row className="justify-content-center">
                <Col xs={10} className="my-auto">
                  <Input
                    type="text"
                    name="success"
                    id="success"
                    value={noteInput}
                    onChange={(e) => {
                      setNoteInput(e.target.value);
                    }}
                    placeholder="Add Notes"
                  />
                </Col>
                <Col xs={2}>
                  <Button
                    className="btn-icon btn-round btn-primary"
                    onClick={addNotes}
                  >
                    <i className="fa fa-plus" />
                  </Button>
                </Col>
              </Row>
              {notes.map((item, index) => {
                return (
                  <Row key={index} className="justify-content-center">
                    <Col
                      xs={10}
                      onClick={() => {
                        editNotesTrigger(index);
                      }}
                      className="my-auto"
                    >
                      <Input
                        type="text"
                        value={item.text}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            editNotes(index);
                          }
                        }}
                        onChange={(e) => {
                          notes[index].text = e.target.value;
                          setNotes([...notes]);
                        }}
                        disabled={item.disabled}
                        name="success"
                        id="success"
                      />
                    </Col>
                    <Col xs={2}>
                      {item.disabled ? (
                        <Button className="btn-icon btn-round btn-danger">
                          <i
                            onClick={() => {
                              deleteNotes(index);
                            }}
                            className="fa fa-trash"
                          />
                        </Button>
                      ) : (
                          <Button className="btn-icon btn-round btn-success">
                            <i
                              onClick={() => {
                                editNotes(index);
                              }}
                              className="fa fa-check"
                            />
                          </Button>
                        )}
                      {/* <Button className="btn-icon btn-round" color="twitter">
                                            <i className="fa fa-check" />
                                        </Button> */}
                    </Col>
                  </Row>
                );
              })}
            </Col>
          </Row>
        )}
      </ModalBody>
    </Modal>
  );
};

// export default ProspectContactInfoModal;

const mapStateToProps = (state) => ({
  notesRedux: state.notes.notes,
  loading: state.notes.loading,
});

const mapDispatchToProps = (dispatch) => ({
  getNotes: (targetId) => dispatch(notesActions.requestGetNotes(targetId)),
  updateNotes: (targetId, notes) =>
    dispatch(notesActions.requestUpdateNotes(targetId, notes)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps)(ProspectContactInfoModal)
);
