import { types } from "../actions";

const { REPLAYS_TEMPLATES: ACTION_HEADER } = types;

const initialState = {
  replayTemplates: [],
  loading: false,
  error: "",
  fetched: false,
  successMessage: "",
};

// "REPLAYS_TEMPLATES_GET_REQUEST",
// "REPLAYS_TEMPLATES_GET_SUCCESS",
// "REPLAYS_TEMPLATES_GET_ERROR",
// "REPLAYS_TEMPLATES_CREATE_REQUEST",
// "REPLAYS_TEMPLATES_CREATE_SUCCESS",
// "REPLAYS_TEMPLATES_CREATE_ERROR",
// "REPLAYS_TEMPLATES_UPDATE_REQUEST",
// "REPLAYS_TEMPLATES_UPDATE_SUCCESS",
// "REPLAYS_TEMPLATES_UPDATE_ERROR",
// "REPLAYS_TEMPLATES_DELETE_REQUEST",
// "REPLAYS_TEMPLATES_DELETE_SUCCESS",
// "REPLAYS_TEMPLATES_DELETE_ERROR"

function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_HEADER.REPLAYS_TEMPLATES_GET_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case ACTION_HEADER.REPLAYS_TEMPLATES_GET_SUCCESS: {
      return {
        ...state,
        loading: false,
        replayTemplates: action.replayTemplates,
      };
    }

    case ACTION_HEADER.REPLAYS_TEMPLATES_GET_ERROR: {
      return {
        ...state,
        loading: false,
        error: "Getting Error while fetching notes",
        replayTemplates: [],
      };
    }
    case ACTION_HEADER.REPLAYS_TEMPLATES_UPDATE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ACTION_HEADER.REPLAYS_TEMPLATES_UPDATE_SUCCESS: {
      let tempsIndex = state.replayTemplates.findIndex((item) => {
        return item._id === action.replayTemplate._id;
      });
      state.replayTemplates[tempsIndex] = action.replayTemplate;

      return {
        ...state,
        loading: false,
        replayTemplates: state.replayTemplates,
      };
    }
    case ACTION_HEADER.REPLAYS_TEMPLATES_CREATE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ACTION_HEADER.REPLAYS_TEMPLATES_CREATE_SUCCESS: {
      let temps = state.replayTemplates;
      temps.unshift(action.replayTemplate);
      return { ...state, loading: false, replayTemplates: temps };
    }
    case ACTION_HEADER.REPLAYS_TEMPLATES_DELETE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ACTION_HEADER.REPLAYS_TEMPLATES_DELETE_SUCCESS: {
      let tempsIndex = state.replayTemplates.findIndex((item) => {
        return item._id === action.replayTemplate;
      });
      state.replayTemplates.splice(tempsIndex, 1);
      return {
        ...state,
        loading: false,
        replayTemplates: state.replayTemplates,
      };
    }
    default: {
      return state;
    }
  }
}

export default reducer;
