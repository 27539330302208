/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "recompose";

import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import configureStore, { history } from "./store";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.1.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import App from "./App";
import { userActions } from "actions";
const { requestGetUser } = userActions;

const store = configureStore();

const mapStateToProps = (state) => ({
  token: state.authentication.token,
  user: state.authentication.user,
});

const mapDispatchToProps = (dispatch) => ({
  requestGetUser: () => dispatch(requestGetUser()),
});

const AppWithStore = withRouter(
  compose(connect(mapStateToProps, mapDispatchToProps)(App))
);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <AppWithStore />
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);
