/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import classnames from "classnames";
import { withRouter } from "react-router-dom";
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  Button,
  Nav,
  Container,
} from "reactstrap";
import styled from "styled-components";
import {
  sequenceActions,
  campaignActions,
  integrationActions,
  liaccountActions,
  tagActions,
} from "../../actions";

import { setLocalStorageData } from '../../utils/localStorageService';

const { requestGetAccountById } = liaccountActions;
const { requestGetCampaigns, resetCampaign } = campaignActions;
const { requestGetSequences } = sequenceActions;
const { resetIntegration } = integrationActions;
const { resetTag } = tagActions;


const StyledLiaccDropdownList = styled.ul`
  position: absolute;
  top: 50px;
  background: white;
  width: fit-content;
  border-radius: 15px;
  list-style: none;
  cursor: pointer;
  padding: 0;
  width: max-content;

  & li {
    margin: 10px;
    & img {
      border-radius: 50%;
      margin-right: 10px;
    }
  }
`;

const StyledNavItem = styled.div`
  position: relative;
  display: flex;
  cursor: pointer;
  align-items: center;
  & .photo img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-right: 20px;
  }
`;

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      color: "navbar-transparent",
      currentPage: "home",
      admin: props.match.path === "/ppadmin" ? true : false
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateColor);
  }
  componentDidUpdate(e) {
    if (
      window.outerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
  }
  componentWillMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }

  handleClick = (e) => {
    if (!this.state.liaccDropdownOpen) return;
    if (this.node.contains(e.target)) {
      return;
    }
    this.handleClickOutside();
  };

  handleClickOutside = () => {
    this.setState({ liaccDropdownOpen: false });
  };

  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: "bg-white",
      });
    } else {
      this.setState({
        color: "navbar-transparent",
      });
    }
  };

  toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
  };

  toggleCollapse = () => {
    let newState = {
      collapseOpen: !this.state.collapseOpen,
    };
    if (!this.state.collapseOpen) {
      newState["color"] = "bg-white";
    } else {
      newState["color"] = "navbar-transparent";
    }
    this.setState(newState);
  };

  getAccountManageHref = () => {
    let identifier = window.location.pathname.split("/")[2];
    if (identifier) {
      return `/admin/${identifier}/user-profile`;
    }
    return "/admin/user-profile";
  };

  getGlobalIntegrationHref = () => {
    return "/admin/global-integration";
  };

  openLiaccDropDown = () => {
    this.setState({ liaccDropdownOpen: true });
  };

  selectLiaccount = (liacc) => {
    this.setState({ liaccDropdownOpen: false });
    if (this.props.liaccount._id !== liacc._id) this.resetLiaccount(liacc);
  };

  resetLiaccount = (account) => {
    setLocalStorageData(account);
    this.props.requestGetAccountById(account._id);
    this.props.requestGetCampaigns(account.email);
    this.props.requestGetSequences(account.email);
    this.props.resetTag();
    this.props.resetCampaign();
    this.props.resetIntegration();
    this.props.history.push(`/admin/${account._id}`);
  };

  render() {
    const { liaccDropdownOpen } = this.state;
    const pathname = this.props.location.pathname;

    return (
      <>
        <Navbar
          className={classnames("navbar-absolute fixed-top", this.state.color)}
          expand="lg"
        >
          <Container fluid>
            <div className="navbar-wrapper" style={{ display: pathname === '/admin' ? 'none' : '' }} >
              <div className="navbar-minimize">
                <Button
                  className="btn-icon btn-round"
                  color="default"
                  id="minimizeSidebar"
                  onClick={this.props.handleMiniClick}
                >
                  <i className="nc-icon nc-minimal-right text-center visible-on-sidebar-mini" />
                  <i className="nc-icon nc-minimal-left text-center visible-on-sidebar-regular" />
                </Button>
              </div>
              <div
                className={classnames("navbar-toggle", {
                  toggled: this.state.sidebarOpen,
                })}
              >
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={this.toggleSidebar}
                >
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>
              {!this.state.admin && <NavbarBrand>
                {this.props.liaccount ? (
                  <StyledNavItem ref={(div) => (this.node = div)}>
                    <div
                      className="photo"
                      onClick={() => this.openLiaccDropDown()}
                    >
                      <img
                        src={
                          this.props.liaccount.imageurl
                            ? this.props.liaccount.imageurl
                            : require("assets/img/placeholder.jpg")
                        }
                        alt=""
                      />
                    </div>
                    <div
                      className="info"
                      onClick={() => this.openLiaccDropDown()}
                    >
                      <span style={{ marginRight: "10px" }}>
                        {this.props.liaccount.name}
                      </span>
                      {liaccDropdownOpen ? (
                        <i
                          className="caret"
                          style={{ transform: "rotate(180deg)" }}
                        />
                      ) : (
                        <i className="caret" />
                      )}
                    </div>
                    {liaccDropdownOpen && (
                      <StyledLiaccDropdownList>
                        {this.props.liaccounts &&
                          this.props.liaccounts.map(
                            (liacc, index) =>
                              liacc._id !== this.props.liaccount._id && (
                                <li
                                  key={index}
                                  onClick={() => this.selectLiaccount(liacc)}
                                >
                                  {liacc.imageurl ? (
                                    <img src={liacc.imageurl} alt="" />
                                  ) : (
                                    <img
                                      src={require("assets/img/placeholder.jpg")}
                                      alt=""
                                    />
                                  )}
                                  <span>{liacc.name}</span>
                                </li>
                              )
                          )}
                      </StyledLiaccDropdownList>
                    )}
                  </StyledNavItem>
                ) : (
                  "Social Prospect"
                )}
              </NavbarBrand>}
            </div>
            <button
              aria-controls="navigation-index"
              aria-expanded={this.state.collapseOpen}
              aria-label="Toggle navigation"
              className="navbar-toggler"
              // data-target="#navigation"
              data-toggle="collapse"
              type="button"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </button>
            <Collapse
              className="justify-content-end"
              navbar
              isOpen={this.state.collapseOpen}
            >
              <Nav navbar>
                <UncontrolledDropdown className="" nav>
                  <DropdownToggle
                    aria-haspopup={true}
                    caret
                    color="default"
                    data-toggle="dropdown"
                    id="navbarDropdownMenuLink"
                    nav
                  >
                    <i className="nc-icon nc-alert-circle-i" />
                  </DropdownToggle>
                  <DropdownMenu aria-labelledby="navbarDropdownMenuLink" right>
                    <DropdownItem
                      href="https://socialprospector.io/#faq"
                      target="_blank"
                    >
                      FAQ
                    </DropdownItem>
                    {/* <DropdownItem
                      href={"https://perfectprospect.io/affiliate-partners"}
                      target="_blacnk"
                    >
                      Referral Program
                    </DropdownItem> */}
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown className="" nav>
                  <DropdownToggle
                    aria-haspopup={true}
                    caret
                    color="default"
                    data-toggle="dropdown"
                    id="navbarDropdownMenuLink"
                    nav
                  >
                    <i className="nc-icon nc-circle-10" />
                    <p>
                      <span className="d-lg-none d-md-block">Account</span>
                    </p>
                  </DropdownToggle>

                  <DropdownMenu aria-labelledby="navbarDropdownMenuLink" right>
                    <DropdownItem href="/admin">Switch LI User</DropdownItem>
                    <DropdownItem href={this.getAccountManageHref()}>
                      Account Manager
                    </DropdownItem>

                    <DropdownItem onClick={this.props.logout}>
                      Logout
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    liaccounts: state.liaccounts.liaccounts,
    liaccount: state.liaccounts.liaccount,
  };
};

const mapDispatchToProps = (dispatch) => ({
  resetCampaign: () => dispatch(resetCampaign()),
  requestGetCampaigns: (email) => dispatch(requestGetCampaigns(email, [], [])),
  requestGetSequences: (email) => dispatch(requestGetSequences(email)),
  resetTag: () => dispatch(resetTag()),
  resetIntegration: () => dispatch(resetIntegration()),
  requestGetAccountById: (id) => dispatch(requestGetAccountById(id)),
});

export default withRouter(
  compose(connect(mapStateToProps, mapDispatchToProps)(AdminNavbar))
);
