// Campaigns Page

/* eslint-disable */
// this was added just to clear up the dev console. Pls fix warnings if its possible

import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { setLocalStorageData } from '../../utils/localStorageService';

// reactstrap components
import {
  Button,
  FormGroup,
  CardBody,
  Label,
  Row,
  Col,
  Card,
  CardHeader,
  CardFooter,
  Input,
  UncontrolledTooltip,
} from "reactstrap";

import InputRange from "react-input-range";
import LoadingOverlay from "react-loading-overlay";
import timezones from "timezones.json";

import "react-input-range/lib/css/index.css";

import { liaccountActions } from "../../actions";
import { originToutc, utcTotarget, isSafari } from "../../utils";

import ReactSelect from "react-select";
import SimpleValue from "react-select-simple-value";

import TimePicker from "react-bootstrap-time-picker";

const { requestChangeSettings } = liaccountActions;

const MySelect = ({ options, value, ...props }) => (
  <SimpleValue options={options} value={value}>
    {(simpleProps) => <ReactSelect {...props} {...simpleProps} />}
  </SimpleValue>
);

class SettingsPage extends React.Component {
  freqKeys = [
    { title: "Profile views", key: "profileViews" },
    // { title: "Follows", key: "follows" },
    { title: "Invitations", key: "invitations" },
    { title: "Messages", key: "messages" },
    { title: "Inmails", key: "inmails" },
  ];

  state = {
    freqRates: {
      profileViews: {
        min: 15,
        max: 30,
      },
      follows: {
        min: 15,
        max: 30,
      },
      invitations: {
        min: 15,
        max: 30,
      },
      messages: {
        min: 15,
        max: 30,
      },
      inmails: {
        min: 15,
        max: 30,
      },
    },
    timeSettings: {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      workingHours: {
        min: "15:04",
        max: "02:00",
      },
    },
    skipWeekends: true,
    autoSync: true,
  };

  fetchSettings = () => {
    const { liaccount } = this.props;
    if (liaccount) {
      const settings = liaccount.settings;
      const setKeys = ["VISIT", "CONNECT", "MESSAGE", "INMAIL"];
      const freqRates = {};

      setKeys.forEach((setKey, index) => {
        const freqKey = this.freqKeys[index].key;
        freqRates[freqKey] = {};
        freqRates[freqKey].min = settings[setKey] ? settings[setKey][0] : 15;
        freqRates[freqKey].max = settings[setKey] ? settings[setKey][1] : 30;
      });

      const workingHours = settings.workingHours;
      const localZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const timeSettings = {
        timezone: settings.timezone ? settings.timezone : localZone,
        workingHours: {
          min: settings.timezone
            ? `${workingHours[0]
              .toString()
              .padStart(2, "0")}:${workingHours[1]
                .toString()
                .padStart(2, "0")}`
            : originToutc("06:00", localZone),
          max: settings.timezone
            ? `${workingHours[2]
              .toString()
              .padStart(2, "0")}:${workingHours[3]
                .toString()
                .padStart(2, "0")}`
            : originToutc("20:00", localZone),
        },
      };

      this.setState({
        freqRates,
        timeSettings,
        skipWeekends: settings.excludeWeekend,
        autoSync: settings.autoSync === undefined ? false : settings.autoSync,
      });
    }
  };

  saveSettings = () => {
    const { freqRates, timeSettings, skipWeekends, autoSync } = this.state;
    const workingHours = timeSettings.workingHours.min
      .split(":")
      .map((str) => parseInt(str))
      .concat(
        timeSettings.workingHours.max.split(":").map((str) => parseInt(str))
      );

    const settings = {
      workingHours,
      timezone: timeSettings.timezone,
      excludeWeekend: skipWeekends,
      autoSync: autoSync,
    };

    const setKeys = ["VISIT", "CONNECT", "MESSAGE", "INMAIL"];
    setKeys.forEach((setKey, index) => {
      const freqKey = this.freqKeys[index].key;
      settings[setKey] = [freqRates[freqKey].min, freqRates[freqKey].max];
    });

    const {
      match: {
        params: { account: identifier },
      },
      liaccount,
    } = this.props;

    const _id = liaccount._id;
    setLocalStorageData('liaccount', {
      ...liaccount,
      settings
    });
    this.props.requestChangeSettings(_id, settings);
  };

  componentDidMount() {
    this.fetchSettings();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.liaccount) {
      this.fetchSettings();
    }
  }

  changeFreqRates = (key) => (value) => {
    if (value.max - value.min < 5) {
      return;
    }
    const { freqRates } = this.state;
    freqRates[key] = value;
    this.setState({ ...this.state, freqRates });
  };

  changeTimeZone = (option) => {
    const { timeSettings } = this.state;
    timeSettings.timezone = option.value;
    this.setState({ ...this.state, timeSettings });
  };

  changeWorkingHours = (type) => ({ target: { value: time } }) => {
    // console.log("changeWorkingHours -->", time);
    const { timeSettings } = this.state;
    timeSettings.workingHours[type] = originToutc(time, timeSettings.timezone);
    this.setState({ ...this.state, timeSettings });
  };

  changeWorkingHoursForSafari = (type) => (target) => {
    // console.log("changeWorkingHours --->", type, target);
    const { timeSettings } = this.state;
    const tminutes = target / 60;
    const hours = ("0" + Math.floor(tminutes / 60)).slice(-2);
    const minus = ("0" + (tminutes % 60)).slice(-2);
    // console.log(`${hours}:${minus}`);
    timeSettings.workingHours[type] = originToutc(
      `${hours}:${minus}`,
      timeSettings.timezone
    );
    this.setState({ ...this.state, timeSettings });
  };

  toggleSkipWeekends = (e) => {
    this.setState({ ...this.state, skipWeekends: !this.state.skipWeekends });
  };

  toggleAutoSync = () => {
    this.setState({ ...this.state, autoSync: !this.state.autoSync });
  };

  render() {
    const { freqRates, timeSettings, skipWeekends, autoSync } = this.state;
    const { timezone, workingHours } = timeSettings;
    const { loading } = this.props;

    return (
      <>
        <LoadingOverlay
          active={loading}
          spinner
          text="Loading/Saving settings"
        ></LoadingOverlay>
        <div className="content accountlist">
          <Row>
            <Col md="12">
              <h3>Account Settings</h3>
              <Card style={{ boxShadow: "none" }}>
                <CardHeader>
                  <h4>Daily rate of frequency</h4>
                </CardHeader>

                <CardBody style={{ padding: "0px 40px" }}>
                  {this.freqKeys.map(({ title, key }) => (
                    <Row key={key}>
                      <h6
                        style={{
                          width: 200,
                          marginTop: 40,
                        }}
                      >
                        {title}
                      </h6>
                      <div
                        className="col-md-8 col-sm-12 ml-auto mr-auto"
                        style={{ marginTop: 40 }}
                      >
                        <InputRange
                          maxValue={100}
                          minValue={0}
                          value={freqRates[key]}
                          onChange={this.changeFreqRates(key)}
                        />
                      </div>
                    </Row>
                  ))}
                </CardBody>
                <CardFooter></CardFooter>
                <CardHeader>
                  <h4>Time & Auto-Sync settings</h4>
                </CardHeader>
                <CardBody style={{ padding: "0px 40px" }}>
                  <Row>
                    <h6
                      style={{
                        width: 200,
                        marginBottom: 10,
                        marginTop: 20,
                      }}
                    >
                      Time zone
                    </h6>
                    <div
                      className="col-md-8 col-sm-12 ml-auto mr-auto"
                      style={{ marginTop: 20 }}
                    >
                      <MySelect
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="timezone"
                        value={timezone}
                        onChange={this.changeTimeZone}
                        options={timezones
                          .map((timezone) =>
                            timezone.utc.map((utc) => ({
                              label: `${timezone.text} - ${utc}`,
                              value: utc,
                            }))
                          )
                          .flat()}
                        placeholder="Select the timezone"
                      />
                    </div>
                  </Row>
                  <Row>
                    <h6
                      style={{
                        width: 200,
                        marginBottom: 10,
                        marginTop: 20,
                      }}
                    >
                      Working hours
                    </h6>
                    <div
                      className="col-md-8 col-sm-12 ml-auto mr-auto"
                      style={{
                        marginTop: 20,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {!isSafari() && (
                        <input
                          value={workingHours.min}
                          type="time"
                          onChange={this.changeWorkingHours("min")}
                        ></input>
                      )}
                      {isSafari() && (
                        <TimePicker
                          value={workingHours.min}
                          onChange={this.changeWorkingHoursForSafari("min")}
                          step={1}
                        />
                      )}
                      <h6 className="el-inline" style={{ margin: "0 20px" }}>
                        to
                      </h6>
                      {isSafari() && (
                        <TimePicker
                          value={workingHours.max}
                          onChange={this.changeWorkingHoursForSafari("max")}
                          step={1}
                        />
                      )}
                      {!isSafari() && (
                        <input
                          value={workingHours.max}
                          type="time"
                          onChange={this.changeWorkingHours("max")}
                        ></input>
                      )}
                    </div>
                  </Row>
                  <Row>
                    <h6
                      style={{
                        width: 200,
                      }}
                    >
                      &nbsp;
                    </h6>
                    <div
                      className="col-md-8 col-sm-12 ml-auto mr-auto"
                      style={{ marginTop: 10 }}
                    >
                      <FormGroup check className="mt-3">
                        <FormGroup check>
                          <Label check>
                            <Input
                              checked={skipWeekends}
                              type="checkbox"
                              onChange={this.toggleSkipWeekends}
                            />
                            Skip weekends <span className="form-check-sign" />
                          </Label>
                        </FormGroup>
                      </FormGroup>
                    </div>
                  </Row>
                  <Row>
                    <h6
                      style={{
                        width: 200,
                      }}
                    >
                      &nbsp;
                    </h6>
                    <div className="col-md-8 col-sm-12 ml-auto mr-auto">
                      <p>
                        NOTE: Changes to the account settings will not affect
                        tasks that have already been scheduled for execution.
                      </p>
                    </div>
                  </Row>
                  {/* <Row>
                    <h6
                      className="d-flex"
                      style={{
                        width: 200,
                        marginBottom: 10,
                        marginTop: 20,
                      }}
                    >
                      Auto-Sync Googlesheets
                      <i
                        className="nc-icon nc-alert-circle-i ml-2"
                        id={`auto-sync-info`}
                      />
                      <UncontrolledTooltip
                        placement="right"
                        delay={0}
                        target={`auto-sync-info`}
                      >
                        Automatically sync your Googlesheet export with the
                        latest data from our DB.
                      </UncontrolledTooltip>
                    </h6>
                    <div className="col-md-8 col-sm-12 ml-auto mr-auto">
                      <FormGroup check className="mt-3">
                        <FormGroup check>
                          <Label check>
                            <Input
                              checked={autoSync}
                              onChange={this.toggleAutoSync}
                              type="checkbox"
                            />
                            Enable Auto-Sync
                            <span className="form-check-sign" />
                          </Label>
                        </FormGroup>
                      </FormGroup>
                    </div>
                  </Row> */}
                  <Row>
                    <h6
                      style={{
                        width: 200,
                      }}
                    >
                      &nbsp;
                    </h6>
                    <div
                      className="col-md-8 col-sm-12 ml-auto mr-auto"
                      style={{ marginTop: 10 }}
                    >
                      <Button
                        color="primary"
                        onClick={() => this.saveSettings()}
                      >
                        Save
                      </Button>
                      <Button onClick={() => this.fetchSettings()}>
                        Reset
                      </Button>
                    </div>
                  </Row>
                </CardBody>
                <CardFooter></CardFooter>
                <CardHeader>
                  <h4>Pending invitations</h4>
                  <p>
                    Our system will clean oldest pending invitations once daily
                    to ensure the total is <strong>less than 1200</strong>.
                  </p>
                </CardHeader>
                <CardBody></CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  liaccount: state.liaccounts.liaccount,
  loading: state.liaccounts.loading,
  error: state.liaccounts.error,
});

const mapDispatchToProps = (dispatch) => ({
  requestChangeSettings: (_id, settings) =>
    dispatch(requestChangeSettings(_id, settings)),
});

export default withRouter(
  compose(connect(mapStateToProps, mapDispatchToProps)(SettingsPage))
);
