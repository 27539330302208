import { REPLAYS_TEMPLATES as ACTION_HEADER } from "./types";

export function getReplayTemplates(liEmail) {
  return {
    type: ACTION_HEADER.REPLAYS_TEMPLATES_GET_REQUEST,
    liEmail,
  };
}

export function getSuccessReplayTemplates(replayTemplates) {
  return {
    type: ACTION_HEADER.REPLAYS_TEMPLATES_GET_SUCCESS,
    replayTemplates,
  };
}

export function getErrorReplayTemplates() {
  return {
    type: ACTION_HEADER.REPLAYS_TEMPLATES_GET_ERROR,
  };
}

export function updateRequestReplayTemplates(replayTemplate) {
  return {
    type: ACTION_HEADER.REPLAYS_TEMPLATES_UPDATE_REQUEST,
    replayTemplate,
  };
}

export function updateSuccessReplayTemplates(replayTemplate) {
  return {
    type: ACTION_HEADER.REPLAYS_TEMPLATES_UPDATE_SUCCESS,
    replayTemplate,
  };
}

export function updateErrorReplayTemplates(replayTemplate) {
  return {
    type: ACTION_HEADER.REPLAYS_TEMPLATES_UPDATE_ERROR,
    replayTemplate,
  };
}

export function createRequestReplayTemplates(replayTemplate) {
  return {
    type: ACTION_HEADER.REPLAYS_TEMPLATES_CREATE_REQUEST,
    replayTemplate,
  };
}

export function createSuccessReplayTemplates(replayTemplate) {
  return {
    type: ACTION_HEADER.REPLAYS_TEMPLATES_CREATE_SUCCESS,
    replayTemplate,
  };
}

export function createErrorReplayTemplates(replayTemplate) {
  return {
    type: ACTION_HEADER.REPLAYS_TEMPLATES_CREATE_ERROR,
    replayTemplate,
  };
}

export function deleteRequestReplayTemplates(replayTemplateId) {
  return {
    type: ACTION_HEADER.REPLAYS_TEMPLATES_DELETE_REQUEST,
    replayTemplateId,
  };
}

export function deleteSuccessReplayTemplates(replayTemplate) {
  return {
    type: ACTION_HEADER.REPLAYS_TEMPLATES_DELETE_SUCCESS,
    replayTemplate,
  };
}

export function deleteErrorReplayTemplates(replayTemplate) {
  return {
    type: ACTION_HEADER.REPLAYS_TEMPLATES_DELETE_ERROR,
    replayTemplate,
  };
}
