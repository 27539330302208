import { types } from "../actions";

const { MESSAGE: ACTION_HEADER } = types;

const initialState = {
  success: false,
  loading: false,
  message: "",
  messageData: null,
};

function messageReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_HEADER.REQUEST_MESSAGE_SEND: {
      return {
        ...state,
        success: false,
        loading: true,
        message: "",
      };
    }

    case ACTION_HEADER.SUCCESS_MESSAGE_SEND: {
      const { success, message, messageData } = action.data;
      return {
        ...state,
        success: success,
        loading: false,
        messageData: messageData,
        message: message,
      };
    }

    case ACTION_HEADER.FAIL_MESSAGE_SEND: {
      const { message } = action;
      return {
        ...state,
        success: false,
        loading: false,
        message: message,
      };
    }

    case ACTION_HEADER.READ_MESSAGE: {
      const { message_id } = action;
      return {
        ...state,
        message_id: message_id,
      };
    }

    case ACTION_HEADER.SUCCESS_READ_MESSAGE: {
      const { data } = action;
      return {
        ...state,
        messageData: data,
      };
    }

    case ACTION_HEADER.UNREAD_MESSAGE: {
      const { message_id } = action;
      return {
        ...state,
        message_id: message_id,
      };
    }

    case ACTION_HEADER.SUCCESS_UNREAD_MESSAGE: {
      const { data } = action;
      return {
        ...state,
        messageData: data,
      };
    }

    case ACTION_HEADER.CLEAR_MESSAGE: {
      return { ...state, messageData: null };
    }

    default: {
      return state;
    }
  }
}

export default messageReducer;
