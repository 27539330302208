// Integration Page

import React from "react";
import IntegrationManager from "./IntegrationManager";
import IntegrationAddEditViewPage from "./IntegrationAddEditViewPage";
import GlobalIntegrationPage from "../GlobalIntegration/GlobalIntegrationPage";

const IntegrationPage = (props) => {
  const { identifier, type } = props.match.params;
  return identifier ? (
    type === "global" ? (
      <GlobalIntegrationPage add={true} {...props} />
    ) : (
      <IntegrationAddEditViewPage {...props} />
    )
  ) : (
    <IntegrationManager {...props} />
  );
};

export default IntegrationPage;
