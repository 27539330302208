import { takeLatest, all, put, takeEvery } from "redux-saga/effects";
import { types, campaignActions, errorActions } from "../actions";
import { backendUrl } from "../utils";
import axios from "axios";
import { push } from "connected-react-router";
import { removeToken } from "actions/userActions";
import getError from "./getError";

const { CAMPAIGN: ACTION_HEADER } = types;
const {
  successCreateCampaign,
  successGetCampaigns,
  successGetFullCampaignData,
  successGetCampaignById,
  successDeleteCampaign,
  successStartCampaign,
  successPauseCampaign,
  successExcludeTarget,
  successUpdateNote,
  successAssignTags,
  successExport,
  failExport,
  successGetContactInfo,
  successGetTargetsByCampaign,
  finishLoading,
  fail,
} = campaignActions;
const { sleepModeException } = errorActions;
const baseURL = backendUrl + "/campaign";

// add/update sequence request saga
function* requestCreateCampaign(action) {
  const { campaign, account, connectionData, repliedData } = action;
  let formData = new FormData();
  formData.append("excludeOption", campaign.excludeOption);
  formData.append("file", campaign.file);
  formData.append("isRunning", campaign.isRunning);
  formData.append("isStarted", campaign.isStarted);
  formData.append("liEmail", campaign.liEmail);
  formData.append("name", campaign.name);
  formData.append("sequenceId", campaign.sequenceId);
  formData.append("sourceLinks", campaign.sourceLinks);
  formData.append("type", campaign.type);
  try {
    const resp = yield axios({
      method: "post",
      url: baseURL,
      heaers: { "Content-Type": "multipart/form-data" },
      data: formData,
    });

    const campaignData = {
      ...resp.data,
      identifier: "camp-" + resp.data.name.split(" -> ")[1].replace(/\W/g, "-"),
      id: resp.data._id,
      connectionData: connectionData
        .map((dat) => {
          const target = resp.data.targets.filter(
            (campTarget) =>
              campTarget.targetLink.split("/").reverse()[1] ===
              dat.targetLink.split("/").reverse()[1]
          )[0];
          return target
            ? {
                targetName: target.name,
                date: dat.date,
                targetLink: target.targetLink,
              }
            : null;
        })
        .filter(Boolean),
      repliedData: repliedData
        .map((dat) => {
          const target = resp.data.targets.filter(
            (campTarget) =>
              campTarget.targetLink.split("/").reverse()[1] ===
              dat.targetLink.split("/").reverse()[1]
          )[0];
          return target
            ? {
                targetName: target.name,
                date: dat.createdAt,
                targetLink: target.targetLink,
              }
            : null;
        })
        .filter(Boolean),
    };

    yield put(successCreateCampaign(campaignData));
    yield put(push(`/admin/${account}/campaigns`));
  } catch (e) {
    if (
      e.response.data.message &&
      e.response.data.message.indexOf("sleep mode") > -1
    ) {
      yield put(sleepModeException());
    } else {
      let error = "Network Error";
      if (e.response) {
        if (!e.response.data.errors) {
          error = e.response.data;
          if (error === "Unauthorized") {
            yield put(removeToken());
          }
        } else if (typeof e.response.data.errors.msg === "object") {
          error = e.response.data.errors.msg[0].msg;
        } else {
          error = e.response.data.errors.msg;
        }
      }
      yield put(fail(error));
    }
  }
}

// delete campaign request saga
function* requestDeleteCampaign(action) {
  const { _id, account } = action;
  // console.log("delete campaign", _id);
  try {
    yield axios.delete(`${baseURL}/${_id}`);

    // console.log("success delete campaign", resp.data);
    yield put(successDeleteCampaign(_id));
    yield put(push(`/admin/${account}/campaigns`));
  } catch (e) {
    let error = "Network Error";
    // console.log(e, e.response);
    if (e.response) {
      if (!e.response.data.errors) {
        error = e.response.data;
        if (error === "Unauthorized") {
          yield put(removeToken());
        }
      } else if (typeof e.response.data.errors.msg === "object") {
        error = e.response.data.errors.msg[0].msg;
      } else {
        error = e.response.data.errors.msg;
      }
    }
    yield put(fail(error));
  }
}

// start campaign request saga
function* requestStartCampaign(action) {
  const { _id, mode } = action;
  // console.log("start campaign", _id, mode);
  try {
    const resp = yield axios.post(`${baseURL}/${mode}/${_id}`);

    // console.log("success start campaign", resp.data);
    yield put(successStartCampaign(resp.data));
  } catch (e) {
    if (
      e.response.data.message &&
      e.response.data.message.indexOf("sleep mode") > -1
    ) {
      yield put(sleepModeException());
    } else {
      let error = "Network Error";
      // console.log(e, e.response);
      if (e.response) {
        if (!e.response.data.errors) {
          error = e.response.data;
          if (error === "Unauthorized") {
            yield put(removeToken());
          }
        } else if (typeof e.response.data.errors.msg === "object") {
          error = e.response.data.errors.msg[0].msg;
        } else {
          error = e.response.data.errors.msg;
        }
      }
      yield put(fail(error));
    }
  }
}

// pause campaign request saga
function* requestPauseCampaign(action) {
  const { _id } = action;
  // console.log("pause campaign", _id);
  try {
    const resp = yield axios.post(`${baseURL}/pause/${_id}`);
    // console.log("success pause campaign", resp.data);
    yield put(successPauseCampaign(resp.data));
  } catch (e) {
    let error = "Network Error";
    // console.log(e, e.response);
    if (e.response) {
      if (!e.response.data.errors) {
        error = e.response.data;
        if (error === "Unauthorized") {
          yield put(removeToken());
        }
      } else if (typeof e.response.data.errors.msg === "object") {
        error = e.response.data.errors.msg[0].msg;
      } else {
        error = e.response.data.errors.msg;
      }
    }
    yield put(fail(error));
  }
}

function* requestGetFullCampainData(action) {
  try {
    const { liEmail, connectionData, repliedData } = action;
    const resp = yield axios.get(`${baseURL}?liEmail=${liEmail}`);
    let campaigns = resp.data.map((camp) => ({ ...camp }));
    campaigns = resp.data.map((camp) => ({
      ...camp,
      identifier: "camp-" + camp.name.split(" -> ")[1].replace(/\W/g, "-"),
      connectionData: connectionData
        .map((dat) => {
          const target = camp.targets.filter((campTarget) => {
            const link = campTarget.targetLink || campTarget.salesLink;
            if (campTarget.salesLink) {
              return campTarget.name === dat.firstName + " " + dat.lastName;
            }
            return (
              link.split("/").reverse()[1] ===
              dat.targetLink.split("/").reverse()[1]
            );
          })[0];
          return target
            ? {
                targetName: target.name,
                date: dat.date,
                targetLink: target.targetLink,
              }
            : null;
        })
        .filter(Boolean),
      repliedData: repliedData
        .map((dat) => {
          const target = camp.targets.filter((campTarget) => {
            const link = campTarget.targetLink || campTarget.salesLink;
            if (campTarget.salesLink) {
              return campTarget.name === dat.firstName + " " + dat.lastName;
            }
            if (dat.targetLink)
              return (
                link.split("/").reverse()[1] ===
                dat.targetLink.split("/").reverse()[1]
              );
            else if (dat.salesLink)
              return (
                link.split("/").reverse()[1] ===
                dat.salesLink.split("/").reverse()[0]
              );
            else return false;
          })[0];
          return target
            ? {
                targetName: target.name,
                date: dat.createdAt,
                targetLink: target.targetLink,
              }
            : null;
        })
        .filter(Boolean),
    }));
    yield put(successGetFullCampaignData(campaigns));
  } catch (e) {
    const error = getError(e);
    if (error === "Unauthorized") {
      yield put(removeToken());
    }
    yield put(fail(error));
  }
}

// get campaigns request saga - mini
function* requestGetCampaigns(action) {
  try {
    const { liEmail } = action;
    const resp = yield axios.get(`${baseURL}/minidata?liEmail=${liEmail}`);
    yield put(successGetCampaigns(resp.data));
  } catch (e) {
    const error = getError(e);
    if (error === "Unauthorized") {
      yield put(removeToken());
    }
    yield put(fail(error));
  }
}
// get campaign by Id
function* requestGetCampaignById(action) {
  try {
    const { id } = action;
    const resp = yield axios.get(`${baseURL}/${id.split("-")[1]}/withTarget`);
    yield put(successGetCampaignById(resp.data));
  } catch (e) {
    const error = getError(e);
    if (error === "Unauthorized") {
      yield put(removeToken());
    }
    yield put(fail(error));
  }
}

// Get Specific targets for Campaign

function* requestGetTargetsByCampaign(action) {
  try {
    const { id } = action;
    const resp = yield axios.get(`${baseURL}/target/${id}`);
    yield put(successGetTargetsByCampaign(id, resp.data));
  } catch (e) {
    const error = getError(e);
    if (error === "Unauthorized") {
      yield put(removeToken());
    }
    yield put(fail(error));
  }
}

function* requestExcludeTarget(action) {
  const {
    browserKey,
    targetLink,
    salesLink,
    campIdentifier,
    targetIndex,
    status,
  } = action;
  try {
    yield axios.post(`${baseURL}/excludeTarget`, {
      browserKey,
      targetLink: targetLink ? targetLink : undefined,
      salesLink: targetLink ? undefined : salesLink,
      notExcluded: status,
    });
    yield put(successExcludeTarget(campIdentifier, targetIndex));
  } catch (e) {
    const error = getError(e);
    if (error === "Unauthorized") {
      yield put(removeToken());
    }
    yield put(fail(error));
  }
}

function* requestUpdateNote(action) {
  const { salesLink, note } = action;
  try {
    const resp = yield axios.post(`${baseURL}/addNoteToTarget`, {
      salesLink,
      note,
    });
    console.log(resp);
    yield put(successUpdateNote());
  } catch (e) {
    const error = getError(e);
    if (error === "Unauthorized") {
      yield put(removeToken());
    }
    yield put(fail(error));
  }
}

function* requestAssignTags(action) {
  const { id, tags, campIdentifier, targetIndex } = action;
  try {
    yield axios.post(`${backendUrl}/target/${id}/tags`, { tags });
    yield put(successAssignTags(tags, campIdentifier, targetIndex));
  } catch (e) {
    const error = getError(e);
    if (error === "Unauthorized") {
      yield put(removeToken());
    }
    yield put(fail(error));
  }
}

function* requestExport(action) {
  const { id, syncType } = action;
  try {
    const resp = yield axios.get(
      `${baseURL}/${id}/${
        syncType === "replied" ? "replySheet" : "connectionSheet"
      }`
    );
    console.log("---------->request export result", resp);
    yield put(successExport(resp.data.sheetId));
  } catch (e) {
    const error = getError(e);
    if (error === "Unauthorized") {
      yield put(removeToken());
    }
    yield put(failExport(error));
  }
}

function* contactInfoPerOne({
  targetLink,
  salesLink,
  targetIndex,
  campIdentifier,
}) {
  try {
    const resp = yield axios.post(`${backendUrl}/target?`, {
      targetLink: targetLink ? targetLink : undefined,
      salesLink: targetLink ? undefined : salesLink,
    });
    yield put(
      successGetContactInfo(resp.data || "", campIdentifier, targetIndex)
    );
  } catch (e) {
    const error = getError(e);
    if (error === "Unauthorized") {
      yield put(removeToken());
    }
  }
}

function* requestGetContactInfo(action) {
  yield all(action.targets.map((target) => contactInfoPerOne(target)));
  yield put(finishLoading());
}

// function* contactInfoPerArray(targets) {
//   try {
//     const resp = yield axios.post(`${backendUrl}/targets`, {
//       ...targets,
//     });
//     console.log("resp in Saga:>> ", resp);
//     // yield put(
//     //   successGetContactInfo(resp.data || "", campIdentifier, targetIndex)
//     // );
//   } catch (e) {
//     const error = getError(e);
//     if (error === "Unauthorized") {
//       yield put(removeToken());
//     }
//   }
// }

// function* requestGetContactInfo(action) {
//   yield contactInfoPerArray(action.targets);
//   yield put(finishLoading());
// }

function* campaignSaga() {
  yield all([
    takeLatest(ACTION_HEADER.REQUEST_CREATE, requestCreateCampaign),
    takeLatest(ACTION_HEADER.REQUEST_GET, requestGetCampaigns),
    takeLatest(ACTION_HEADER.REQUEST_GET_FULL, requestGetFullCampainData),
    takeLatest(ACTION_HEADER.REQUEST_GET_BY_ID, requestGetCampaignById),
    takeLatest(ACTION_HEADER.REQUEST_DELETE, requestDeleteCampaign),
    takeLatest(ACTION_HEADER.REQUEST_START, requestStartCampaign),
    takeLatest(ACTION_HEADER.REQUEST_PAUSE, requestPauseCampaign),
    takeLatest(ACTION_HEADER.EXCLUDE_TARGET, requestExcludeTarget),
    takeLatest(ACTION_HEADER.UPDATE_NOTE, requestUpdateNote),
    takeLatest(ACTION_HEADER.REQUEST_ASSIGN_TAGS, requestAssignTags),
    takeLatest(ACTION_HEADER.REQUEST_EXPORT, requestExport),
    takeLatest(ACTION_HEADER.TARGET_REQUEST_GET, requestGetTargetsByCampaign),
    takeEvery(ACTION_HEADER.REQUEST_CONTACT, requestGetContactInfo),
  ]);
}

export default campaignSaga;
