import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { NavLink, Route, Switch, Redirect } from "react-router-dom";
import { Nav } from "reactstrap";

import AdminNavbar from "components/Navbars/AdminNavbar";

import { userActions, adminActions } from "../actions";
import Users from "views/pages/PPAdmin/Users.jsx";
import Proxy from "views/pages/PPAdmin/Proxy.jsx";

const { requestFileList, requestUserList, requestProxyList } = adminActions;
const { logout } = userActions;

class PPadmin extends React.Component {
  constructor(props) {
    super(props);
    if (props.token) {
      props.requestUserList();
      props.requestProxyList();
    }
  }

  handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  activeRoute = (url) => {
    return this.props.location.pathname.split("/")[2] === url;
  };

  render() {
    const { token } = this.props;
    return token ? (
      <div className="wrapper">
        <div className="sidebar" data-color="brown" data-active-color="info">
          <div className="logo">
            <div className="logo-img">
              <a href="/ppadmin/users">
                <img src={require("assets/img/sp-logo.png")} alt="sp-logo" />
              </a>
            </div>
          </div>
          <div className="sidebar-wrapper" ref="sidebar">
            <div className="user text-center">Admin Avatar</div>
            <Nav>
              <li className={this.activeRoute("users") ? "active" : ""}>
                <NavLink to="/ppadmin/users">
                  <i className="nc-icon nc-user-run"></i>
                  <p>Users</p>
                </NavLink>
              </li>
              <li className={this.activeRoute("proxies") ? "active" : ""}>
                <NavLink to="/ppadmin/proxies">
                  <i className="nc-icon nc-user-run"></i>
                  <p>Proxies</p>
                </NavLink>
              </li>
            </Nav>
          </div>
        </div>
        <div className="main-panel">
          <div
            style={{
              padding: 10,
              textAlign: "center",
              width: 400,
              margin: "auto",
            }}
          >
            {" "}
            <u></u>
            <br /> <br />{" "}
          </div>
          <AdminNavbar
            {...this.props}
            handleMiniClick={this.handleMiniClick}
            logout={() => {
              this.props.logout();
              window.location.reload();
            }}
          />
          <div className="content">
            <Switch>
              <Route
                path="/ppadmin/users"
                render={() => <Users {...this.props} />}
                exact
              />
              <Route
                path="/ppadmin/proxies"
                render={() => <Proxy {...this.props} />}
                exact
              />
            </Switch>
          </div>
        </div>
      </div>
    ) : null;
  }
}

const mapStateToProps = (state) => ({
  token: state.authentication.token,
  userList: state.admin.userlist,
  fileList: state.admin.filelist,
  proxyList: state.admin.proxylist,
  error: state.admin.error,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  requestFileList: () => dispatch(requestFileList()),
  requestUserList: () => dispatch(requestUserList()),
  requestProxyList: () => dispatch(requestProxyList()),
});

export default compose(connect(mapStateToProps, mapDispatchToProps)(PPadmin));
