import React from "react";
import { useSelector } from "react-redux";

const CampaignCustomTagBadge = (props) => {
  const tags = useSelector((state) => state.tag.tag);
  const myTag = tags.filter((tag) => tag._id === props.tag)[0];
  return (
    <span
      className={`badge badge-${myTag && myTag.color ? myTag.color : "primary"
        } badge-pill`}
    >
      {myTag && myTag.title ? myTag.title : ''}
    </span>
  );
};

export default CampaignCustomTagBadge;
