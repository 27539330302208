import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Button, Spinner } from "reactstrap";
import styled from "styled-components";

const StyledCampaignSyncGooglesheetModal = styled.div`
  .option-btns-wrapper {
    display: flex;
    width: 100%;

    .btn {
      flex-grow: 1;
      height: 40px;
    }
  }
`;

const CampaignSyncGooglesheetModal = (props) => {
  const { toggleModal, callback, isOpen, showConfirmDlg } = props;

  const [syncOption, setSyncOption] = useState("replied");
  const gsheetURL = useSelector((state) => state.campaigns.gsheetURL);

  let isExportClicked = useRef(false);

  useEffect(() => {
    if (
      gsheetURL.url !== "" &&
      isOpen &&
      !gsheetURL.loading &&
      isExportClicked.current
    ) {
      showConfirmDlg(gsheetURL.url);
      toggleModal();
    }
    if (
      gsheetURL.error !== "" &&
      isOpen &&
      !gsheetURL.loading &&
      isExportClicked.current
    ) {
      toggleModal();
    }
  });

  return (
    <StyledCampaignSyncGooglesheetModal>
      <div className="option-btns-wrapper">
        <Button
          onClick={() => setSyncOption("replied")}
          className="btn"
          color="default"
          outline={syncOption === "all"}
        >
          replied only
        </Button>
        <Button
          onClick={() => setSyncOption("all")}
          className="btn"
          color="default"
          outline={syncOption === "replied"}
        >
          all new connections
        </Button>
      </div>
      <Button
        className="btn-block"
        color="info"
        disabled={gsheetURL.loading}
        onClick={() => {
          callback("google", syncOption);
          isExportClicked.current = true;
        }}
      >
        {gsheetURL.loading && (
          <Spinner
            className="mr-2"
            animation="border"
            variant="secondary"
            size="sm"
          />
        )}
        {gsheetURL.loading ? "Exporting" : "Export"}
      </Button>
    </StyledCampaignSyncGooglesheetModal>
  );
};

export default CampaignSyncGooglesheetModal;
