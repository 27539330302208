import { ADMIN as ACTION_HEADER } from "./types";

export function requestFileList() {
  return { type: ACTION_HEADER.REQUEST_FILELIST };
}
export function requestUserList() {
  return { type: ACTION_HEADER.REQUEST_USERLIST };
}
export function requestProxyList() {
  return { type: ACTION_HEADER.REQUEST_PROXYLIST };
}
export function requestProxyUpdate({ id, pserver, puser, ppassword }) {
  return {
    type: ACTION_HEADER.REQUEST_PROXYUPDATE,
    id,
    pserver,
    puser,
    ppassword,
  };
}
export function requestProxySave({ pserver, puser, ppassword }) {
  return { type: ACTION_HEADER.REQUEST_PROXYSAVE, pserver, puser, ppassword };
}
export function requestUserCreate({ pserver, puser, ppassword }) {
  return { type: ACTION_HEADER.REQUEST_USERCREATE, pserver, puser, ppassword };
}

export function successFileList(filelist) {
  return { type: ACTION_HEADER.SUCCESS_FILELIST, filelist };
}
export function successUsersList(userlist) {
  return { type: ACTION_HEADER.SUCCESS_USERLIST, userlist };
}
export function successProxyList(proxylist) {
  return { type: ACTION_HEADER.SUCCESS_PROXYLIST, proxylist };
}
export function successProxyUpdate(proxy) {
  return { type: ACTION_HEADER.SUCCESS_PROXYUPDATE, proxy };
}
export function successProxySave(proxy) {
  return { type: ACTION_HEADER.SUCCESS_PROXYSAVE, proxy };
}
export function successUserCreate(proxy) {
  return { type: ACTION_HEADER.SUCCESS_USERCREATE, proxy };
}
export function fail(error, args) {
  return { type: ACTION_HEADER.FAIL, error, args };
}
