/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  Container,
  Col,
  Row,
} from "reactstrap";

import { validator } from "../../utils";
import { userActions } from "../../actions";
const { requestLogin } = userActions;

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: -1,
      password: -1,
      error: "",
    };
  }
  componentDidMount() {
    document.body.classList.toggle("login-page");
    document.getElementsByClassName("navbar")[0].style.display="none";
  }
  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }
  componentDidUpdate(prevProps) {
    if (this.props.error && this.props.error !== prevProps.error) {
      this.setState({ error: this.props.error });
    }
  }
  handleSubmit = (e) => {
    e.preventDefault();
    const { email, password } = this.state;
    if (email === -1) {
      this.setState({ email: "" });
    }
    if (password === -1) {
      this.setState({ password: "" });
    }

    if (
      validator.checkEmailValidation(email) ||
      validator.checkRequiredValidation(password) ||
      email === -1 ||
      password === -1
    ) {
      return;
    }

    this.props.requestLogin(email, password);
  };
  setEmail = (e) => {
    this.setState({ email: e.target.value });
  };
  setPassword = (e) => {
    this.setState({ password: e.target.value });
  };
  render() {
    const { email, error, password } = this.state;
    return (
      <div style={{display:'flex'}}>
        <div className="blue-left-area">
          <div>
            <img src={require("assets/img/sp-art-1.png")} className="aw aw1"></img>
            <img src={require("assets/img/sp-art-2.png")} className="aw aw2"></img>
          </div>
        </div>
        <div className="blue-left-area-text">
          <div className="login-area">
            <img
              src={require("assets/img/face-logo.png")} 
              width="80" style={{marginBottom:'3em'}}
            ></img>
            <h2>Welcome to<br/><span>Social Prospector</span></h2>
            <a href="https://socialprospector.io">
              <button className="btn my-btn">
                Learn More
              </button>
            </a>
          </div>
        </div>
        <div className="my-login-page">
          <div className="mobile-message" style={{display:'none'}}>
            <img
              src={require("assets/img/face-logo.png")} 
              width="40"
            ></img>
            <h2>Welcome to<br/><span>Social Prospector</span></h2>
          </div>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" lg="5" md="8">
                <Form
                  action=""
                  className="form"
                  method=""
                  onSubmit={this.handleSubmit}
                >
                  <Card className="card-login">
                    <CardHeader>
                      <CardHeader>
                        <h3 className="header text-center">Sign in</h3>
                        <div className="my-under-blue"></div>
                      </CardHeader>
                    </CardHeader>
                    <CardBody>
                      {error && (
                        <InputGroup>
                          <Input hidden invalid />
                          <FormFeedback>{error}</FormFeedback>
                        </InputGroup>
                      )}
                      <InputGroup>
                        <Input
                          placeholder="Email"
                          type="email"
                          invalid={validator.checkEmailValidation(email)}
                          onChange={this.setEmail}
                          onFocus={this.setEmail}
                        />
                        <FormFeedback>
                          {email === "" ? `Email required` : `Invalid Email`}
                        </FormFeedback>
                      </InputGroup>
                      <InputGroup className="mike">
                        <Input
                          placeholder="Password"
                          type="password"
                          autoComplete="off"
                          onChange={this.setPassword}
                          onFocus={this.setPassword}
                          invalid={validator.checkRequiredValidation(password)}
                        />
                        <FormFeedback>Password Required</FormFeedback>
                      </InputGroup>
                    </CardBody>
                    <CardFooter>
                      <div style={{ textAlign: "center" }}>
                        <Button className="btn-round mb-3 my-btn-2">
                          Login
                        </Button>
                      </div>
                      <div>
                          <a
                            href={`/auth/forgot-password`}
                            style={{ fontSize: "0.9rem", color: 'black', float: 'left' }}
                          >
                            Forgot password?
                          </a>
                          <a
                            href={`/auth/register/personal-plan`}
                            style={{ fontSize: "0.9rem", color: 'black', float: 'right' }}
                          >
                            Sign Up
                          </a>
                      </div>
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Row>
          </Container>
          <div
            className="full-page-background"
            
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  error: state.authentication.error,
  loading: state.authentication.loading,
});

const mapDispatchToProps = (dispatch) => ({
  requestLogin: (email, password) => dispatch(requestLogin(email, password)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps)(Login));
