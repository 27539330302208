import { PROSPECT as ACTION_HEADER } from "./types";

export function requestAddBlackLinks(blackLinks, account) {
  return { type: ACTION_HEADER.BLACK_LINK_REQUEST_ADD, blackLinks, account };
}

export function successAddBlackLinks(blackLinks) {
  return { type: ACTION_HEADER.BLACK_LINK_SUCCESS_ADD, blackLinks };
}

export function requestGetBlackLinks(liEmail) {
  return { type: ACTION_HEADER.BLACK_LINK_REQUEST_GET, liEmail };
}

export function successGetBlackLinks(blackLinks) {
  return { type: ACTION_HEADER.BLACK_LINK_SUCCESS_GET, blackLinks };
}

export function requestDeleteBlackLink(_id, account) {
  return { type: ACTION_HEADER.BLACK_LINK_REQUEST_DELETE, _id, account };
}

export function successDeleteBlackLink(_id) {
  return { type: ACTION_HEADER.BLACK_LINK_SUCCESS_DELETE, _id };
}

export function fail(error) {
  return { type: ACTION_HEADER.FAIL, error };
}
