import { takeLatest, all, put } from "redux-saga/effects";
import { types, adminActions, userActions } from "../actions";
import { backendUrl } from "../utils";
import axios from "axios";

const { ADMIN } = types;
const {
  successFileList,
  fail,
  successUsersList,
  successUserCreate,
  successProxyList,
  successProxyUpdate,
  successProxySave,
} = adminActions;
const { removeToken } = userActions;

const baseURL = backendUrl + "/admin";
const requestFileListURL = "/filelist";
const requestUsersURL = "/getallusers";
const requestProxyURL = "/getallproxies";
const requestProxyUpdateURL = "/updateproxy";
const requestProxySaveURL = "/saveproxy";
const requestUserCreateURL = "/createuser";

function* requestFileList() {
  try {
    const resp = yield axios.get(baseURL + requestFileListURL);
    yield put(successFileList(resp.data));
  } catch (e) {
    let error = "Network Error";
    if (e.response) {
      if (!e.response.data.errors) {
        error = e.response.data;
        if (error === "Unauthorized") {
          yield put(removeToken());
        }
      } else if (typeof e.response.data.errors.msg === "object") {
        error = e.response.data.errors.msg[0].msg;
      } else {
        error = e.response.data.errors.msg;
      }
    }
    yield put(fail(error));
  }
}
function* requestUserList() {
  try {
    const resp = yield axios.get(baseURL + requestUsersURL);
    yield put(successUsersList(resp.data));
  } catch (e) {
    let error = "Network Error";
    if (e.response) {
      if (!e.response.data.errors) {
        error = e.response.data;
        if (error === "Unauthorized") {
          yield put(removeToken());
        }
      } else if (typeof e.response.data.errors.msg === "object") {
        error = e.response.data.errors.msg[0].msg;
      } else {
        error = e.response.data.errors.msg;
      }
    }
    yield put(fail(error));
  }
}
function* requestProxyList() {
  console.log("****** requestProxyList *******");
  try {
    const resp = yield axios.get(baseURL + requestProxyURL);

    yield put(successProxyList(resp.data));
  } catch (e) {
    let error = "Network Error";
    if (e.response) {
      if (!e.response.data.errors) {
        error = e.response.data;
        if (error === "Unauthorized") {
          yield put(removeToken());
        }
      } else if (typeof e.response.data.errors.msg === "object") {
        error = e.response.data.errors.msg[0].msg;
      } else {
        error = e.response.data.errors.msg;
      }
    }
    yield put(fail(error));
  }
}

function* requestProxyUpdate(action) {
  const { id, pserver, puser, ppassword } = action;
  console.log("**** requestProxyUpdate ****", id, pserver, puser, ppassword);
  try {
    const proxy = yield axios.put(baseURL + requestProxyUpdateURL, {
      id,
      pserver,
      puser,
      ppassword,
    });

    console.log("****** requestProxyUpdate RESULT *******");
    const data = proxy.data.data;
    console.log(proxy.data.data);
    yield put(
      successProxyUpdate({
        linkedins: [],
        server: data.proxyServer,
        user: data.username,
        usage: 0,
        createdAt: data.createdAt,
        id: data._id,
      })
    );
  } catch (e) {
    let error = "Network Error";
    if (e.response) {
      if (!e.response.data.errors) {
        error = e.response.data;
        if (error === "Unauthorized") {
          yield put(removeToken());
        }
      } else if (typeof e.response.data.errors.msg === "object") {
        error = e.response.data.errors.msg[0].msg;
      } else {
        error = e.response.data.errors.msg;
      }
    }
    yield put(fail(error));
  }
}

function* requestUserCreate(action) {
  const { pserver, puser, ppassword } = action;
  console.log("**** requestUserCreate ****", action);
  try {
    const resp = yield axios.post(baseURL + requestUserCreateURL, {
      email: puser,
      password: ppassword,
      billDate: pserver,
    });
    console.log("**** requestUserCreate ****", resp.data);
    yield put(fail(null));

    yield put(successUserCreate(resp.data));
  } catch (e) {
    let error = "Network Error";
    if (e.response) {
      if (!e.response.data.errors) {
        error = e.response.data;
        if (error === "Unauthorized") {
          yield put(removeToken());
        }
      } else if (typeof e.response.data.errors.msg === "object") {
        error = e.response.data.errors.msg[0].msg;
      } else {
        error = e.response.data.errors.msg;
      }
    }
    console.log("**** requestUserCreate ****", error);

    yield put(fail(error));
  }
}
function* requestProxySave(action) {
  const { pserver, puser, ppassword } = action;
  console.log("**** requestProxySave ****");
  try {
    const proxy = yield axios.post(baseURL + requestProxySaveURL, {
      pserver,
      puser,
      ppassword,
    });
    console.log("****** requestProxy RESULT *******");
    const data = proxy.data.data;
    console.log(proxy.data.data);
    yield put(
      successProxySave({
        linkedins: [],
        server: data.proxyServer,
        user: data.username,
        usage: 0,
        createdAt: data.createdAt,
        id: data._id,
      })
    );
  } catch (e) {
    let error = "Network Error";
    if (e.response) {
      if (!e.response.data.errors) {
        error = e.response.data;
        if (error === "Unauthorized") {
          yield put(removeToken());
        }
      } else if (typeof e.response.data.errors.msg === "object") {
        error = e.response.data.errors.msg[0].msg;
      } else {
        error = e.response.data.errors.msg;
      }
    }
    yield put(fail(error));
  }
}
function* adminSaga() {
  yield all([
    takeLatest(ADMIN.REQUEST_FILELIST, requestFileList),
    takeLatest(ADMIN.REQUEST_USERLIST, requestUserList),
    takeLatest(ADMIN.REQUEST_PROXYLIST, requestProxyList),
    takeLatest(ADMIN.REQUEST_PROXYUPDATE, requestProxyUpdate),
    takeLatest(ADMIN.REQUEST_PROXYSAVE, requestProxySave),
    takeLatest(ADMIN.REQUEST_USERCREATE, requestUserCreate),
  ]);
}

export default adminSaga;
