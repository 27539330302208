import { types } from "../actions";

const { TAG: ACTION_HEADER } = types;

const initialState = {
  tag: [],
  loading: false,
  fetched: false,
};

function tagReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_HEADER.REQUEST_GET_TAG: {
      return state;
    }
    case ACTION_HEADER.SUCCESS_GET_TAG: {
      return {
        ...state,
        fetched: true,
        tag: action.data.tags,
      };
    }
    case ACTION_HEADER.RESET_TAG: {
      return {
        ...state,
        tag: [],
        fetched: false,
      };
    }
    // case ACTION_HEADER.REQUEST_CREATE_TAG: {
    // }
    //   break;
    // case ACTION_HEADER.SUCCESS_CREATE_TAG: {
    // }
    //   break;
    // case ACTION_HEADER.REQUEST_DELETE_TAG: {
    // }
    //   break;
    // case ACTION_HEADER.SUCCESS_DELETE_TAG: {
    // }
    //   break;
    // case ACTION_HEADER.FAIL: {
    // }
    //   break;
    default: {
      return state;
    }
  }
}

export default tagReducer;
