import React, { useState } from "react";
import { Button, Spinner, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";
import EmojiPicker from "./EmojiPicker";
import ReplayTemplatesModal from '../ReplayTemplatesModal/replayTemplatesModal'
import { Form } from "react-bootstrap";
import { compose } from "recompose";
import { connect } from "react-redux";

const SendMessageBlock = (props) => {
  const {
    onSend,
    toggleFirstOpen,
    firstOpen,
    messages,
    messageValue,
    handleInputChange,
    liaccount,
    replayTemplates
  } = props;
  const messageData = useSelector((state) => state.message);

  const handleChange = (event) => {
    handleInputChange(event.target.value);
  };
  const [isOpenEmojiPicker, toggleEmojiPicker] = useState(false);
  const sendingStatus = messages.filter(msg => msg.status === 'sending').length > 0;
  const [showReplayTemplate, setShowReplayTemplate] = useState(false);
  const setShowReplayTemplateToggle = () => {
    setShowReplayTemplate(!showReplayTemplate);
  }

  return (
    <>
      <div className="send-msg-container send-message">
        <hr />
        <div className="avatar">
          <img className="maxwidth-avatar" src={props.userImgUrl} alt="crash" />
        </div>
        <div className="message-wrapper">
          <TextareaAutosize
            className="input"
            placeholder="Type message..."
            disabled={messageData.loading}
            value={messageValue}
            onChange={handleChange}
            maxRows={7}
            style={{
              height: "38px",
              border: "#e0e0e0 1px solid",
              padding: "5px",
              marginRight: "3px",
            }}
          />

          <EmojiPicker
            isOpen={isOpenEmojiPicker}
            showGif={true}
            togglePicker={() => toggleEmojiPicker(!isOpenEmojiPicker)}
            handleInputChange={(emoji) =>
              handleInputChange(`${messageValue}${emoji}`)
            }
            sendGif={handleInputChange}
          />
        </div>
        <div className="send-button">
          <Button
            onClick={() => {
              onSend();
              toggleFirstOpen();
            }}
            className="btn-default"
            disabled={messageData.loading || !messageValue || sendingStatus}
          >
            {messageData.loading ? (
              <Spinner animation="border" variant="secondary" size="sm" />
            ) : (
                <i className="nc-send nc-icon"></i>
              )}
          </Button>
        </div>
      </div>
      <div className="reply-error-msg">
        <p
          className={`mb-3 ${messageData.success ? "text-success" : "text-danger"
            }`}
          style={{ marginLeft: "50px" }}
        >
          {!firstOpen &&
            (messageData.message === "sent"
              ? ""
              : messageData.message === "user is not in your connection"
                ? "You’re not connected with this user"
                : messageData.message === "try again later"
                  ? "Try again later"
                  : messageData.message)}
        </p>
      </div>
      <hr style={{ margin: "0px 220px" }} />
      <div className="text-center muted"><small>Quick Replies</small></div>
      <div className="my-2">
        <div className="col-sm-10" style={{ display: "inline-block" }} >
          <Form.Group controlId="formHorizontalEmail">

            <Form.Control as="select" onChange={(e) => {
              handleInputChange(e.target.value);
            }} >
              <option value={''} >Quick Reply</option>
              {
                replayTemplates && replayTemplates.map((item) => {
                  return <option value={item.message} >{item.name}</option>
                })
              }
            </Form.Control>

          </Form.Group>
        </div>

        <div className="col-sm-2" style={{ display: "inline", padding: "0" }}>
          <Button onClick={setShowReplayTemplateToggle} className="add-btn el-inline btn btn-primary btn-icon btn-round btn btn-secondary" ><i class="nc-icon nc-simple-add"></i></Button>
        </div>
      </div>
      <ReplayTemplatesModal liaccount={liaccount} showReplayTemplateModal={showReplayTemplate} replayTemplateModalToggel={setShowReplayTemplateToggle} />
    </>
  );
};

const mapStateToProps = (state) => ({
  replayTemplates: state.replayTemplates.replayTemplates,
});

export default compose(
  connect(mapStateToProps)(SendMessageBlock)
);