/* eslint-disable */
// this was added just to clear up the dev console. Pls fix warnings if its possible

import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import { Line } from "react-chartjs-2";
import { chartExample12 } from "variables/charts.jsx";
import { actionToActivity } from "../../../utils";
import moment from "moment";

import {
  recentDays,
  statusCount,
  connectedCount,
  dateRange,
} from "../../../utils";

const CampaignAnalysisCard = (props) => {
  const { campaign, campaignType, timezone } = props;

  const [timeFrameDropdownOpen, setTimeFrameDropdownOpen] = useState(false);
  const [selectetTimeFrameToDisplay, setSelectetTimeFrameToDisplay] = useState(
    10
  );

  const getAction = (name) => {
    const campData = campaign.data.filter(
      (campData) => campData.name === name
    )[0];

    const actions = [];

    if (
      campaign.repliedData.findIndex((dat) => dat.targetName === name) !== -1
    ) {
      actions.push("Replied");
    }
    if (
      campaign.connectionData.findIndex((dat) => dat.targetName === name) !== -1
    ) {
      actions.push("Accepted");
    }

    if (campData) {
      actions.push(
        ...campData.actions
          .filter(Boolean)
          .map(({ name }) => actionToActivity(name))
      );
    }

    return actions.length ? actions : ["None"];
  };

  const recentDates = recentDays(selectetTimeFrameToDisplay);
  // const recentDates = dateRange(moment().subtract(10, "days"), moment().add(1, "day"))

  let connectionData = [];
  let acceptance = 0;
  let repliedRate = 0;

  let requestedTotal = 0;
  let acceptedTotal = 0;
  let repliedTotal = 0;

  if (campaign) {
    connectionData = recentDates.map((date) => {
      if (campaignType === "INMAIL")
        return {
          requested: statusCount(campaign.data, "INMAIL", 1, date, timezone),
          replied: statusCount(
            campaign.repliedData,
            "REPLIED",
            1,
            date,
            timezone
          ),
          accepted: connectedCount(campaign.connectionData, date, timezone),
        };
      else
        return {
          requested: statusCount(campaign.data, "CONNECT", 1, date, timezone),
          replied: statusCount(
            campaign.repliedData,
            "REPLIED",
            1,
            date,
            timezone
          ),
          accepted: connectedCount(campaign.connectionData, date, timezone),
        };
    });

    requestedTotal = campaign.data.length;
    acceptedTotal = campaign.targets.filter(
      (target) => getAction(target.name).indexOf("Accepted") > -1
    ).length;
    repliedTotal = campaign.repliedData.length;

    if (requestedTotal) {
      acceptance = ((acceptedTotal * 100) / requestedTotal).toFixed(1);
      if (campaignType === "INMAIL") acceptance = "N/A";
    }
    if (repliedTotal) {
      let requested = 0;
      if (requestedTotal > 0) requested = requestedTotal;
      else requested = campaign.data.length;
      repliedRate = ((repliedTotal * 100) / requested).toFixed(1);
    }
  }

  const graphData = {
    labels: recentDates.map((date) => date.format("MMM D")),
    datasets: [3, 2, 1].map((key) => {
      // console.log(" KEY --->", key);
      let label = "Replies";
      if (key == 1) label = "Requests Sent";
      if (key === 2) label = "New connections";

      let borderColor = "#6bd098";
      if (key === 1) borderColor = "#fbc658";
      if (key === 2) borderColor = "#51bcda";

      let backgroundColor = "#6bd098";
      if (key === 1) backgroundColor = "#fbc658";
      if (key === 2) backgroundColor = "#51bcda";

      let data = connectionData.map(({ replied }) => replied);
      if (key === 1) data = connectionData.map(({ requested }) => requested);
      if (key === 2) data = connectionData.map(({ accepted }) => accepted);

      return {
        label,
        borderColor,
        backgroundColor,
        pointRadius: 0,
        pointHoverRadius: 0,
        borderWidth: 3,
        data,
      };
    }),
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h4" className="el-inline">
          Outreach Analysis
        </CardTitle>

        <p className="pull-right el-inline">
          <span className="badge badge-pill badge-green-border">{`${repliedRate}% replied`}</span>
        </p>

        <p className="pull-right el-inline">
          <span className="badge badge-pill badge-blue-border">{`${acceptance}% acceptance`}</span>
        </p>
      </CardHeader>
      <CardBody>
        <Line data={graphData} options={chartExample12.options} height={75} />
      </CardBody>
      <CardFooter>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="legend">
            <i className="fa fa-circle text-warning" />
            {`Requests sent (${requestedTotal})`} &nbsp;{" "}
            <i className="fa fa-circle text-info" />
            {`New connections (${acceptedTotal})`} &nbsp;{" "}
            <i className="fa fa-circle text-success" />
            {`Replies (${repliedTotal})`}
          </div>
          <Dropdown
            isOpen={timeFrameDropdownOpen}
            toggle={() => setTimeFrameDropdownOpen((prevState) => !prevState)}
          >
            <DropdownToggle caret className="btn-sm">
              Date range
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => setSelectetTimeFrameToDisplay(10)}>
                10 days
              </DropdownItem>
              <DropdownItem onClick={() => setSelectetTimeFrameToDisplay(30)}>
                30 days
              </DropdownItem>
              <DropdownItem onClick={() => setSelectetTimeFrameToDisplay(60)}>
                60 days
              </DropdownItem>
              <DropdownItem onClick={() => setSelectetTimeFrameToDisplay(90)}>
                90 days
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </CardFooter>
    </Card>
  );
};

export default CampaignAnalysisCard;
