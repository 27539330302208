import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import moment from "moment";
import ProfileImage from "./ProfileImage";

const LIAccountCard = (props) => {
  const {
    _id,
    profileImg,
    headline,
    summary,
    name,
    email,
    activeCMPcnt,
    connections,
    removeAccount,
    reLoginRequired,
    toggleReLoginModal,
    // eslint-disable-next-line
    toggleEditLIAccountModal,
    billDate,
  } = props;

  const accountBillDate = billDate ? moment(billDate * 1000) : moment();

  const isExpired = useMemo(() => {
    const currentDate = moment();
    if (accountBillDate > currentDate) {
      return false;
    } else return true;
  }, [accountBillDate]);

  const goToAccount = (e) => {
    e.preventDefault();
    props.goToAccount(_id);
  };
  const updateProfile = () => {
    const { email, profileLink } = props;
    props.requestUpdateProfile(email, profileLink);
  };
  return (
    <div className="col-sm-6 col-md-3">
      <Card
        className={
          "liaccount " + (reLoginRequired || isExpired ? "disabled-card" : "")
        }
      >
        <div
          className="d-flex justify-content-end"
          style={{ marginBottom: -6 }}
        >
          <UncontrolledDropdown>
            <DropdownToggle
              aria-haspopup={true}
              color="default"
              data-toggle="dropdown"
              id="editProfileDropdownMenu"
              caret
              nav
            >
              <i className="nc-icon nc-settings-gear-65 mr-2"></i>
            </DropdownToggle>
            <DropdownMenu
              aria-labelledby="editProfileDropdownMenu"
              className="fadeIn"
              right
            >
              <DropdownItem
                onClick={() =>
                  toggleEditLIAccountModal(email, headline, summary)
                }
              >
                Edit Profile
              </DropdownItem>
              <DropdownItem href="/admin/user-profile">
                Manage Subscription
              </DropdownItem>
              <DropdownItem onClick={() => removeAccount(_id)}>
                Remove Account
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>

        <CardBody>
          <div className="row liac-top" onClick={(e) => goToAccount(e)}>
            <div className="col-md-4">
              <ProfileImage
                info={{ profileImg, name }}
                onUpdate={() => updateProfile()}
              />
            </div>
            <div className="col-md-8 li-user-info">
              <h3 className="">{name}</h3>
              <p className="text-muted">{headline}</p>
              {isExpired && <h6>Expired</h6>}
            </div>
          </div>
          <button
            hidden={!reLoginRequired}
            onClick={toggleReLoginModal}
            className="btn btn-sm btn-block btn-default re-login"
          >
            Re-login
          </button>

          <div className="liac-bottom" hidden={reLoginRequired ? "hidden" : ""}>
            <span className="text-center badge badge-pill badge-success">
              {activeCMPcnt ? activeCMPcnt : "0"} Active Campaigns
            </span>
            <span className="text-center badge badge-pill badge-info">
              {connections} connections
            </span>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default LIAccountCard;
