import { combineReducers } from "redux";
import authReducer from "./authReducer";
import liaccountReducer from "./liaccountReducer";
import sequenceReducer from "./sequenceReducer";
import integrationReducer from "./integrationReducer";
import prospectReducer from "./prospectReducer";
import campaignReducer from "./campaignReducer";
import planReducer from "./planReducer";
import adminReducer from "./adminReducer";
import messageReducer from "./messageReducer";
import tagReducer from "./tagReducer";
import errorReducer from "./errorReducers";
import notesReducer from "./notesReducer";
import replayTemplatesReducer from "./replayTemplatesReducer";
import { connectRouter } from "connected-react-router";

const appReducer = (history) =>
  combineReducers({
    authentication: authReducer,
    liaccounts: liaccountReducer,
    sequences: sequenceReducer,
    integrations: integrationReducer,
    prospects: prospectReducer,
    campaigns: campaignReducer,
    plans: planReducer,
    admin: adminReducer,
    message: messageReducer,
    tag: tagReducer,
    exception: errorReducer,
    notes: notesReducer,
    replayTemplates: replayTemplatesReducer,
    router: connectRouter(history),
  });

function rootReducer(history) {
  return appReducer(history);
}

export default rootReducer;
