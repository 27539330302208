import { NOTES as ACTION_HEADER } from "./types";

export function requestGetNotes(target_id) {
  return {
    type: ACTION_HEADER.REQUEST_NOTES,
    target_id,
  };
}

export function getNotesSuccess(notes) {
  return {
    type: ACTION_HEADER.GET_NOTES_SUCCESS,
    notes,
  };
}

export function getNotesError() {
  return {
    type: ACTION_HEADER.GET_NOTES_ERROR,
  };
}

export function requestUpdateNotes(target_id, notes) {
  return {
    type: ACTION_HEADER.REQUEST_NOTES_UPDATE,
    target_id,
    notes,
  };
}

export function updateNotesSuccess(notes) {
  return {
    type: ACTION_HEADER.NOTES_UPDATE_SUCCESS,
    notes,
  };
}

export function updateNotesError(notes) {
  return {
    type: ACTION_HEADER.NOTES_UPDATE_ERROR,
    notes,
  };
}
