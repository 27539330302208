import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import { Button, Card, CardBody, Row, Col, Input } from "reactstrap";
import ReactTable from "react-table";
import ProspectContactInfoModal from "./LinkedinInfoModal";
import Cookies from "js-cookie";
import { adminActions } from "../../../actions";
import { useDispatch } from "react-redux";
import NotificationAlert from "react-notification-alert";

const { requestUserCreate } = adminActions;

const Users = (props) => {
  // console.log(props);
  const { userList, error } = props;
  console.log("--props------");
  console.log(props);

  // const [selectedCategory, setSelectedCategory] = useState("");
  const [users, setUsers] = useState([]);
  const [prospectInfoModel, setProspectInfoModel] = useState(false);
  const [openProspectInfo, setOpenProspectInfo] = useState({});
  const [server, setServer] = useState("");
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const notificationAlert = useRef();

  useEffect(() => {
    if (userList.data) {
      setUsers(userList.data.userData);
    }
    if (error) {
      console.log(error);
      let options = {
        place: "tc",
        message: (
          <div>
            <div>{error}</div>
          </div>
        ),
        type: "danger",
        icon: "now-ui-icons ui-1_bell-53",
        autoDismiss: 7,
      };
      notificationAlert.current.notificationAlert(options);
    }
  }, [userList, users, error]);
  const setProspectInfoToggle = (e, data) => {
    // console.log("e", e)
    if (e && e.currentTarget) e.preventDefault();
    if (data) {
      setOpenProspectInfo({
        title: data.name,
        ...data,
      });
    }

    setProspectInfoModel(!prospectInfoModel);
  };
  const addProxy = (server, user, password) => {
    console.log("save -- ", server, user, password);
    setServer("");
    setUser("");
    setPassword("");
    dispatch(
      requestUserCreate({ pserver: server, puser: user, ppassword: password })
    );
    // saveProxy(server, user, password)
  };
  const dispatch = useDispatch();

  const filterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id;
    // console.log(id)
    // console.log(row)
    // console.log(row[id])

    if (row[id]) {
      const orgStringArray = row[id].toLowerCase().split(" ");
      const filterString = filter.value.toLowerCase();
      for (let i in orgStringArray) {
        if (orgStringArray.slice(i).join(" ").startsWith(filterString)) {
          return true;
        }
      }

      return false;
    }
    return true;
  };
  const loginToUser = (a, b) => {
    Cookies.remove("BRJWT");
    Cookies.set("BRJWT", `Bearer ${b}`);
    window.location.reload();
    window.location.href = "/admin";
  };
  const userData =
    users &&
    users.map((seq) => ({
      email: (
        <span className="el-inline" style={{ color: "green", fontWeight: 500 }}>
          {seq.email}
        </span>
      ),
      linkedins: seq.linkedinData.map((step, index) => (
        <div
          className="el-inline"
          style={{ display: "flex", color: "#007bff", fontWeight: 500 }}
          key={step.name + index}
        >
          <a
            className="el-inline"
            href="/"
            onClick={(e) => setProspectInfoToggle(e, step)}
            style={{ display: "flex", color: "#007bff", fontWeight: 500 }}
          >
            {step.name}
          </a>
        </div>
      )),
      billing: seq.linkedinData.map((step, index) => (
        <div
          className="el-inline"
          style={{ display: "flex", fontWeight: 500 }}
          key={step.name + index}
        >
          <span
            className="el-inline"
            style={{ color: "green", fontWeight: 500 }}
          >
            {step.billDate > Date.now() / 1000 ? "Active" : "Expired"}
          </span>
          <span className="el-inline">
            ({step.isSubscribed ? " subscribed " : " canceled "})
          </span>
        </div>
      )),
      status: seq.linkedinData.map((step, index) => (
        <div
          className="el-inline"
          style={{ display: "flex", fontWeight: 500 }}
          key={step.name + index}
        >
          <span
            className="el-inline"
            style={{ color: "blue", fontWeight: 500 }}
          >
            {step.reLoginRequired ? "Disconnected" : "Normal"}
          </span>
        </div>
      )),
      plan: seq.linkedinData.map((step, index) => (
        <div
          className="el-inline"
          style={{ display: "flex", fontWeight: 500 }}
          key={step.name + index}
        >
          <span
            className="el-inline"
            style={{ color: "black", fontWeight: 500 }}
          >
            {step.isTrial ? "Trial" : step.isBasicPlan ? "$29" : "$49"}
          </span>
          <span
            className="el-inline"
            style={{ color: "black", fontWeight: 500 }}
          >
            {step.isTrial ? (step.isBasicPlan ? " ($29) " : "($49)") : ""}
          </span>
        </div>
      )),
      a_date: seq.linkedinData.map((step, index) => (
        <div
          className="el-inline"
          style={{ display: "flex", fontWeight: 500 }}
          key={step.name + index}
        >
          <span className="el-inline" style={{ color: "black" }}>
            {moment(step.billDate * 1000).format("DD-MM-YYYY")}
          </span>
        </div>
      )),
      stripeId: (
        <span style={{ display: "flex", fontWeight: 500 }}>
          {" "}
          {seq.customerId}
        </span>
      ),
      createdAt: (
        <span style={{ display: "flex", fontWeight: 500 }}>
          {" "}
          {seq.registered.substring(0, 10)}
        </span>
      ),
      actions: (
        <div className="">
          <Button
            color="danger"
            size="md"
            className="btn-icon btn-default btn-link remove"
            onClick={(e) => this.warningWithConfirmMessage(e, seq.identifier)}
          >
            <i className="fa fa-times" />
          </Button>
          <Button
            color="primary"
            size="md"
            id={"tooltip-clone-btn-" + seq._id}
            className="btn-icon btn-default btn-link"
            onClick={(e) => this.navigateToSequence(e, seq.identifier, "copy")}
          >
            <i className="fa fa-clipboard" />
          </Button>
        </div>
      ),
      login: seq.ids ? (
        <div className="">
          <Button
            color="danger"
            size="md"
            className="btn-icon btn-default btn-link remove"
            onClick={(e) => loginToUser(e, seq.ids)}
          >
            <i className="fa fa-sign-in" />
          </Button>
        </div>
      ) : (
        <div></div>
      ),
    }));
  return (
    <div>
      <ProspectContactInfoModal
        setProspectInfoToggle={setProspectInfoToggle}
        prospectInfoModel={prospectInfoModel}
        openProspectInfo={openProspectInfo}
      />
      <NotificationAlert ref={notificationAlert} />
      <div className="d-flex">
        <Row style={{ width: "100%" }}>
          <Col md="12">
            <div>
              <h3 className="el-inline">User List</h3>

              <Input
                type="text"
                name="success"
                id="success"
                value={user}
                className="el-inline"
                onChange={(e) => {
                  setUser(e.target.value);
                }}
                placeholder="Add Username"
                style={{ width: 200, margin: 10 }}
              />
              <Input
                type="text"
                name="success"
                id="success"
                className="el-inline"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                placeholder="Add password"
                style={{ width: 200, margin: 10 }}
              />
              <Input
                type="number"
                name="success"
                id="adays"
                className="el-inline"
                value={server}
                onChange={(e) => {
                  setServer(e.target.value);
                }}
                style={{ width: 200, margin: 10 }}
                placeholder="Add days"
              />
              <Button
                className="btn-success"
                onClick={() => {
                  addProxy(server, user, password);
                }}
                style={{
                  width: 150,
                  margin: 10,
                  height: 30,
                  padding: 5,
                }}
              >
                Add User
              </Button>
            </div>
            <Card>
              <CardBody>
                <ReactTable
                  style={{ fontWeight: 500 }}
                  data={userData}
                  resizable={true}
                  filterable
                  columns={[
                    {
                      Header: "Email",
                      accessor: "email",
                      sortable: false,
                      filterable: true,
                      width: 320,
                    },
                    {
                      Header: "Customer ID",
                      accessor: "stripeId",
                      sortable: false,
                      filterable: false,
                      width: 200,
                    },
                    {
                      Header: "Joined At",
                      accessor: "createdAt",
                      sortable: true,
                      filterable: false,
                      width: 150,
                    },
                    {
                      Header: "Linkedins",
                      accessor: "linkedins",
                      sortable: false,
                      filterable: false,
                      width: 200,
                    },
                    {
                      Header: "Billing",
                      accessor: "billing",
                      sortable: false,
                      filterable: false,
                      width: 180,
                    },
                    {
                      Header: "Status",
                      accessor: "status",
                      sortable: false,
                      filterable: false,
                      width: 100,
                    },
                    {
                      Header: "Plan",
                      accessor: "plan",
                      sortable: false,
                      filterable: false,
                      width: 100,
                    },
                    {
                      Header: "Available Date",
                      accessor: "a_date",
                      sortable: false,
                      filterable: false,
                    },
                    {
                      Header: "Action",
                      accessor: "login",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  defaultPageSize={10}
                  showPaginationTop={false}
                  showPaginationBottom
                  defaultFilterMethod={filterMethod}
                  /*
                  You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                */
                  className=" -highlight sequence-manager-table"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default Users;
