import React, { Fragment, useState } from "react";

/* eslint-disable */
// this was added just to clear up the dev console. Pls fix warnings if its possible

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Input,
  UncontrolledAlert,
  FormFeedback,
  InputGroup,
  Button,
  Row,
  UncontrolledTooltip,
  Col,
} from "reactstrap";

// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";
import { validator } from "utils";
import EmojiPicker from "../campaigns/ProspectMessagingModal/EmojiPicker";
var ConnectIds = 1;
var MessageIds = 1000;
const insertAtCaret = (areaId, text) => {
  var txtarea = document.getElementById(areaId);
  var scrollPos = txtarea.scrollTop;
  var strPos = 0;
  var br =
    txtarea.selectionStart || txtarea.selectionStart == "0"
      ? "ff"
      : document.selection
      ? "ie"
      : false;
  if (br == "ie") {
    txtarea.focus();
    var range = document.selection.createRange();
    range.moveStart("character", -txtarea.value.length);
    strPos = range.text.length;
  } else if (br == "ff") strPos = txtarea.selectionStart;

  var front = txtarea.value.substring(0, strPos);
  var back = txtarea.value.substring(strPos, txtarea.value.length);
  txtarea.value = front + text + back;
  strPos = strPos + text.length;
  if (br == "ie") {
    txtarea.focus();
    var range = document.selection.createRange();
    range.moveStart("character", -txtarea.value.length);
    range.moveStart("character", strPos);
    range.moveEnd("character", 0);
    range.select();
  } else if (br == "ff") {
    txtarea.selectionStart = strPos;
    txtarea.selectionEnd = strPos;
    txtarea.focus();
  }
  txtarea.scrollTop = scrollPos;
};

const SequenceCard = ({
  keyConnect,
  key2,
  sequence,
  cardRef,
  toggleSequence,
  setMessage,
  toggleStopPrevDetected,
  changeTimeoutNumber,
  changeTimeoutUnit,
  indexOn,
  draggable,
  controllable,
  ...restProps
}) => {
  const {
    name,
    timeoutNumber,
    timeoutUnit,
    msg,
    stopPrevDetected,
    toggle,
  } = sequence;
  const [isOpenEmojiPicker, toggleEmojiPicker] = useState(false);

  return (
    <Row>
      <Col xs={11} className="sequence-builder">
        <div className={controllable ? "" : "cover"} />
        <Card {...restProps} innerRef={cardRef} key={name}>
          <CardHeader>
            <h5 className="pull-left" style={{ margin: 0 }}>
              {name.toUpperCase()}
            </h5>
            <div className="pull-right">
              <Switch
                offColor="primary"
                onColor="primary"
                value={toggle}
                onChange={toggleSequence}
                disabled={!draggable}
              />
            </div>
          </CardHeader>
          <CardBody>
            {toggle && (
              <>
                {name === "CONNECT" && (
                  <>
                    <Card style={{ boxShadow: "none" }}>
                      <p>Connection Message</p>
                      <InputGroup>
                        <Input
                          type="textarea"
                          id={keyConnect}
                          maxLength="275"
                          value={msg !== -1 ? msg : ""}
                          onChange={(e) => setMessage(e.target.value)}
                          onClick={(e) => setMessage(e.target.value)}
                          placeholder="Hi {firstName}, I'd like to add you to my network."
                          invalid={
                            validator.checkSequenceMessageValidation(msg) ||
                            msg.length > 275
                          }
                        />
                        <FormFeedback>
                          {msg.length > 275
                            ? "Message length limit reached"
                            : "The firstName syntax is wrong. You must input {firstName}"}
                        </FormFeedback>
                      </InputGroup>
                      <small className="text-muted el-inline">
                        <span>{`${
                          275 - (msg === -1 ? 0 : msg.length)
                        } characters remaining.`}</span>
                        &nbsp;
                        <i
                          className="nc-icon nc-alert-circle-i text-muted el-inline"
                          id="tooltip-max"
                        ></i>
                      </small>

                      <UncontrolledTooltip
                        placement="bottom"
                        delay={0}
                        target="tooltip-max"
                      >
                        Using the {"{firstName}"} variable may increase the
                        character count beyond LI's 299 char limit. Consider
                        avoiding long connection messages.
                      </UncontrolledTooltip>
                    </Card>
                    <div className="d-flex justify-content-start mt-6">
                      <div className="send-msg-container">
                        <div className="message-wrapper pull-right">
                          {/* <Button
                            color="info"
                            onClick={() => insertAtCaret(keyConnect, "{firstName}")}
                          >{`Insert {firstName}`}</Button>

                          <Button
                            color="info"
                            onClick={() => insertAtCaret(keyConnect, "{companyName}")}
                          >{`Insert {companyName}`}</Button> */}

                          <EmojiPicker
                            isOpen={isOpenEmojiPicker}
                            togglePicker={() =>
                              toggleEmojiPicker(!isOpenEmojiPicker)
                            }
                            handleInputChange={(emoji) => {
                              if (msg !== -1) {
                                setMessage(`${msg}${emoji}`);
                              } else {
                                setMessage(`${emoji}`);
                              }
                            }}
                            showGif={false}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <UncontrolledAlert className="alert-info" fade={false}>
                      <span onClick={() => setMessage(msg + "{firstName}")}>
                        <b>Insert </b>
                        {"{firstName}"}
                      </span>
                    </UncontrolledAlert> */}
                    <p className="descriptor" style={{ paddingLeft: 20 }}>
                      <small>
                        Connection invitations are withdrawn if we detect 1200+
                        invites sent. This ensures that your account is keep
                        safe from LI restrictions regarding too many pending
                        requests.
                      </small>
                    </p>
                  </>
                )}
                {name.includes("MESSAGE") && (
                  <Card style={{ boxShadow: "none" }}>
                    <p>Followup Message</p>
                    <InputGroup>
                      <Input
                        type="textarea"
                        value={msg !== -1 ? msg : ""}
                        id={key2}
                        onChange={(e) => setMessage(e.target.value)}
                        onClick={(e) => setMessage(e.target.value)}
                        placeholder="Hi {firstName}, thanks for connecting. It would be great to compare notes with you and share my expertise. When are you available for a quick chat?"
                        invalid={
                          validator.checkRequiredValidation(msg) ||
                          validator.checkSequenceMessageValidation(msg)
                        }
                      />
                      <FormFeedback>
                        {validator.checkRequiredValidation(msg)
                          ? "Message is Required"
                          : "Your firstName syntax is wrong. You must input {firstName}"}
                      </FormFeedback>
                    </InputGroup>
                    <p className="descriptor">
                      The message will be scheduled for sending after we detect
                      that the invitation has been accepted.
                    </p>
                    <div className="d-flex justify-content-start mt-6">
                      <div className="send-msg-container">
                        <div className="message-wrapper pull-right">
                          {/* <Button
                            color="info"
                            onClick={() => insertAtCaret(key2, "{firstName}")}
                          >{`Insert {firstName}`}</Button> */}
                          <EmojiPicker
                            isOpen={isOpenEmojiPicker}
                            togglePicker={() =>
                              toggleEmojiPicker(!isOpenEmojiPicker)
                            }
                            handleInputChange={(emoji) => {
                              if (msg !== -1) {
                                setMessage(`${msg}${emoji}`);
                              } else {
                                setMessage(`${emoji}`);
                              }
                            }}
                            showGif={false}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-column justify-content-start mt-4">
                      <Switch
                        offColor="primary"
                        onColor="primary"
                        value={stopPrevDetected}
                        onChange={toggleStopPrevDetected}
                      />
                      <p className="text-left ml-2">
                        Skip this step if the recipient messages you first
                      </p>
                    </div>
                  </Card>
                )}
                {indexOn > 1 && (
                  <Card style={{ boxShadow: "none", marginTop: 20 }}>
                    <Row>
                      <p
                        style={{
                          display: "inline-block",
                          marginLeft: 20,
                          marginTop: 5,
                        }}
                      >
                        Perform
                      </p>
                      <Col xl="2" lg="3" md="3" sm="3" xs="3">
                        <Input
                          type="number"
                          value={timeoutNumber}
                          onChange={(e) => changeTimeoutNumber(e.target.value)}
                          min={1}
                        ></Input>
                      </Col>
                      <Col xl="2" lg="3" md="3" sm="3" xs="3">
                        <Input
                          type="select"
                          value={timeoutUnit}
                          onChange={(e) => changeTimeoutUnit(e.target.value)}
                          style={{ height: 32 }}
                        >
                          {/*<option value="minutes">minutes</option>*/}
                          <option value="hours">hours</option>
                          <option value="days">days</option>
                        </Input>
                      </Col>
                      <p
                        style={{
                          display: "inline-block",
                          marginRight: 20,
                          marginTop: 5,
                        }}
                      >
                        after previous step
                      </p>
                      <p className="descriptor" style={{ paddingLeft: 20 }}>
                        Action will be performed the next available day if time
                        falls outside of the working hours specified in
                        settings.
                      </p>
                    </Row>
                  </Card>
                )}
              </>
            )}
          </CardBody>
        </Card>
      </Col>
      {indexOn > 0 && toggle && <h1>{indexOn}</h1>}
    </Row>
  );
};

const InmailCard = (props) => {
  const {
    inmail,
    handleInMailToggle,
    handleInMailSubject,
    handleInMailMsg,
    handleSendOpenProfilesToggle,
  } = props;
  const { toggle, sendOpenProfiles, subject, msg } = inmail;

  return (
    <Row>
      <Col xs={11} className="sequence-builder">
        <Card style={{ padding: 10 }}>
          <CardHeader>
            <h5 className="pull-left" style={{ margin: 0 }}>
              INMAIL MESSAGE
            </h5>
            <div className="pull-right">
              <Switch
                offColor="primary"
                onColor="primary"
                value={toggle}
                onChange={handleInMailToggle}
              />
            </div>
          </CardHeader>
          <CardBody>
            {toggle && (
              <Fragment>
                <Card style={{ boxShadow: "none", marginBottom: 0 }}>
                  <InputGroup>
                    <Input
                      placeholder="Subject (required)"
                      className="sequence-builder__input"
                      value={subject !== -1 ? subject : ""}
                      onChange={(e) => handleInMailSubject(e.target.value)}
                      onClick={(e) => handleInMailSubject(e.target.value)}
                      invalid={validator.checkRequiredValidation(subject)}
                    ></Input>
                    <FormFeedback>Subject is Required</FormFeedback>
                  </InputGroup>
                  <InputGroup>
                    <Input
                      type="textarea"
                      id="inmail"
                      className="sequence-builder__input"
                      placeholder="Hi {firstName}, it would be great to compare notes with you and share my expertise. When are you available for a quick chat?"
                      value={msg !== -1 ? msg : ""}
                      onChange={(e) => handleInMailMsg(e.target.value)}
                      onClick={(e) => handleInMailMsg(e.target.value)}
                      invalid={
                        validator.checkRequiredValidation(msg) ||
                        validator.checkSequenceMessageValidation(msg)
                      }
                    ></Input>
                    <FormFeedback>
                      {validator.checkRequiredValidation(msg)
                        ? "Message is Required"
                        : "Your firstName syntax is wrong. You must input {firstName}"}
                    </FormFeedback>
                  </InputGroup>
                </Card>
                {/* <div className="">
                  <Button
                    color="info"
                    onClick={() => insertAtCaret("inmail", "{firstName}")}
                  >
                    {"Insert {firstName}"}
                  </Button>
                </div> */}
                <div className="d-flex flex-column justify-content-start mt-4">
                  <Switch
                    offColor="primary"
                    onColor="primary"
                    value={sendOpenProfiles}
                    onChange={handleSendOpenProfilesToggle}
                  />
                  <p className="text-left">
                    Send to Open profiles only
                    <i
                      className="nc-icon nc-alert-circle-i ml-2 el-inline"
                      id="tooltip-open-profile"
                    ></i>
                  </p>
                  <UncontrolledTooltip
                    placement="right"
                    delay={0}
                    target="tooltip-open-profile"
                  >
                    Open profiles are allowed to received inmails - up to 800
                    free inmails per month. Otherwise, one must use a credit to
                    send inmail.
                  </UncontrolledTooltip>
                </div>
              </Fragment>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export { SequenceCard, InmailCard };
