import { TAG as ACTION_HEADER } from "./types";

export function requestGetTag() {
  return { type: ACTION_HEADER.REQUEST_GET_TAG };
}

export function successGetTag(data) {
  return {
    type: ACTION_HEADER.SUCCESS_GET_TAG,
    data,
  };
}

export function requestDeleteTag(id) {
  return {
    type: ACTION_HEADER.REQUEST_DELETE_TAG,
    id,
  };
}

export function successDeleteTag() {
  return { type: ACTION_HEADER.SUCCESS_CREATE_TAG };
}

export function requestCreateTag(title, color) {
  return { type: ACTION_HEADER.REQUEST_CREATE_TAG, title, color };
}

export function successCreateTag() {
  return { type: ACTION_HEADER.SUCCESS_CREATE_TAG };
}

export function fail(error) {
  return {
    type: ACTION_HEADER.FAIL,
    error,
  };
}

export function resetTag() {
  return { type: ACTION_HEADER.RESET_TAG };
}
