import React, { useState, Fragment } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Nav,
  NavLink,
  NavItem,
  Card,
  CardBody,
  TabPane,
  TabContent,
  Spinner,
} from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";

import AccountSubscription from "../components/accountmanager/AccountSubscription";

import { planActions, userActions } from "../../actions";
import { useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import axios from "axios";

const { requestGetUser } = userActions;
const {
  requestGetPlans,
  requestUnsubscribe,
  requestReactivate,
  requestCancelSubscription,
} = planActions;

const SequenceAddEditForm = ({
  user,
  liaccounts,
  plans,
  loadingPlan,
  loadingAuth,
  ...restProps
}) => {
  const headlines = [
    {
      key: "subscriptions",
      title: "Manage Subscriptions",
    },
    //{
    //key: "accounts",
    //title: "Manage Accounts",
    //},
    // { key: "password", title: "Change Password" },
  ];

  const [type, setType] = useState("subscriptions");
  const [alert, setAlert] = useState(null);
  const [isRequesting, setIsRequesting] = useState(false);

  useEffect(() => {
    if (!plans.length && user) {
      restProps.requestGetPlans();
    }
  }, [plans.length, user, restProps]);

  const reactivateSubscription = (_id) => {
    restProps.requestReactivate(_id);
  };

  const cancelSubscription = (_id) => {
    restProps.requestCancelSubscription(_id);
    setAlert(null);
  };

  const showSubscriptionModal = (_id, isExpired, billDate) => {
    if (isExpired) {
      reactivateSubscription(_id);
    } else
      setAlert(
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={`Your subscription will expire on ${billDate.format("ll")}`}
          onConfirm={() => {
            cancelSubscription(_id);
          }}
          onCancel={() => setAlert(null)}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Confirm Cancelation"
        ></ReactBSAlert>
      );
  };

  const goToStripePortal = () => {
    setIsRequesting(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/stripe/create_customer_portal_session`
      )
      .then((res) => {
        setIsRequesting(false);
        const { data } = res;
        window.open(data.url, "_blank");
      })
      .catch((e) => {
        setIsRequesting(false);
        // console.log("getStripPortal error ---->", e);
      });
  };

  return (
    <>
      <LoadingOverlay
        active={!plans.length || loadingPlan}
        spinner
        text="Loading Account Manager"
      ></LoadingOverlay>
      <div className="content campaignlist">
        {alert ? alert : null}
        <Col md="12" xl="12" className="ml-auto mr-auto">
          <h4 className="el-inline">Account Manager</h4>
          <Card className="campaign-builder-card">
            <CardBody>
              <Row>
                <Col lg="4" md="5" sm="4" xs="6">
                  <div className="nav-tabs-navigation verical-navs mb-2">
                    <div className="nav-tabs-wrapper">
                      <Nav
                        className="flex-column nav-stacked"
                        role="tablist"
                        tabs
                      >
                        <NavItem>
                          <NavLink
                            data-toggle="tab"
                            href="#"
                            role="tab"
                            className="active"
                            style={{ padding: "20px" }}
                            onClick={() => setType("subscriptions")}
                          >
                            Manage Subscriptions
                          </NavLink>
                        </NavItem>
                        {user && Object.keys(user).length > 0 && (
                          <NavItem>
                            <NavLink
                              data-toggle="tab"
                              href="#"
                              role="tab"
                              style={{ padding: "20px" }}
                              onClick={() => {
                                goToStripePortal();
                              }}
                            >
                              {isRequesting ? (
                                <Spinner color="primary" size="sm" />
                              ) : (
                                "Manage Billing"
                              )}
                            </NavLink>
                          </NavItem>
                        )}
                      </Nav>
                    </div>
                  </div>
                </Col>
                <Col
                  lg="8"
                  md="7"
                  sm="8"
                  xs="6"
                  className="d-flex justify-content-between"
                >
                  <TabContent activeTab={type}>
                    {headlines.map(({ key }) => (
                      <TabPane tabId={key} key={key}>
                        <Fragment>
                          {liaccounts.map((liaccount) => (
                            <AccountSubscription
                              key={liaccount._id}
                              liaccount={liaccount}
                              showSubscriptionModal={showSubscriptionModal}
                            />
                          ))}
                        </Fragment>
                      </TabPane>
                    ))}
                  </TabContent>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  liaccounts: state.liaccounts.liaccounts,
  plans: state.plans.plans,
  user: state.authentication.user,
  loadingPlan: state.plans.loading,
  loadingAuth: state.authentication.loading,
});

const mapDispatchToProps = (dispatch) => ({
  requestGetPlans: () => dispatch(requestGetPlans()),
  requestUnsubscribe: () => dispatch(requestUnsubscribe()),
  requestCancelSubscription: (accountId) =>
    dispatch(requestCancelSubscription(accountId)),
  requestReactivate: (accountId) => dispatch(requestReactivate(accountId)),
  requestGetUser: () => dispatch(requestGetUser()),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps)(SequenceAddEditForm)
);
