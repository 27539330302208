import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Form, FormGroup, Label, Button, Spinner } from "reactstrap";
import styled from "styled-components";
import NotificationAlert from "react-notification-alert";

import { liaccountActions } from "../../../actions";
import { requestGetAccounts } from "actions/liaccountActions";
const { requestHeadEditAccount, requestAboutEditAccount } = liaccountActions;

const StyledEditLIInfoForm = styled.div`
  padding: 0 1rem;

  .closeBtn {
    margin-left: 1.25rem;
  }

  .headline {
    height: 5rem;
    overflow: scroll;
  }

  .aboutline {
    height: 10rem;
    overflow: scroll;
  }
`;

const EditLIInfoForm = (props) => {
  const { email, headline, summary, closeModal } = props;
  const [headlineValue, setHeadlineValue] = useState(headline);
  const [headLineExceed, setHeadLineExceed] = useState(false);
  const [aboutlineValue, setAboutLineValue] = useState(summary);
  const [aboutLineExceed, setAboutLineExceed] = useState(false);
  const [saveClicked, setSaveClicked] = useState(false);
  const dispatch = useDispatch();
  const headEditProcess = useSelector((state) => state.liaccounts.headEdit);
  const aboutEditProcess = useSelector((state) => state.liaccounts.aboutEdit);
  const notificationAlert = useRef();

  useEffect(() => {
    if (!headEditProcess.loading && !aboutEditProcess.loading && saveClicked) {
      if (headEditProcess.error !== "" || aboutEditProcess.error !== "")
        notify("err");
      else notify("success");
    }
  }, [saveClicked, headEditProcess, aboutEditProcess]);

  const checkExceed = () => {
    let headExceed = headlineValue.length > 220;
    let aboutExceed = aboutlineValue.length > 2600;
    setHeadLineExceed(headExceed);
    setAboutLineExceed(aboutExceed);
    return headExceed || aboutExceed;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (checkExceed()) return;
    if (headlineValue !== headline) {
      setSaveClicked(true);
      dispatch(
        requestHeadEditAccount(
          email,
          window.btoa(unescape(encodeURIComponent(headlineValue)))
        )
      );
    }
    if (aboutlineValue !== summary) {
      setSaveClicked(true);
      dispatch(
        requestAboutEditAccount(
          email,
          window.btoa(unescape(encodeURIComponent(aboutlineValue)))
        )
      );
    }
  };

  const notify = (state) => {
    let options = {
      place: "tc",
      message:
        state === "success" ? (
          <div>Profile data saved</div>
        ) : (
          <div>Profile data failed</div>
        ),
      type: state === "success" ? "success" : "danger",
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
    setSaveClicked(false);
  };

  return (
    <StyledEditLIInfoForm>
      <NotificationAlert ref={notificationAlert} />
      <h4>Edit Linkedin Profile</h4>
      <Form action="" className="form" method="" onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="headline">Headline</Label>
          <Input
            type="textarea"
            name="headline"
            className="headline"
            value={headlineValue}
            onChange={(e) => {
              setHeadlineValue(e.target.value);
              setHeadLineExceed(false);
            }}
          />
          {headLineExceed && (
            <Label className="text-danger">
              You’ve exceeded the limit by {headlineValue.length - 220}{" "}
              characters.
            </Label>
          )}
        </FormGroup>
        <FormGroup className="mt-4">
          <Label for="aboutline">About (summary)</Label>
          <Input
            type="textarea"
            name="aboutline"
            className="aboutline"
            value={aboutlineValue}
            onChange={(e) => {
              setAboutLineValue(e.target.value);
              setAboutLineExceed(false);
            }}
          />
          {aboutLineExceed && (
            <Label className="text-danger">
              You’ve exceeded the limit by {aboutlineValue.length - 2600}{" "}
              characters.
            </Label>
          )}
        </FormGroup>
        <Button
          className="btn-round mb-3 pull-right closeBtn"
          onClick={() => {
            closeModal();
            dispatch(requestGetAccounts());
          }}
          disabled={headEditProcess.loading || aboutEditProcess.loading}
        >
          Close
        </Button>
        <Button
          className="btn-round mb-3 pull-right"
          color="warning"
          type="submit"
          disabled={
            headlineValue.length < 1 ||
            aboutlineValue.length < 1 ||
            headEditProcess.loading ||
            aboutEditProcess.loading
          }
        >
          {(headEditProcess.loading || aboutEditProcess.loading) && (
            <Spinner
              className="mr-2"
              animation="border"
              variant="secondary"
              size="sm"
            />
          )}
          {headEditProcess.loading || aboutEditProcess.loading
            ? "Saving"
            : "Save"}
        </Button>
      </Form>
    </StyledEditLIInfoForm>
  );
};

export default EditLIInfoForm;
