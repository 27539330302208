import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { Input, Badge, Button } from "reactstrap";
import { tagActions } from "../../../actions";
import { tagColorList } from "../../../utils";
const { requestCreateTag } = tagActions;

const StyledCampaignProspectsAddTagModal = styled.div`
  .tag-manage {
    &__colors {
      display: flex;
      flex-wrap: wrap;

      &__item {
        width: 33.33333%;
        height: 30px;
        padding: 2px;
        cursor: pointer;

        &--active {
          border: 1px solid red;
          border-radius: 10%;
        }
      }
    }
  }
`;
const CreateNewTag = ({ onHide }) => {
  const [tagName, setTagName] = useState("");
  const [tagColor, setTagColor] = useState("");
  const dispatch = useDispatch();
  const tagInputChangeHandler = (e) => {
    e.preventDefault();
    setTagName(e.target.value);
  };
  const create = () => {
    if (tagName && tagColor) {
      dispatch(requestCreateTag(tagName, tagColor));
      setTagName("");
      onHide(false);
    }
  };
  return (
    <StyledCampaignProspectsAddTagModal>
      <h4 style={{ marginTop: 0 }}>
        <Button
          className="btn-icon btn-round"
          color="default"
          onClick={() => onHide()}
        >
          <i className="nc-icon nc-minimal-left text-center visible-on-sidebar-regular" />
        </Button>{" "}
        Create Tags
      </h4>
      <Input
        className="mb-2"
        placeholder="Type to create..."
        type="text"
        value={tagName}
        onChange={tagInputChangeHandler}
      />
      <div className="tag-manage__colors my-3">
        {tagColorList.map((item, index) => (
          <div
            className={`tag-manage__colors__item ${
              tagColor === item && "tag-manage__colors__item--active"
            }`}
            key={index}
            onClick={() => setTagColor(item)}
          >
            <Badge
              className="d-flex align-items-center justify-content-center w-100 h-100"
              color={item}
            ></Badge>
          </div>
        ))}
      </div>
      <Button block onClick={() => create()}>
        Create
      </Button>
    </StyledCampaignProspectsAddTagModal>
  );
};
export default CreateNewTag;
