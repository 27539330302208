import React, { Fragment, useState } from "react";

const ProfileImage = ({
  info,
  bgColor,
  textClass,
  width,
  height,
  isRadius,
  fontSize,
  onUpdate,
}) => {
  const [showPlaceholder, setShowPlaceholder] = useState(false);
  const style = {
    width: width || 50,
    height: height || 50,
    background: "#f7f7f7",
    display: "table-cell",
    verticalAlign: "middle",
    textAlign: "center",
    margin: "0 auto",
    borderRadius: isRadius ? "50%" : "0%",
    lineHeight: "40px",
    fontSize: 14,
    fontWeight: "bold",
    color: "#000",
  };
  if (bgColor) Object.assign(style, { background: bgColor });
  if (width) Object.assign(style, { width });
  if (height) Object.assign(style, { height, lineHeight: height + "px" });
  if (fontSize) Object.assign(style, { fontSize });

  const username = info.name.split(" ");
  let firstChar = "";
  let lastChar = "";
  try {
    firstChar = username[0].charAt(0);
    lastChar = username[1].charAt(0);
  } catch (e) {}
  // @ts-ignore
  const placeHolder = (
    <span style={style} className={"mx-auto " + textClass}>
      {firstChar + lastChar}
    </span>
  );
  const updateProfile = () => {
    onUpdate();
    setShowPlaceholder(true);
  };
  return (
    <Fragment>
      {!showPlaceholder ? (
        <img
          src={info.profileImg ? info.profileImg : "unknown"}
          onError={() => {
            updateProfile();
          }}
          className="photo"
          alt="profile"
        />
      ) : (
        placeHolder
      )}
    </Fragment>
  );
};
export default ProfileImage;
