import { takeLatest, all, put } from "redux-saga/effects";
import { types, tagActions } from "../actions";
import { backendUrl } from "../utils";
import axios from "axios";
import getError from "./getError";

const { TAG: ACTION_HEADER } = types;
const { requestGetTag, successGetTag, fail } = tagActions;

const baseURL = backendUrl + "/tag";

function* requestGetTagSaga() {
  try {
    const resp = yield axios.get(baseURL);
    yield put(successGetTag(resp.data));
  } catch (e) {
    const error = getError(e);
    yield put(fail(error));
  }
}

function* requestCreateTag(action) {
  const { title, color } = action;
  try {
    yield axios.post(baseURL, { title, color });
    yield put(requestGetTag());
  } catch (e) {
    const error = getError(e);
    yield put(fail(error));
  }
}

function* requestDeleteTag(action) {
  const { id } = action;
  try {
    yield axios.delete(`${baseURL}/${id}`);
    yield put(requestGetTag());
  } catch (e) {
    const error = getError(e);
    yield put(fail(error));
  }
}

function* tagSaga() {
  yield all([
    takeLatest(ACTION_HEADER.REQUEST_GET_TAG, requestGetTagSaga),
    takeLatest(ACTION_HEADER.REQUEST_CREATE_TAG, requestCreateTag),
    takeLatest(ACTION_HEADER.REQUEST_DELETE_TAG, requestDeleteTag),
  ]);
}

export default tagSaga;
