// Camp Dash Page

import React, { useRef, useEffect, useState } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { statusCount } from "../../../utils";
import { CSVLink } from "react-csv";
import moment from "moment";
import { campaignActions, sequenceActions } from "../../../actions";
import "react-circular-progressbar/dist/styles.css";

import LoadingOverlay from "react-loading-overlay";

// reactstrap components
import { Row, Col } from "reactstrap";

import CampaignProgressCard from "../../components/campaigns/CampaignProgressCard";
import CampaignConnectionCard from "../../components/campaigns/CampaignConnectionCard";
import CampaignAnalysisCard from "../../components/campaigns/CampaignAnalysisCard";
import CampaignDailyLogCard from "../../components/campaigns/CampaignDailyLogCard";
import CampaignProspectsCard from "../../components/campaigns/CampaignProspectsCard";
import CampaignRecentActivityCard from "../../components/campaigns/CampaignRecentActivityCard";
import { getConnectionData, getRepliedData } from "../../../utils";

const { requestGetTargetsByCampaign, requestGetCampaignById } = campaignActions;
const { requestGetSequences } = sequenceActions;

const CampaignDetailsPage = (props) => {
  const [updatedCampaign, setUpdatedCampaign] = useState(null);

  const history = useHistory();
  const csvRef = useRef(null);
  const {
    match: {
      params: { identifier: account },
    },
    liaccount,
    loading,
    seqLoading,
    sequences,
    campaign,
    requestGetCampaignById,
    requestGetSequences,
  } = props;

  useEffect(() => {
    if ((!campaign && !loading)) {
      requestGetCampaignById(account);
    } else if (campaign) {
      if ((campaign.id !== account)) {
        requestGetCampaignById(account);
      }
    }

    if (sequences.length === 0 && !seqLoading) {
      requestGetSequences(liaccount.email);
    }
  }, []);

  useEffect(() => {
    if (campaign) {
      if (liaccount.connectionData && liaccount.repliedData) {
        setUpdatedCampaign({
          ...campaign,
          connectionData: getConnectionData(
            campaign.data,
            liaccount.connectionData
          ),
          repliedData: getRepliedData(campaign.data, liaccount.repliedData),
        });
      } else {
        setUpdatedCampaign(campaign);
      }

      if (!loading && campaign.data.length === 0) {
        requestGetTargetsByCampaign(campaign._id);
      }
    }
  }, [campaign, liaccount]);

  let newConnections = 0;
  let totalSent = 0;
  let totalReplies = 0;
  let sequence = null;

  if (updatedCampaign && sequences.length) {
    sequence = sequences.filter(
      (seq) => seq._id === updatedCampaign.sequenceId
    )[0];
    newConnections = updatedCampaign.connectionData.length;
    totalReplies = updatedCampaign.repliedData.length;
    totalSent = statusCount(updatedCampaign.data, "INMAIL", 1, "all");
  }

  const manageProspects = () => {
    history.push(
      `/admin/${account}/prospects?campaign=camp-${getCampaignIdentifier(updatedCampaign)}`
    );
  };

  const getCampaignType = (sequenceId) => {
    const sequence = sequences.filter((seq) => seq._id === sequenceId)[0];
    return sequence ? sequence.sequences[0].name : "";
  };

  const downloadCSV = () => {
    csvRef.current.link.click();
  };

  const getExportData = (data) => {
    let exportData = [];
    if (data.length > 0) {
      data.map((el) => {
        exportData.push({ url: el });
      });
    }
    return exportData;
  };
  const getCampaignIdentifier = (campaignObj) => {

    if (campaignObj) {
      console.log(campaignObj.browserKey);

      const allIds = campaignObj.browserKey.split("**");

      return (allIds[2]).replace(/\W/g, "-");
    }
  }

  return (
    <>
      <LoadingOverlay
        active={loading || seqLoading}
        spinner
        text="Loading data..."
      ></LoadingOverlay>
      <div className="content camp-dash">
        <Row>
          <Col lg={12} xs={12}>
            <h3 style={{ marginBottom: 0 }} className="el-inline">
              {updatedCampaign && updatedCampaign.name.split(" -> ")[1]}
            </h3>
            &nbsp;
            <span className="el-inline">
              {sequence && (
                <Link
                  to={`/admin/${account}/sequences/edit-${sequence.identifier}`}
                  style={{ position: "relative" }}
                >
                  <p style={{ margin: 0 }}>{sequence.name}</p>
                </Link>
              )}
            </span>
            {updatedCampaign &&
              (updatedCampaign.type === "SALES_NAVIGATOR" ||
                updatedCampaign.type === "BASIC_QUERY") && (
                <>
                  <div className="tab">
                    <input id="tab-1" type="checkbox" name="tab" />
                    <label className="badge badge-primary" htmlFor="tab-1" style={{marginTop:'4px', marginBottom:'6px'}}>
                      Query URL
                    </label>
                    <div className="tab-content">
                      <p style={{ position: "relative" }}>
                        <a
                          rel="noreferrer noopener"
                          href={updatedCampaign.sourceLinks[0]}
                          target="_blank"
                        >
                          {updatedCampaign.sourceLinks[0]}
                        </a>
                      </p>
                    </div>
                  </div>
                </>
              )}
            {updatedCampaign && updatedCampaign.type === "CUSTOM" && (
              <>
                <CSVLink
                  ref={csvRef}
                  data={getExportData(campaign.sourceLinks)}
                  filename={`${campaign.identifier + moment().format("_YYYY_MM_DD_HH_mm")
                    }.csv`}
                  className="hidden"
                  target="_blank"
                />
                <div>
                  <span
                    className="btn btn-primary btn-sm"
                    style={{ marginBottom: "5px", marginTop: "0px" }}
                    onClick={() => downloadCSV()}
                  >
                    CSV FILE
                  </span>
                </div>
              </>
            )}
          </Col>
        </Row>
        <Row>
          <Col lg={3} xs={12}>
            {updatedCampaign && (
              <CampaignProgressCard
                targetsLength={updatedCampaign.targets.length}
                dataLength={updatedCampaign.data.length}
                totalProspects={updatedCampaign.totalProspects}
                searchDone={updatedCampaign.searchDone}
              />
            )}
            <div className="row">
              <Col lg={6} xs={6}>
                {updatedCampaign &&
                  (getCampaignType(updatedCampaign.sequenceId) !== "INMAIL" ? (
                    <CampaignConnectionCard
                      title="New Leads"
                      number={newConnections}
                      logo="nc-icon nc-air-baloon text-primary"
                      descrIcon="nc-icon nc-check-2"
                      descrText="Requests accepted"
                    />
                  ) : (
                      <CampaignConnectionCard
                        title="Total Sent"
                        number={totalSent}
                        logo="nc-icon nc-air-baloon text-primary"
                        descrIcon="nc-icon nc-check-2"
                        descrText="Requests accepted"
                      />
                    ))}
              </Col>
              <Col lg={6} xs={6}>
                <Link
                  style={{ textDecoration: "none" }}
                  to={{
                    pathname: `/admin/${account}/prospects`,
                    state: {
                      campIdentifier: 'camp-' + getCampaignIdentifier(updatedCampaign),
                      replied: true,
                    },
                  }}
                >
                  <CampaignConnectionCard
                    title="Total Replies"
                    number={totalReplies}
                    logo="nc-icon nc-user-run text-success"
                    descrIcon="fa fa-clock-o"
                    descrText="Updated"
                    haslink={true}
                  />
                </Link>
              </Col>
            </div>
            
          </Col>
          <Col lg="9">
            {liaccount && (
              <CampaignDailyLogCard
                {...{
                  campaign: updatedCampaign,
                  campaignType: sequence ? sequence.sequences[0].name : "",
                  link: `/admin/${account}/prospects`,
                  timezone: liaccount.settings.timezone,
                }}
              />
            )}
          </Col>
        </Row>
        <CampaignAnalysisCard
          {...{
            campaign: updatedCampaign,
            campaignType: sequence ? sequence.sequences[0].name : "",
            timezone: liaccount.settings.timezone,
          }}
        />
        <Row>
          <Col xl={8} lg={8} xs={12}>
            <CampaignProspectsCard
              {...{
                liaccount,
                campaign: updatedCampaign,
                fromIndex: 0,
                count: 4,
                manageProspects,
              }}
            />
          </Col>
          <Col xl={4} lg={4} xs={12}>
            <CampaignRecentActivityCard {...{ campaign: updatedCampaign }} />
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  liaccount: state.liaccounts.liaccount,
  loading: state.campaigns.loading,
  campaign: state.campaigns.campaign,
  sequences: state.sequences.sequences,
  seqLoading: state.sequences.loading,
  error: state.campaigns.error,
  fetched: state.campaigns.fetched,
});

const mapDispatchToProps = (dispatch) => ({
  requestGetTargetsByCampaign: (id) =>
    dispatch(requestGetTargetsByCampaign(id)),
  requestGetCampaignById: (id) => dispatch(requestGetCampaignById(id)),
  requestGetSequences: (email) => dispatch(requestGetSequences(email)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps)(CampaignDetailsPage)
);
