import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import AuthLayout from "./layouts/Auth";
import AdminLayout from "./layouts/Admin";
import PAdminLayout from "./layouts/PPadmin";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const App = (props) => {
  useEffect(() => {
    const checkSignIn = () => {
      const { token, history, location } = props;
      const mainRouter = location.pathname.split("/")[1];
      if (token) {
        if (!props.user) {
          props.requestGetUser();
        }
        if (mainRouter === "auth") {
          if (props.user && props.user.role === "admin") history.push("/ppadmin");
          else history.push("/admin");
        }
      } else if (mainRouter === "admin" || mainRouter === "ppadmin") {
        history.push("/auth/login");
      }
    };

    checkSignIn();
  });

  return (
    <Elements stripe={stripePromise}>
      <Switch>
        <Route
          path="/ppadmin"
          render={(props) => <PAdminLayout {...props} />}
        />
        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
        <Redirect to="/admin" />
      </Switch>
    </Elements>
  );
};

export default App;
