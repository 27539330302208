import React from "react";
import { Card, CardHeader, CardBody, CardFooter, CardTitle } from "reactstrap";
import { Doughnut } from "react-chartjs-2";
import { chartExample5 } from "variables/charts.jsx";
import { progress } from "../../../utils";

const CampaignProgressCard = (props) => {
  let { targetsLength, dataLength, totalProspects, searchDone } = props;
  let doneSeqs = searchDone ? targetsLength : dataLength;
  if (searchDone && targetsLength < totalProspects) {
    doneSeqs = totalProspects;
  }
  let totalSeqs = dataLength;
  if (dataLength > totalProspects) {
    totalSeqs = dataLength;
  } else {
    totalSeqs = totalProspects;
  }
  // if (targetsLength > totalProspects) {
  //   totalSeqs = targetsLength;
  // } else {
  //   totalSeqs = totalProspects;
  // }

  const calculatedProgress =
    progress(targetsLength, dataLength, totalProspects, searchDone) + "%";

  return (
    <Card className="camp-dash-progress">
      <CardHeader>
        <CardTitle>Progress</CardTitle>
        <p className="card-category">Total sequences started</p>
      </CardHeader>
      <CardBody>
        <Doughnut
          // data={chartExample5.data}
          data={{
            labels: [1, 2],
            datasets: [
              {
                ...chartExample5.data.datasets[0],
                data: [
                  doneSeqs,
                  totalSeqs - doneSeqs >= 0 ? totalSeqs - doneSeqs : 0,
                ],
              },
            ],
          }}
          options={{
            ...chartExample5.options,
            elements: {
              center: {
                ...chartExample5.options.elements.center,
                text: calculatedProgress,
              },
            },
          }}
          className="ct-chart ct-perfect-fourth"
          height={300}
          width={456}
          cutoutPercentage={10}
        />
      </CardBody>
      <CardFooter>
        <div className="legend">Completed ({`${doneSeqs}/${totalSeqs}`})</div>
        {/*<hr />
        <div className="stats">
          <i className="fa fa-clock-o" />
          Updated 1 hour ago
        </div>*/}
      </CardFooter>
    </Card>
  );
};

export default CampaignProgressCard;
