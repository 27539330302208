import { types } from "../actions";

const { NOTES: ACTION_HEADER } = types;

const initialState = {
  notes: [],
  loading: false,
  error: "",
  fetched: false,
  successMessage: "",
};

function integrationReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_HEADER.REQUEST_NOTES: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case ACTION_HEADER.GET_NOTES_SUCCESS: {
      return { ...state, loading: false, notes: action.notes };
    }
    case ACTION_HEADER.GET_NOTES_ERROR: {
      return {
        ...state,
        loading: false,
        error: "Getting Error while fetching notes",
        notes: [],
      };
    }
    case ACTION_HEADER.REQUEST_NOTES_UPDATE: {
      return {
        ...state,
        loading: false,
        error: "",
        notes: {
          [action.target_id]: action.notes,
        },
      };
    }
    case ACTION_HEADER.NOTES_UPDATE_SUCCESS: {
      return { ...state, loading: false };
    }
    case ACTION_HEADER.NOTES_UPDATE_ERROR: {
      return {
        ...state,
        loading: false,
        error: "Getting Error while updating notes",
      };
    }
    default: {
      return state;
    }
  }
}

export default integrationReducer;
