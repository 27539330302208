import React from "react";
import { useEffect, useState, useRef } from "react";
import NotificationAlert from "react-notification-alert";
import { validURL } from "../../../utils";

import {
  Button,
  Col,
  Input,
  FormFeedback,
  InputGroup,
  Container,
  Label,
  Form,
  Row,
} from "reactstrap";
import { compose } from "recompose";
import { connect } from "react-redux";
import { integrationActions } from "../../../actions";
import LoadingOverlay from "react-loading-overlay";
import { SmoothImageLoad } from "views/components/SmoothImageLoad";
const { requestAddIntegration, hideSuccessMessage } = integrationActions;

const AddGlobalintegration = (props) => {
  const [loading, setLoading] = useState(true);
  const [callbackUrl, setCallbackUrl] = useState("");
  const [action, setAction] = useState(new Map());
  const [actionBody, setActionBody] = useState([]);
  const [selectLiAccounts, setSelectLiAccounts] = useState(new Map());
  const [error, setError] = useState({});
  const notificationAlert = useRef();
  const account = props.account;

  const allActions = [
    {
      label: "Get updates for new connections",
      value: "connect",
      isChecked: false,
      textStyle: "",
      postBody: ["targetName", "targetLink", "date"],
    },
    {
      label: "Get updates for new replies",
      value: "replies",
      isChecked: false,
      textStyle: "",
      postBody: ["targetName", "targetLink", "date"],
    },
  ];

  useEffect(() => {
    //   idea: get all
    if (props.liaccounts.length > 0) {
      setLoading(false);
    }
  }, [props.liaccounts]);

  const submitForm = (e) => {
    e.preventDefault();
    let actionArray = [];
    action.forEach((item, key) => {
      if (item) {
        actionArray.push(key);
      }
    });
    let selectLiAccountsArray = [];
    selectLiAccounts.forEach((item, key) => {
      if (item) {
        selectLiAccountsArray.push(key);
      }
    });
    if (callbackUrl.trim() === "") {
      return setError({
        ...error,
        callbackUrl: true,
        callbackUrlError: false,
      });
    }
    if (validURL(callbackUrl) === false) {
      return setError({
        ...error,
        callbackUrl: true,
        callbackUrlError: true,
      });
    }
    if (actionArray.length === 0) {
      return setError({
        ...error,
        action: true,
      });
    }
    if (selectLiAccountsArray.length === 0) {
      return setError({
        ...error,
        selectLiAccounts: true,
      });
    }
    if (selectLiAccountsArray.length === 0) {
      return setError({
        ...error,
        selectLiAccounts: true,
      });
    }

    props.requestAddIntegrationDesp(
      {
        callbackUrl,
        actions: actionArray,
        accounts: selectLiAccountsArray,
        liEmail: props.liaccounts[0].email,
        type: "account",
        postBody: actionBody,
        userId: props.liaccounts[0].userId,
      },
      account
    );
    setLoading(true);
  };
  const testActionZapier = () => {
    if (callbackUrl && actionBody) {
      let actionBodyJson = {};
      for (let y = 0; y < actionBody.length; y++) {
        const element = actionBody[y];
        actionBodyJson[element] = "a";
      }
      try {
        var xhr = new XMLHttpRequest();
        xhr.open("POST", callbackUrl);
        xhr.send(
          JSON.stringify({
            firstName: "John",
            lastName: "Doe",
            targetLink: "https://www.linkedin.com/in/andy-ayer-a2313516a/",
            address: "123 Cool Lane. Chicago, IL",
            birthday: "12/2/84",
            connected_date: "November 6, 2020",
            phone: "7731231231",
            skype: "chillyboy69",
            website: "coolstuff.com",
            wechat: "chillyboy69",
            email: "johnDoe@gmail.com",
            lastMessage: "Hello John Doe - let's get zoomin soon.",
            repliedTime: "2020-10-21 17:45",
            company: "Acme Company",
            jobTitle: "Prospect Job Title",
            avatarURL: "https://socialprospector.io/assets/img/sp-logo.png",
          })
        );
        xhr.onreadystatechange = (e) => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            let options = {
              place: "tc",
              message: (
                <div>
                  <div>
                    Success! Please hit save and then complete the process on
                    Zapier.
                  </div>
                </div>
              ),
              type: "success",
              icon: "now-ui-icons ui-1_bell-53",
              autoDismiss: 7,
            };
            notificationAlert.current.notificationAlert(options);
          }
          if (xhr.readyState === 4 && xhr.status !== 200) {
            let options = {
              place: "tc",
              message: (
                <div>
                  <div>Callback url failed.</div>
                </div>
              ),
              type: "danger",
              icon: "now-ui-icons ui-1_bell-53",
              autoDismiss: 7,
            };
            notificationAlert.current.notificationAlert(options);
          }
        };
      } catch (e) {
        let options = {
          place: "tc",
          message: (
            <div>
              <div>Callback url failed.</div>
            </div>
          ),
          type: "danger",
          icon: "now-ui-icons ui-1_bell-53",
          autoDismiss: 7,
        };
        notificationAlert.current.notificationAlert(options);
      }
    }
  };

  const cancelIntegration = () => {
    props.history.push({
      pathname: `/admin/${account}/integration`,
      state: { state: "global" },
    });
  };

  return (
    <>
      <LoadingOverlay
        active={loading}
        spinner
        text="Loading Integration"
      ></LoadingOverlay>
      <NotificationAlert ref={notificationAlert} />
      <Form className="form" method="" onSubmit={submitForm}>
        <Row className="Integrationlist">
          <Col>
            <div className="ml-auto mr-auto col-12 col-xl-9">
              <h3>Add Global Zapier Webhooks</h3>
              <p>
                Export data to your favorite CRM by integrating with Zapier.
              </p>
              {/* <p>
                <a
                  href="https://www.youtube.com/watch?v=BO2La5Qura4"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="nc-icon nc-bulb-63" /> Watch the step-by-step
                  tutorial here.
                </a>
              </p> */}
              <div>
                {error.selectLiAccounts && (
                  <div className="alert alert-danger">
                    Please select account
                  </div>
                )}
                {error.action && (
                  <div className="alert alert-danger">
                    Please select any action
                  </div>
                )}
                <div className="card">
                  <div className="card-body">
                    <InputGroup className="Integration-name-field">
                      <Input
                        className="fade-in"
                        onChange={(e) => {
                          if (e.target.value !== "") {
                            setCallbackUrl(e.target.value);
                            setError({
                              ...error,
                              callbackUrl: false,
                            });
                          }
                        }}
                        invalid={error.callbackUrl}
                        placeholder="Enter Callback URL here"
                      ></Input>
                      <FormFeedback>
                        {error.callbackUrlError ? (
                          <span>Callback URL is invalid</span>
                        ) : (
                          <span>Callback URL is required</span>
                        )}
                      </FormFeedback>
                    </InputGroup>
                    <InputGroup className="Integration-name-field">
                      <Label>
                        <h5> Type of updates to send:</h5>
                      </Label>
                      <Container>
                        {allActions.map((item, index) => {
                          return (
                            <Col key={index} className={"form-check"}>
                              <InputGroup>
                                <Label
                                  className={"form-check-label"}
                                  style={{ paddingTop: 0 }}
                                  htmlFor={item.value}
                                >
                                  {item.label}
                                  <Input
                                    type="checkbox"
                                    name="action"
                                    id={item.value}
                                    className={"form-check-input"}
                                    value={item.value}
                                    onChange={(e) => {
                                      setAction(
                                        action.set(
                                          e.target.value,
                                          e.target.checked
                                        )
                                      );
                                      setError({
                                        ...error,
                                        action: false,
                                      });
                                      setActionBody(item.postBody);
                                    }}
                                  />
                                  <span
                                    style={{ top: "30px" }}
                                    className="form-check-sign"
                                  >
                                    <span className="check"></span>
                                  </span>
                                </Label>
                              </InputGroup>
                            </Col>
                          );
                        })}
                      </Container>
                    </InputGroup>
                    <InputGroup className="Integration-name-field">
                      <Label>
                        <h5> Select Users (applies to all campaigns): </h5>
                      </Label>
                      <Container>
                        {props.liaccounts.map((item, index) => {
                          return (
                            <Col key={index} className={"form-check"}>
                              <InputGroup>
                                <Label
                                  className={"form-check-label"}
                                  style={{ paddingTop: 0 }}
                                  htmlFor={item.name}
                                >
                                  <SmoothImageLoad
                                    src={item.imageurl}
                                    alt={item.name}
                                  />
                                  &nbsp;{item.name} &nbsp;
                                  <Input
                                    type="checkbox"
                                    name="action"
                                    id={item.name}
                                    className={"form-check-input"}
                                    value={item._id}
                                    onChange={(e) => {
                                      setError({
                                        ...error,
                                        action: false,
                                      });
                                      setSelectLiAccounts(
                                        selectLiAccounts.set(
                                          item.email,
                                          e.target.checked
                                        )
                                      );
                                    }}
                                  />
                                  <span
                                    style={{ top: "30px" }}
                                    className="form-check-sign"
                                  >
                                    <span className="check"></span>
                                  </span>
                                </Label>
                              </InputGroup>
                            </Col>
                          );
                        })}
                      </Container>
                    </InputGroup>
                    <InputGroup>
                      <div>
                        <Button
                          type="button"
                          className="btn-warning"
                          onClick={testActionZapier}
                        >
                          Test Zapier
                        </Button>
                        <Button type="submit" color="primary">
                          Save
                        </Button>
                        <Button onClick={cancelIntegration} type="button">
                          Cancel
                        </Button>
                      </div>
                    </InputGroup>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
};

const mapStateToProps = (state) => ({
  liaccounts: state.liaccounts.liaccounts,
  sequences: state.sequences.sequences,
  campaigns: state.campaigns.campaigns,
  loading: state.sequences.loading,
  successMessage: state.sequences.successMessage,
});

const mapDispatchToProps = (dispatch) => ({
  requestHideSuccess: () => dispatch(hideSuccessMessage()),
  requestAddIntegrationDesp: (data, account) =>
    dispatch(requestAddIntegration(data, account)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps)(AddGlobalintegration)
);
