import { takeLatest, all, put } from "redux-saga/effects";
import { types, prospectActions } from "../actions";
import { backendUrl } from "../utils";
import axios from "axios";
import { push } from "connected-react-router";
import { removeToken } from "actions/userActions";

const { PROSPECT } = types;
const {
  successGetBlackLinks,
  successDeleteBlackLink,
  successAddBlackLinks,
  fail,
} = prospectActions;

const baseURL = backendUrl + "/blacklist";

function* requestAddBlackLinks(action) {
  const { blackLinks, account } = action;

  try {
    const data = {
      liEmail: blackLinks.liEmail,
      blockLink: blackLinks.links,
    };

    const resp = yield axios.post(baseURL, data);

    yield put(
      successAddBlackLinks({
        ...resp.data,
      })
    );

    yield put(push(`/admin/${account}/blacklist`));
  } catch (e) {
    let error = "Network Error";
    // console.log(e, e.response);
    if (e.response) {
      if (!e.response.data.errors) {
        error = e.response.data;
        if (error === "Unauthorized") {
          yield put(removeToken());
        }
      } else if (typeof e.response.data.errors.msg === "object") {
        error = e.response.data.errors.msg[0].msg;
      } else {
        error = e.response.data.errors.msg;
      }
    }
    yield put(fail(error));
  }
}

// delete blackLinks request saga
function* requestDeleteBlackLink(action) {
  const { _id, account } = action;

  try {
    yield axios.delete(`${baseURL}/${_id}`);

    yield put(successDeleteBlackLink(_id));
    yield put(push(`/admin/${account}/blacklist`));
  } catch (e) {
    let error = "Network Error";
    if (e.response) {
      if (!e.response.data.errors) {
        error = e.response.data;
        if (error === "Unauthorized") {
          yield put(removeToken());
        }
      } else if (typeof e.response.data.errors.msg === "object") {
        error = e.response.data.errors.msg[0].msg;
      } else {
        error = e.response.data.errors.msg;
      }
    }
    yield put(fail(error));
  }
}

// get blackLinks request saga
function* requestGetBlackLinks(action) {
  try {
    const { liEmail } = action;
    const resp = yield axios.get(`${baseURL}?liEmail=${liEmail}`);
    const blackLinks = resp.data.docs.map((blackLink) => ({
      ...blackLink,
      identifier: "black-link-" + blackLink._id,
    }));

    yield put(successGetBlackLinks(blackLinks));
  } catch (e) {
    let error = "Network Error";

    if (e.response) {
      if (!e.response.data.errors) {
        error = e.response.data;
        if (error === "Unauthorized") {
          yield put(removeToken());
        }
      } else if (typeof e.response.data.errors.msg === "object") {
        error = e.response.data.errors.msg[0].msg;
      } else {
        error = e.response.data.errors.msg;
      }
    }
    yield put(fail(error));
  }
}

function* prospectSaga() {
  yield all([
    takeLatest(PROSPECT.BLACK_LINK_REQUEST_ADD, requestAddBlackLinks),
    takeLatest(PROSPECT.BLACK_LINK_REQUEST_GET, requestGetBlackLinks),
    takeLatest(PROSPECT.BLACK_LINK_REQUEST_DELETE, requestDeleteBlackLink),
  ]);
}

export default prospectSaga;
