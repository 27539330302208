import { all, fork } from "redux-saga/effects";
import userSaga from "./userSaga";
import liaccountSaga from "./liaccountSaga";
import sequenceSaga from "./sequenceSaga";
import prospectSaga from "./prospectSaga";
import campaignSaga from "./campaignSaga";
import planSaga from "./planSaga";
import adminSaga from "./adminSaga";
import messageSaga from "./messageSaga";
import tagSaga from "./tagSaga";
import integrationSaga from "./integrationSaga";
import notesSaga from "./notesSaga";
import replayTemplateSaga from "./replayTemplateSaga";

export default function* rootSaga() {
  yield all([
    fork(userSaga),
    fork(liaccountSaga),
    fork(sequenceSaga),
    fork(prospectSaga),
    fork(campaignSaga),
    fork(planSaga),
    fork(adminSaga),
    fork(messageSaga),
    fork(tagSaga),
    fork(integrationSaga),
    fork(notesSaga),
    fork(replayTemplateSaga),
  ]);
}
