import React, { useState, useEffect } from "react";

const CampaignTags = (props) => {
  const { selectedCampaign, removeFromSelected, color } = props;
  const [array, setArray] = useState([]);
  useEffect(() => {
    setArray(selectedCampaign);
  }, [selectedCampaign]);
  return (
    <React.Fragment>
      <span className="my-2" key={selectedCampaign.length}>
        {array.map((campaign, index) => (
          <span
            key={index}
            className={`btn btn-sm mr-2 btn-outline-${color} my-1`}
            onClick={() => removeFromSelected(campaign)}
          >
            {campaign}&nbsp;
            <i className="nc-icon nc-simple-remove" />
          </span>
        ))}
      </span>
    </React.Fragment>
  );
};

export default CampaignTags;
