import React, { useMemo, Fragment } from "react";
import styled from "styled-components";
import moment from "moment";

const StyledAccountSubscription = styled.div`
  display: flex;

  .account-subscription {
    &__photo {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }

    &__details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &__btn {
        cursor: pointer;
      }
    }
  }
`;

const AccountSubscription = (props) => {
  const { liaccount, showSubscriptionModal } = props;
  const billDate = liaccount.billDate
    ? moment(liaccount.billDate * 1000)
    : moment();
  const currentPlanTxt = liaccount.isBasicPlan
    ? "$29/mth until canceled ~"
    : "$49/mth until canceled ~";
  const isExpired = useMemo(() => {
    const currentDate = moment();
    if (billDate > currentDate) {
      return false;
    } else return true;
  }, [billDate]);
  console.log(liaccount.isSubscribed);
  return (
    <Fragment>
      {liaccount && (
        <StyledAccountSubscription className="mb-3">
          {liaccount.profileImg !== "UNKNOWN" ? (
            <img
              src={liaccount.profileImg}
              className="account-subscription__photo"
              alt="profile"
            />
          ) : (
            <img
              className="account-subscription__photo"
              alt="missing"
              src="https://perfectprospect.io/assets/img/placeholder.jpg"
            />
          )}
          <div className="account-subscription__details ml-4">
            <p className="m-0">{liaccount.name}</p>
            {!liaccount.isSubscribed ? (
              <span className="text-danger"> Subscription is canceled</span>
            ) : (
              ""
            )}
            {isExpired ? (
              <span className="text-danger">
                Expired at {billDate.format("ll")}
              </span>
            ) : liaccount.isSubscribed ? (
              currentPlanTxt
            ) : (
              ""
            )}{" "}
            {!isExpired ? "Active until: " + billDate.format("ll") : ""}
            {!liaccount.isSubscribed ? (
              <p
                onClick={() =>
                  showSubscriptionModal(liaccount._id, true, billDate)
                }
                className="account-subscription__details__btn text-primary m-0"
              >
                {" "}
                Reactivate Subscription{" "}
              </p>
            ) : (
              <p
                onClick={() =>
                  showSubscriptionModal(liaccount._id, isExpired, billDate)
                }
                className="account-subscription__details__btn text-danger m-0"
              >
                {" "}
                Cancel Subscription
              </p>
            )}
          </div>
        </StyledAccountSubscription>
      )}
    </Fragment>
  );
};

export default AccountSubscription;
