import React, { useEffect, useState } from "react";
import { Row, Form, Input, Label, Button, FormGroup, FormFeedback } from "reactstrap";

const AddReplayTemplates = ({
    backtoList,
    createReplayTemplateHook,
    updateReplayTemplatesHook,
    editForm
}) => {
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState({
        name: false,
        message: false,
    });
    useEffect(() => {
        if (editForm) {
            setName(editForm.name);
            setMessage(editForm.message);
            setError({
                message: false,
                name: false,
            });
        }
    }, []);
    const submitForm = (e) => {
        e.preventDefault();
        let nameError = false;
        let messageError = false;
        if (name.trim() === "") {
            nameError = true;
        }
        if (message.trim() === "") {
            messageError = true;
        }
        setError({
            message: messageError,
            name: nameError,
        });
        if (nameError === false && messageError == false) {
            // submit form 
            if (editForm) {
                updateReplayTemplatesHook({
                    name,
                    message,
                    _id: editForm._id
                });
            } else {
                createReplayTemplateHook({
                    name,
                    message
                });
            }
        }
    }

    return (
        <Form onSubmit={submitForm} >
            <Row>
                <FormGroup className="replay-name-field" style={{ width: '100%' }} >
                    <Label for="name">Name
                </Label>
                    <Input
                        id="name"
                        className="fade-in"
                        onChange={(e) => {
                            setName(e.target.value);
                            if (String(e.target.value).trim() !== '') {
                                setError({
                                    ...error,
                                    name: false,
                                })
                            }
                        }}
                        value={name}
                        invalid={error.name}
                        placeholder="Name"
                    ></Input>
                    {error.name && (
                        <FormFeedback>
                            <span>Name is required</span>
                        </FormFeedback>
                    )}
                </FormGroup>
            </Row>
            <Row>
                <FormGroup className="replay-message-field" style={{ width: '100%' }}>
                    <Label for="messgae">Message
                    </Label>
                    <Input
                        type="textarea"
                        className="fade-in"
                        style={{
                            width: '100%'
                        }}
                        value={message}
                        onChange={(e) => {
                            setMessage(String(e.target.value));
                            if (String(e.target.value).trim() !== '') {
                                setError({
                                    ...error,
                                    message: false,
                                })
                            }
                        }}
                        id="messgae"
                        invalid={error.message}
                        placeholder="Message"
                    ></Input>
                    {error.message && (
                        <FormFeedback>
                            <span>Message is required</span>
                        </FormFeedback>
                    )}
                </FormGroup>
            </Row>
            <Row>
                <FormGroup>
                    <Button type="submit" color="primary">
                        Save
                    </Button>
                    <Button type="button" onClick={backtoList} >
                        Cancel
                    </Button>
                </FormGroup>
            </Row>
        </Form>
    );
};

export default AddReplayTemplates;