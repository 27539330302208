import { types } from "../actions";

const { ERROR: ACTION_HEADER } = types;

const initialState = {
  sleepMode: false,
  errorMsg: "",
};

function errorReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_HEADER.SLEEP_MODE: {
      return { ...state, sleepMode: true };
    }
    case ACTION_HEADER.RESET_ERROR: {
      return { ...state, sleepMode: false };
    }
    default: {
      return state;
    }
  }
}

export default errorReducer;
