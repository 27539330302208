import { ERROR as ACTION_HEADER } from "./types";

export function sleepModeException() {
  return {
    type: ACTION_HEADER.SLEEP_MODE,
  };
}

export function resetError() {
  return {
    type: ACTION_HEADER.RESET_ERROR,
  };
}
