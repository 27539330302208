import { MESSAGE as ACTION_HEADER } from "./types";

export function requestMessageSend(
  liEmail,
  msg,
  profileUrl,
  name,
  isSales,
  useCred,
  isInMail,
  subject
) {
  return {
    type: ACTION_HEADER.REQUEST_MESSAGE_SEND,
    liEmail,
    msg,
    profileUrl,
    name,
    isSales,
    useCred,
    isInMail,
    subject,
  };
}

export function successMessageSend(data) {
  return { type: ACTION_HEADER.SUCCESS_MESSAGE_SEND, data };
}

export function failMessageSend(message) {
  return { type: ACTION_HEADER.FAIL_MESSAGE_SEND, message };
}

export function readMessage(message_id, liaccount_id) {
  return { type: ACTION_HEADER.READ_MESSAGE, message_id, liaccount_id };
}

export function unreadMessage(message_id) {
  return { type: ACTION_HEADER.UNREAD_MESSAGE, message_id };
}

export function clearMessage() {
  return { type: ACTION_HEADER.CLEAR_MESSAGE };
}

export function successReadStatus(data) {
  return { type: ACTION_HEADER.SUCCESS_READ_MESSAGE, data };
}

export function successUnReadStatus(data) {
  return { type: ACTION_HEADER.SUCCESS_UNREAD_MESSAGE, data };
}
