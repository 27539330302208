import React from "react";
import nl2br from "react-newline-to-break";
import UserInfo from "./UserInfo";

const Message = (props) => {
  const {
    userName,
    userImgUrl,
    msgTimeStamp,
    msgText,
    sender,
    oppositeImgUrl,
    isAccepted,
  } = props;
  const msgCtnClass = `msg-container ${
    sender === "You" ||
    sender.toLowerCase().trim() === userName.toLowerCase().trim()
      ? ""
      : "received-container"
  }`;
  const msgTextClass = `msg-text ${
    sender === "You" ||
    sender.toLowerCase().trim() === userName.toLowerCase().trim()
      ? "other"
      : "self"
  }`;
  const msgTopBarWrapper = `msg-topBar ${
    sender === "You" ||
    sender.toLowerCase().trim() === userName.toLowerCase().trim()
      ? ""
      : "end"
  }`;

  const renderGifMessage = (msgText) => {
    return <img className="intercom-emoji-picker-gif" src={msgText} />;
  };

  return (
    <div className={msgCtnClass}>
      <div className={msgTopBarWrapper}>
        <div className="left-block">
          <UserInfo
            imageUrl={userImgUrl}
            userName={userName}
            imageAlt="user-avatar"
          />
        </div>
        {msgText === "" && !isAccepted && (
          <div className={msgTextClass}>
            <p>
              <i className="fa fa-ban danger" />
              &nbsp;
              <em>Declined your InMail at {msgTimeStamp}</em>
            </p>
          </div>
        )}
        {msgText === "" && isAccepted && (
          <div className={msgTextClass}>Accepted your InMail</div>
        )}
        {msgText !== "" && (
          <div className={msgTextClass}>
            <p className="msg">
              {msgText.indexOf(".giphy.com") > -1
                ? renderGifMessage(msgText)
                : nl2br(msgText)}
            </p>
            <div className="msg-timestamp">{msgTimeStamp}</div>
          </div>
        )}
        <div className="right-block">
          <UserInfo
            imageUrl={oppositeImgUrl}
            userName={userName}
            imageAlt="user-avatar"
          />
        </div>
        <div className="self"></div>
      </div>
    </div>
  );
};

export default Message;
