import { takeLatest, all, put } from "redux-saga/effects";
import { types, replayTemplatesActions } from "../actions";
import { backendUrl } from "../utils";
import getError from "./getError";
import axios from "axios";

const { REPLAYS_TEMPLATES } = types;
const {
  getSuccessReplayTemplates,
  getErrorReplayTemplates,
  createSuccessReplayTemplates,
  createErrorReplayTemplates,
  deleteSuccessReplayTemplates,
  updateSuccessReplayTemplates,
  updateErrorReplayTemplates,
} = replayTemplatesActions;

const replayTemplateUrl = backendUrl + "/replytemplate";

function* requestReplayTemplateGet(action) {
  try {
    const replayTemplates = yield axios.get(replayTemplateUrl);
    yield put(getSuccessReplayTemplates(replayTemplates.data));
  } catch (e) {
    const error = getError(e);
    console.log(error);
    yield put(getErrorReplayTemplates(error));
  }
}

function* requestReplayTemplateUpdate(actions) {
  try {
    yield axios.patch(replayTemplateUrl, {
      ...actions.replayTemplate,
      id: actions.replayTemplate._id,
    });
    yield put(updateSuccessReplayTemplates(actions.replayTemplate));
  } catch (e) {
    const error = getError(e);
    yield put(updateErrorReplayTemplates(error));
  }
}

function* requestReplayTemplateCreate(actions) {
  try {
    const replayTemplateResp = yield axios.post(
      replayTemplateUrl,
      actions.replayTemplate
    );
    yield put(createSuccessReplayTemplates(replayTemplateResp.data));
  } catch (e) {
    const error = getError(e);
    console.log(error);
    yield put(createErrorReplayTemplates(error));
  }
}

function* requestReplayTemplateDelete(actions) {
  try {
    const replayTemplateResp = yield axios.delete(
      replayTemplateUrl + "?id=" + actions.replayTemplateId
    );
    yield put(deleteSuccessReplayTemplates(actions.replayTemplateId));
  } catch (e) {
    const error = getError(e);
    console.log(error);
    yield put(createErrorReplayTemplates(error));
  }
}

function* replayTemplateSaga() {
  yield all([
    takeLatest(
      REPLAYS_TEMPLATES.REPLAYS_TEMPLATES_GET_REQUEST,
      requestReplayTemplateGet
    ),
    takeLatest(
      REPLAYS_TEMPLATES.REPLAYS_TEMPLATES_UPDATE_REQUEST,
      requestReplayTemplateUpdate
    ),
    takeLatest(
      REPLAYS_TEMPLATES.REPLAYS_TEMPLATES_CREATE_REQUEST,
      requestReplayTemplateCreate
    ),
    takeLatest(
      REPLAYS_TEMPLATES.REPLAYS_TEMPLATES_DELETE_REQUEST,
      requestReplayTemplateDelete
    ),
  ]);
}

export default replayTemplateSaga;
