// Campaigns Page

import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";

import ReactTable from "react-table";

// reactstrap components
import {
  UncontrolledTooltip,
  Button,
  Card,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import LoadingOverlay from "react-loading-overlay";
import ReactBSAlert from "react-bootstrap-sweetalert";
import BlackListAddForm from "./BlackListAddForm";
import { prospectActions } from "../../../../actions";

const {
  requestDeleteBlackLink,
  requestAddBlackLinks,
  requestGetBlackLinks,
} = prospectActions;

class BlackListManager extends React.Component {
  state = {
    alert: null,
    liaccount: this.props.liaccount,
  };

  componentDidMount() {
    const { liaccount } = this.props;
    if (this.props.blackLinks.length === 0 && !this.props.loading) {
      this.props.requestGetBlackLinks(liaccount.email);
    }
  }

  deleteBlackLink = (identifier) => {
    const { account } = this.props.match.params;
    const { blackLinks } = this.props;
    const blackLink = blackLinks.filter(
      (blackLink) => blackLink.identifier === identifier
    )[0];
    this.props.requestDeleteBlackLink(blackLink._id, account);
    this.hideAlert();
  };

  addBlackLink = (links) => {
    const { account } = this.props.match.params;
    const liEmail = this.state.liaccount.email;

    const data = {
      links: links,
      liEmail: liEmail,
    };

    this.props.requestAddBlackLinks(data, account);
  };

  warningWithConfirmMessage = (e, identifier) => {
    e.preventDefault();
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure?"
          onConfirm={() => this.deleteBlackLink(identifier)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          showCancel
        >
          You will not be able to recover this blackLink!
        </ReactBSAlert>
      ),
    });
  };
  hideAlert = () => {
    this.setState({ alert: null });
  };

  render() {
    const { blackLinks, loading } = this.props;

    const blackLinksData = blackLinks.map((blackLink) => ({
      link: (
        <a href={blackLink.blockLink}>
          <span>{blackLink.blockLink}</span>
        </a>
      ),
      actions: (
        <div className="">
          <Button
            color="danger"
            size="md"
            className="btn-icon btn-default btn-link remove"
            onClick={(e) =>
              this.warningWithConfirmMessage(e, blackLink.identifier)
            }
          >
            <i className="fa fa-times" />
          </Button>{" "}
        </div>
      ),
    }));
    return (
      <>
        <LoadingOverlay
          active={loading}
          spinner
          text="Loading BlackLinks"
        ></LoadingOverlay>
        <div className="content blackLink-list">
          {this.state.alert}
          <Row>
            <Col md="12">
              <h4 className="el-inline">Blacklist &nbsp;</h4>

              <i className="nc-icon nc-alert-circle-i" id={`blacklist-info`} />
              <UncontrolledTooltip
                placement="bottom"
                delay={0}
                target={`blacklist-info`}
              >
                Add here Sales Navigator profile URLs of prospects you{" "}
                <strong>never</strong> want to contact.
              </UncontrolledTooltip>

              <BlackListAddForm addBlackLink={this.addBlackLink} />
              <Card>
                <CardBody>
                  <ReactTable
                    data={blackLinksData}
                    resizable={true}
                    columns={[
                      {
                        Header: "Profile URL",
                        accessor: "link",
                        sortable: false,
                        filterable: false,
                        width: 350,
                      },
                      {
                        Header: "",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    defaultPageSize={10}
                    showPaginationTop={false}
                    className=" -highlight blackLink-manager-table"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  liaccount: state.liaccounts.liaccount,
  blackLinks: state.prospects.blackLinks,
  loading: state.prospects.loading,
  error: state.prospects.error,
});

const mapDispatchToProps = (dispatch) => ({
  requestDeleteBlackLink: (_id, account) =>
    dispatch(requestDeleteBlackLink(_id, account)),
  requestAddBlackLinks: (links, account) =>
    dispatch(requestAddBlackLinks(links, account)),
  requestGetBlackLinks: (email) => dispatch(requestGetBlackLinks(email)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps)(BlackListManager)
);
