import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Input, Badge, Button, Spinner } from "reactstrap";

import { campaignActions } from "../../../actions";
import CreateNewTag from "./CreateNewTag";
const { requestAssignTags } = campaignActions;

const StyledCampaignProspectsAssignTagsModal = styled.div`
  padding: 1rem;
`;

const CampaignProspectsAssignTagsModal = ({ data }) => {
  const tag = useSelector((state) => state.tag.tag);
  const loading = useSelector((state) => state.campaigns.assignLoading);
  const [selectedTags, setSelectedTags] = useState(data && data.tags ? data.tags : []);
  const dispatch = useDispatch();
  const [isModalOpen, setModalOpen] = useState(false);
  function handleAssignCheck(id) {
    if (selectedTags.indexOf(id) > -1) {
      let temp = [...selectedTags];
      let index = temp.indexOf(id);
      temp.splice(index, 1);
      setSelectedTags(temp);
    } else {
      setSelectedTags((prev) => [...prev, id]);
    }
  }

  function handleAssignClick() {
    if (data)
      dispatch(
        requestAssignTags(
          data.id,
          selectedTags,
          data.identifier,
          data.targetIndex
        )
      );
  }

  return (
    <StyledCampaignProspectsAssignTagsModal>
      {isModalOpen ? (
        <CreateNewTag onHide={() => setModalOpen(false)} />
      ) : (
          <>
            <h4 style={{ marginTop: 0 }}>Assign Tags</h4>
            <div className="my-3">
              {tag.map((item) => (
                <div className="d-flex my-1" key={item._id}>
                  <Badge
                    className="d-flex align-items-center justify-content-center w-100"
                    color={item.color}
                  >
                    {item.title}
                  </Badge>
                  <div>
                    <Input
                      checked={selectedTags.some((tag) => tag === item._id)}
                      type="checkbox"
                      onChange={() => handleAssignCheck(item._id)}
                    />
                  </div>
                </div>
              ))}
            </div>
            <Button
              block
              onClick={() => {
                handleAssignClick();
              }}
              disabled={loading}
            >
              {loading && (
                <Spinner
                  className="mr-2"
                  animation="border"
                  variant="secondary"
                  size="sm"
                />
              )}
            Assign Tags
          </Button>
            <Button
              block
              className="ml-0"
              onClick={() => {
                setModalOpen(true);
              }}
              disabled={loading}
            >
              Create a new tag
          </Button>
          </>
        )}
    </StyledCampaignProspectsAssignTagsModal>
  );
};

export default CampaignProspectsAssignTagsModal;
