import React, { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Input, Badge, Button } from "reactstrap";

import { tagActions } from "../../../actions";
import CreateNewTag from "./CreateNewTag";
const { requestDeleteTag } = tagActions;

const StyledCampaignProspectsManageTagsModal = styled.div`
  padding: 1rem;
`;

const CampaignProspectsManageTagsModal = () => {
  const [isCreatingToggle, setIsCreatingToggle] = useState(false);
  const dispatch = useDispatch();
  const tag = useSelector((state) => state.tag.tag);
  const [tagName, setTagName] = useState("");
  const filteredTag = useMemo(() => {
    if (tagName === "") {
      return tag;
    } else {
      return tag.filter((item) =>
        item.title.toLowerCase().includes(tagName.toLowerCase())
      )
        ? tag.filter((item) =>
            item.title.toLowerCase().includes(tagName.toLowerCase())
          )
        : [];
    }
  }, [tagName, tag]);

  const tagInputChangeHandler = (e) => {
    e.preventDefault();
    setTagName(e.target.value);
  };
  const deleteTag = (id) => {
    dispatch(requestDeleteTag(id));
  };

  return (
    <StyledCampaignProspectsManageTagsModal>
      {isCreatingToggle ? (
        <CreateNewTag onHide={() => setIsCreatingToggle(false)} />
      ) : (
        <>
          <h4 style={{ marginTop: 0 }}>Manage Tags</h4>
          <Input
            className="mb-2"
            placeholder="Type to filter..."
            type="text"
            value={tagName}
            onChange={tagInputChangeHandler}
          />
          <div className="my-3">
            {filteredTag.map((item) => (
              <div className="d-flex my-1" key={item._id}>
                <Badge
                  className="d-flex align-items-center justify-content-center w-100"
                  color={item.color}
                >
                  {item.title}
                </Badge>
                {/* <Button
                    className="btn-round btn-icon btn-icon-mini btn-neutral my-0"
                    color="info"
                    id="tooltip825783733"
                    title=""
                    type="button"
                  >
                    <i className="nc-icon nc-ruler-pencil" />
                  </Button> */}
                <Button
                  className="btn-round btn-icon btn-icon-mini btn-neutral my-0"
                  color="info"
                  id="tooltip825783733"
                  title=""
                  type="button"
                  onClick={() => deleteTag(item._id)}
                >
                  <i className="nc-icon nc-simple-remove" />
                </Button>
              </div>
            ))}
          </div>
          <Button block onClick={() => setIsCreatingToggle(true)}>
            Create a new tag
          </Button>
        </>
      )}
    </StyledCampaignProspectsManageTagsModal>
  );
};

export default CampaignProspectsManageTagsModal;
