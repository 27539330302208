import { INTEGRATION as ACTION_HEADER } from "./types";

export function requestUpdateIntegration(integration, account) {
  return { type: ACTION_HEADER.REQUEST_UPDATE, integration, account };
}

export function successUpdateintegration(integration) {
  return { type: ACTION_HEADER.SUCCESS_UPDATE, integration };
}
export function requestAddIntegration(integration, account) {
  return { type: ACTION_HEADER.REQUEST_UPDATE, integration, account };
}

export function requestGetintegrations(liEmail) {
  return { type: ACTION_HEADER.REQUEST_GET, liEmail };
}

export function requestGetintegrationsAccount(liEmail) {
  return { type: ACTION_HEADER.REQUEST_GET_ACCOUNT, liEmail };
}

export function successGetintegrations(data) {
  return { type: ACTION_HEADER.SUCCESS_GET, data };
}

export function successGetintegrationsAccount(data) {
  return { type: ACTION_HEADER.SUCCESS_GET_ACCOUNT, data };
}

export function requestDeleteIntegration(_id, account) {
  return { type: ACTION_HEADER.REQUEST_DELETE, _id, account };
}

export function successDeleteIntegration(_id) {
  return { type: ACTION_HEADER.SUCCESS_DELETE, _id };
}

export function successDeleteIntegrationAccount(_id) {
  return { type: ACTION_HEADER.SUCCESS_DELETE_ACCOUNT, _id };
}

export function fail(error) {
  return { type: ACTION_HEADER.FAIL, error };
}

export function setSeqFetch() {
  return { type: ACTION_HEADER.NEED_FETCH };
}

export function showSuccessMessage(message) {
  return { type: ACTION_HEADER.SHOW_SUCCESS_MESSAGE, message };
}

export function hideSuccessMessage() {
  return { type: ACTION_HEADER.HIDE_SUCCESS_MESSAGE };
}

export function resetIntegration() {
  return { type: ACTION_HEADER.RESET_INTEGRATION };
}
