import React from "react";
import CampaignPage from "./Campaign/CampaignPage";
import SequencePage from "./Sequence/SequencePage";
import IntegrationPage from "./Integration/IntegrationPage";
import ProspectPage from "./Prospect";
import BlackListPage from "./BlackList";
import SettingsPage from "./SettingsPage";
import ProfileManager from "./ProfileManager";

import { withRouter } from "react-router-dom";

const ManagePage = (props) => {
  const {
    match: {
      params: { account, page },
    },
  } = props;

  if (account === "user-profile") {
    return <ProfileManager {...props} />;
  }

  switch (page) {
    case "campaigns":
      return <CampaignPage {...props} />;
    case "sequences":
      return <SequencePage {...props} />;
    case "settings":
      return <SettingsPage {...props} />;
    case "prospects":
      return <ProspectPage {...props} />;
    case "blacklist":
      return <BlackListPage {...props} />;
    case "user-profile":
      return <ProfileManager {...props} />;
    case "integration":
      return <IntegrationPage {...props} />;
    default:
      props.history.push(`/admin/${account}/campaigns`);
      return null;
  }
};

export default withRouter(React.memo(ManagePage));
