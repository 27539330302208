/* eslint-disable */
// this was added just to clear up the dev console. Pls fix warnings if its possible

import { extendMoment } from "moment-range";
import Moment from "moment-timezone";

const moment = extendMoment(Moment);

export const createActionTypes = (base, actions = []) =>
  actions.reduce((acc, type) => {
    acc[type] = `${base}_${type}`;
    return acc;
  }, {});
// export const backendUrl = "http://192.168.1.244:3000";

export const backendUrl = process.env.REACT_APP_API_URL;
// export const backendUrl = "http://api.perfectprospect.io/";
// export const backendUrl = "http://localhost:3000";
// export const backendUrl = "https://app.perfectprospect.io";
export const DEFAULT_EXPIRATION_TIME = 4320 * 60;

export const validator = {
  checkEmailValidation(email) {
    if (email === -1) {
      return false;
    }
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !email || !re.test(String(email).toLowerCase());
  },
  checkRequiredValidation(value) {
    return value !== -1 && !value;
  },
  checkPasswordValidation(password) {
    return password.length < 5;
  },
  checkBasicQueryValidation(query) {
    return (
      query !== -1 &&
      (!query.startsWith("https://www.linkedin.com/search/results/") ||
        this.checkSalesNavSavedValidation(query))
    );
  },
  checkSalesNavValidation(query) {
    return (
      query !== -1 &&
      (!query.startsWith("https://www.linkedin.com/sales/search/") ||
        this.checkSalesNavSavedValidation(query))
    );
  },
  checkSalesNavSavedValidation(query) {
    return query !== -1 && /savedSearchId/i.test(query);
  },
  checkSequenceMessageValidation(msg) {
    return (
      msg !== -1 && msg && /(first|Name)/i.test(msg) && !/{firstName}/.test(msg)
    );
  },
};

export const originToutc = (time, zone) => {
  const date = moment().format("YYYY-MM-DD");
  const momentTime = moment.tz(date + " " + time, zone);
  return momentTime.format("HH:mm");
};

export const utcTotarget = (time, zone) => {
  const date = moment().format("YYYY-MM-DD");
  const momentTime = moment(date + "T" + time + "Z");
  return momentTime.tz(zone).format("HH:mm");
};

export const campaignTypes = [
  {
    title: "Sales Navigator Search Results",
    key: "SALES_NAVIGATOR",
    disabled: false,
  },
  {
    title: "Basic LinkedIn Search Results",
    key: "BASIC_QUERY",
    disabled: false,
  },
  {
    title: "Custom Import",
    key: "CUSTOM",
    disabled: false,
  },
  // {
  //   title: "Linkedin Suggestions",
  //   key: "LI_SUGGESTIONS",
  //   disabled: true,
  // },
  //{
  //title: "My Followers",
  //key: "FOLLOWERS",
  //disabled: true,
  //},
];

const getDate = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

const getDateByTimeZone = (date, timezone) => {
  return moment(date).tz(timezone)?.format("YYYY-MM-DD");
};

export const statusCount = (data, status, result, date = "all", timezone) => {
  return status === "CONNECTED"
    ? data.filter((dat) => dat.isConnected).length
    : status === "REPLIED"
    ? data.filter(
        (dat) => getDateByTimeZone(dat.date, timezone) === getDate(date)
      ).length
    : data.reduce(
        (sum, curVal) =>
          sum +
          curVal.actions.reduce(
            (sum, action) =>
              (sum +=
                action.name === status &&
                action.result === result &&
                (date === "all" ||
                  getDateByTimeZone(action.date, timezone) === getDate(date))),
            0
          ),
        0
      );
};

export const connectedCount = (connectionData, date = "all", timezone) =>
  connectionData.filter(
    (dat) => getDateByTimeZone(dat.date, timezone) === getDate(date)
  ).length;

export const repliedCount = (repliedData, date, timezone) => {
  return repliedData.filter(
    (dat) => getDateByTimeZone(dat.date, timezone) === getDate(date)
  ).length;
};

export const recentDays = (range) =>
  [...Array(range).keys()].map((key) =>
    moment().subtract(range - key - 1, "days")
  );

export const dateRange = (start, end, timezone) => {
  return Array.from(
    moment.range(start, end).snapTo("day").by("day")
  ).map((item) => moment(item).tz(timezone));
};

export const actionToActivity = (name) =>
  name === "CONNECT"
    ? "Requested"
    : name === "VISIT"
    ? "Visited"
    : name === "MESSAGE"
    ? "Messaged"
    : name === "FOLLOW"
    ? "Followed"
    : name;

export const activityToDescription = (activity, name) => {
  switch (activity) {
    case "Requested":
      return `Sent connection request to ${name}`;
    case "Accepted":
      return `${name} accepted your connection request`;
    case "Messaged":
      return `Sent a message to ${name}`;
    case "Visited":
      return `Visited ${name}`;
    case "Followed":
      return `Followed ${name}`;
    case "Replied":
      return `${name} sent a reply!`;
    case "INMAIL":
      return `Sent inmail to ${name}`;
    case "REQUEST_FAILED":
      return `Request failed to ${name}`;
    case "EMAIL_REQUIRED":
      return `Email Required from ${name}`;
    case "SKIPPED":
      return `Skipped ${name}`;
    case "OTHER_CAMPAIGN":
      return `${name} is on the other campaign`;
    case "MESSAGE_FAILED":
      return `Send message failed to ${name}`;
    case "VISIT_FAILED":
      return `Visit ${name} failed `;
    case "ERROR":
      return `Error occured to ${name}`;
    case "INMAIL_FAILED":
      return `Sent INMAIL failed to ${name}`;
    case "INMAIL_EXIT":
      return `INMAIL exist to ${name}`;
    case "INMAIL_NO_FREE":
      return `Send INMAIL is not free to ${name}`;
    case "IN_BLOCKLIST":
      return `${name} is in linkedIn block list`;
    case "LI_MEMBER":
      return `You need a SaleNav Team Membership for ${name}`;
    case "NO_CONNECT_OPTION":
      return `There is not connect option to ${name}`;
    default:
      return `Unknown action to ${name}`;
  }
};

export const progress = (
  targetsLength,
  dataLength,
  totalProspects,
  searchDone
) => {
  const doneSeqs = searchDone ? targetsLength : dataLength;
  let totalSeqs;
  if (targetsLength > totalProspects) {
    totalSeqs = targetsLength;
  } else {
    totalSeqs = totalProspects;
  }

  if (totalSeqs && doneSeqs > totalSeqs) {
    return 100.0;
  } else if (searchDone) {
    return 100.0;
  } else if (totalSeqs) {
    const result = ((doneSeqs / totalSeqs) * 100).toFixed(2);
    return result;
  } else {
    return 0.0;
  }
};

export const formatAgo = (dateFrm, timezone) => {
  const date = moment(dateFrm).tz(timezone);
  const yrDiff = moment().diff(date, "year");
  if (yrDiff) {
    return `${yrDiff} ${yrDiff > 1 ? "years" : "year"} ago`;
  }
  const moDiff = moment().diff(date, "month");
  if (moDiff) {
    return `${moDiff} ${moDiff > 1 ? "months" : "month"} ago`;
  }
  const dyDiff = moment().diff(date, "day");
  if (dyDiff) {
    return `${dyDiff} ${dyDiff > 1 ? "days" : "day"} ago`;
  }
  const hrDiff = moment().diff(date, "hour");
  if (hrDiff) {
    return `${hrDiff} ${hrDiff > 1 ? "hours" : "hour"} ago`;
  }
  const minDiff = moment().diff(date, "minute");
  if (minDiff) {
    return `${minDiff} ${minDiff > 1 ? "minutes" : "minute"} ago`;
  }
  const secDiff = moment().diff(date, "second");
  if (secDiff) {
    return `${secDiff} ${secDiff > 1 ? "seconds" : "second"} ago`;
  }
};

export const isSafari = () => {
  var ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf("safari") != -1) {
    if (ua.indexOf("chrome") > -1) {
      return false;
    } else {
      return true;
    }
  }
  return false;
};

export const validURL = (str) => {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};
export const replaceEmojiAndTitle = (str) => {
  if (typeof str === "string") {
    return str
      .replace(
        /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g,
        ""
      )
      .replace("Mr.", "")
      .replace("Mrs.", "")
      .replace("Ms.", "")
      .replace("Miss.", "")
      .replace("Dr.", "")
      .replace("PhD.", "")
      .trim();
  }
  return "";
};

export const getConnectionData = (targets, connectionData) => {
  let campaignConnected = connectionData.filter(
    (data) =>
      targets.filter(
        (target) => `${data.firstName} ${data.lastName}` === target.name
      ).length > 0
  );
  campaignConnected = campaignConnected.map((data) => ({
    ...data,
    targetName: `${data.firstName} ${data.lastName}`,
  }));
  return campaignConnected;
};

export const getRepliedData = (targets, repliedData) => {
  let campaignReplied = repliedData.filter(
    (data) =>
      targets.filter(
        (target) => `${data.firstName} ${data.lastName}` === target.name
      ).length > 0
  );
  campaignReplied = campaignReplied.map((data) => ({
    ...data,
    date: data.createdAt,
    targetName: `${data.firstName} ${data.lastName}`,
  }));
  return campaignReplied;
};

export const tagColorList = [
  "success",
  "info",
  "dull-orange",
  "warning",
  "magenta",
  "danger",
  "grey",
  "primary",
];
