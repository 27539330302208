import React, { useState, useRef } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import NotificationAlert from "react-notification-alert";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
// drag and drop with react-beautiful-dnd
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// reactstrap components
import {
  Button,
  Alert,
  Row,
  Col,
  Input,
  Form,
  FormFeedback,
  InputGroup,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import {
  SequenceCard,
  InmailCard,
} from "../../components/sequences/SequenceCard";

import { sequenceActions } from "../../../actions";
// form validator
import { validator } from "../../../utils";
import { useEffect } from "react";

const { requestUpdateSequence, hideSuccessMessage } = sequenceActions;

const SequenceAddEditForm = ({
  sequences,
  error,
  loading,
  requestUpdateSequence,
  requestHideSuccess,
  liaccount,
  successMessage,
  ...restProps
}) => {
  const { identifier } = restProps.match.params;

  const [horizontalTabs, setHorizontalTabs] = useState("normal");

  const [steps, setSteps] = useState([
    {
      name: "VISIT",
      toggle: false,
      timeoutNumber: 0,
      timeoutUnit: "hours",
    },
    // {
    //   name: "FOLLOW",
    //   toggle: false,
    //   timeoutNumber: 1,
    //   timeoutUnit: "minutes"
    // },
    {
      name: "CONNECT",
      toggle: false,
      timeoutNumber: 1,
      timeoutUnit: "hours",
      msg: "",
    },
    {
      name: "MESSAGE",
      toggle: false,
      timeoutNumber: 1,
      timeoutUnit: "hours",
      msg: -1,
      stopPrevDetected: true,
    },
  ]);
  const [name, setName] = useState(-1);
  const [formError, setFormError] = useState("");
  const [messageCount, setMessageCount] = useState(1);

  const [inMail, setInMail] = useState({
    name: "INMAIL",
    toggle: false,
    timeoutNumber: 0,
    timeoutUnit: "hours",
    subject: -1,
    msg: -1,
    sendOpenProfiles: true,
  });

  const notificationAlert = useRef();

  const mode = identifier.slice(0, 4);
  const seqId = identifier.slice(5);
  const sequence = sequences.filter((seq) => seq.identifier === seqId)[0];

  useEffect(() => {
    const seqId = identifier.slice(5);

    const sequence = sequences.filter((seq) => seq.identifier === seqId)[0];
    if (sequence) {
      setName(sequence.name);
      if (sequence.sequences[0].name === "INMAIL") {
        setHorizontalTabs("inmail");
        setInMail({ ...sequence.sequences[0] });
      } else setSteps(sequence.sequences);
    }
  }, [sequences, identifier]);

  useEffect(() => {
    if (successMessage) notify("success");
  }, [successMessage]); //eslint-disable-line react-hooks/exhaustive-deps

  const changeName = (e) => {
    setName(e.target.value);
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    if (result.destination.index > 2) {
      return;
    }
    const newSteps = steps.slice(0);
    newSteps.splice(
      result.destination.index,
      0,
      newSteps.splice(result.source.index, 1)[0]
    );
    setSteps(newSteps);
  };

  const toggleSequence = (index) => {
    const newSteps = steps.slice(0);
    newSteps[index].toggle = !newSteps[index].toggle;
    setSteps(newSteps);
  };

  const changeTimeoutNumber = (index) => (number) => {
    const newSteps = steps.slice(0);
    newSteps[index].timeoutNumber = number.replace(/\+/gi, "");
    setSteps(newSteps);
  };

  const changeTimeoutUnit = (index) => (unit) => {
    const newSteps = steps.slice(0);
    newSteps[index].timeoutUnit = unit;
    setSteps(newSteps);
  };

  const setMessage = (index) => (message) => {
    const newSteps = steps.slice(0);
    if (newSteps[index].name === "connect" && message.length > 275) {
      return;
    }
    newSteps[index].msg = message;
    setSteps(newSteps);
  };

  const toggleStopPrevDetected = (index) => {
    const newSteps = steps.slice(0);
    newSteps[index].stopPrevDetected = !newSteps[index].stopPrevDetected;
    setSteps(newSteps);
  };

  const addMessage = () => {
    let tmp_count = messageCount;
    if (tmp_count > 10) return;
    // console.log(tmp_count);
    setMessageCount(tmp_count + 1);

    let newMsg = {
      index: tmp_count,
      name: "MESSAGE",
      toggle: false,
      timeoutNumber: 1,
      timeoutUnit: "days",
      msg: -1,
      stopPrevDetected: true,
    };
    // console.log(newMsg);
    setSteps([...steps, newMsg]);
  };

  const convertToSeconds = (number, unit) => {
    switch (unit) {
      case "minutes":
        return number * 60;
      case "hours":
        return number * 3600;
      case "days":
        return number * 3600 * 24;
      default:
        return number * 3600 * 24;
    }
  };

  const hideSuccessMessage = () => {
    requestHideSuccess();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (name === -1) {
      setName("");
      setFormError("Please enter the sequence name.");
      return;
    }
    if (validator.checkRequiredValidation(name)) {
      setFormError("Please enter the sequence name.");
      return;
    }
    if (
      sequences.findIndex((seq) => seq.name === name.trim()) !== -1 &&
      mode !== "edit"
    ) {
      setFormError("Sequence name is duplicated. Please use another name.");
      return;
    }
    if (
      sequences.findIndex((seq) => seq.name === name.trim() && seq._id !== sequence._id) !== -1 &&
      mode == "edit"
    ) {
      setFormError("Sequence name is duplicated. Please use another name.");
      return;
    }

    if (horizontalTabs === "inmail") {
      if (!inMail.toggle) {
        setFormError("You need to add at least one step.");
        return;
      }

      if (inMail.subject === -1) {
        handleInMailSubject("");
        setFormError("Please enter the subject.");
        return;
      }

      if (validator.checkRequiredValidation(inMail.subject)) {
        setFormError("Please enter the subject.");
        return;
      }

      if (inMail.msg === -1) {
        handleInMailMsg("");
        setFormError("Please enter the message.");
        return;
      }

      if (validator.checkRequiredValidation(inMail.msg)) {
        setFormError("Please enter the message.");
        return;
      }

      if (validator.checkSequenceMessageValidation(inMail.msg)) {
        setFormError(
          "Your firstName syntax is wrong. You must input {firstName}"
        );
        return;
      }
    } else if (horizontalTabs === "normal") {
      if (steps.reduce((sum, step) => (sum += step.toggle ? 1 : 0), 0) === 0) {
        setFormError("You need to add at least one step.");
        return;
      }

      for (let index = 0; index < steps.length; index++) {
        const step = steps[index];
        if (step.name === "MESSAGE") {
          if (step.toggle) {
            if (step.msg === -1) {
              setMessage(index)("");
            }
            if (validator.checkRequiredValidation(step.msg)) {
              setFormError(
                "Please enter the message for the " + step.name + " step."
              );
              return;
            }
          }
        }
      }

      for (let step of steps) {
        if (validator.checkSequenceMessageValidation(step.msg)) {
          setFormError(
            "Your firstName syntax is wrong. You must input {firstName}"
          );
          return;
        }
      }
    }

    setFormError("");

    const { account } = restProps.match.params;
    const liEmail = liaccount.email;

    if (horizontalTabs === "normal") {
      const tobkdSteps = steps
        .map(
          ({
            name,
            msg,
            stopPrevDetected,
            timeoutNumber,
            timeoutUnit,
            toggle,
          }) =>
            toggle
              ? {
                name: name.toUpperCase(),
                msg,
                option: stopPrevDetected,
                timeout: convertToSeconds(timeoutNumber, timeoutUnit),
              }
              : null
        )
        .filter(Boolean)
        .map((seq, index) => ({
          ...seq,
          timeout: index > 0 ? seq.timeout : 0,
        }));

      requestUpdateSequence(
        {
          steps: tobkdSteps,
          name: name.trim(),
          _id: sequence && mode != "copy" ? sequence._id : undefined,
          liEmail,
        },
        account
      );
    } else if (horizontalTabs === "inmail") {
      if (inMail.toggle) {
        requestUpdateSequence(
          {
            steps: [
              {
                name: inMail.name.toUpperCase(),
                subject: inMail.subject,
                msg: inMail.msg,
                option: inMail.sendOpenProfiles,
                timeout: 0,
              },
            ],
            name: name.trim(),
            _id: sequence ? sequence._id : undefined,
            liEmail,
          },
          account
        );
      }
    }
  };

  //INMAIL FUNCTIONS

  const handleInMailToggle = () => {
    setInMail((prev) => {
      return { ...prev, toggle: !prev.toggle };
    });
  };

  const handleSendOpenProfilesToggle = () => {
    setInMail((prev) => {
      return { ...prev, sendOpenProfiles: !prev.sendOpenProfiles };
    });
  };

  const handleInMailSubject = (subject) => {
    setInMail((prev) => {
      return { ...prev, subject: subject };
    });
  };

  const handleInMailMsg = (msg) => {
    setInMail((prev) => {
      return { ...prev, msg: msg };
    });
  };

  const notify = (notifyType) => {
    let options = {
      place: "tc",
      message:
        notifyType === "existing" ? (
          <div>
            <div>
              Switching to{" "}
              <b>{horizontalTabs === "normal" ? "INMAIL" : "STANDARD"}</b> is
              not available for the existing campaign. Please create another
              Sequence and Campaign.
            </div>
          </div>
        ) : notifyType === "steps" ? (
          <div>
            <div>
              Please disable all <b>{inMail.toggle ? "INMAIL" : "STANDARD"}</b>{" "}
              steps in order to switch to the{" "}
              <b>{inMail.toggle ? "STANDARD" : "INMAIL"}</b> sequence.
            </div>
          </div>
        ) : (
              <div>
                <div>Sequence updated.</div>
              </div>
            ),
      type: notifyType === "success" ? "success" : "danger",
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
    if (notifyType === "success") hideSuccessMessage();
  };

  const checkStandard = () => {
    return steps.some((item) => item.toggle);
  };
  // const goBack = e => {
  //   e.preventDefault();
  //   const { account } = restProps.match.params;
  //   restProps.history.push(`/admin/${account}/sequences`);
  // };

  return (
    <div className="content sequencelist">
      <LoadingOverlay
        active={loading}
        spinner
        text="Loading Sequence"
      ></LoadingOverlay>
      <NotificationAlert ref={notificationAlert} />
      <Form className="form" method="" onSubmit={handleSubmit}>
        <Row>
          <Col md="9" xs="12">
            <Col xs="12" xl="12" className="ml-auto mr-auto">
              <h4>Sequence Builder</h4>
              <InputGroup className="sequence-name-field">
                <Input
                  disabled={
                    sequences.findIndex((seq) => seq.id === seqId) !==
                    -1
                  }
                  defaultValue={name !== -1 ? name : ""}
                  className="fade-in"
                  invalid={validator.checkRequiredValidation(name)}
                  onChange={changeName}
                  placeholder="Enter sequence name"
                ></Input>
                <FormFeedback>
                  <span>Sequence name is required</span>
                </FormFeedback>
              </InputGroup>

              {<div className="nav-tabs-navigation">
                <div className="nav-tabs-wrapper">
                  <Nav id="tabs" role="tablist" tabs>
                    <NavItem>
                      <NavLink
                        aria-expanded={horizontalTabs === "normal"}
                        data-toggle="tab"
                        role="tab"
                        className={horizontalTabs === "normal" ? "active" : ""}
                        onClick={() => {
                          if (sequence && horizontalTabs === "inmail") {
                            notify("existing");
                          } else {
                            if (inMail.toggle) {
                              notify("steps");
                            } else setHorizontalTabs("normal");
                          }
                        }}
                      >
                        Standard
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        aria-expanded={horizontalTabs === "inmail"}
                        data-toggle="tab"
                        role="tab"
                        className={horizontalTabs === "inmail" ? "active" : ""}
                        onClick={() => {
                          if (sequence && horizontalTabs === "normal") {
                            notify("existing");
                          } else {
                            if (checkStandard()) {
                              notify("steps");
                            } else setHorizontalTabs("inmail");
                          }
                        }}
                      >
                        Inmail
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </div>}
              {<TabContent
                className="text-center"
                id="my-tab-content"
                activeTab={horizontalTabs}
              >
                <TabPane tabId="normal" role="tabpanel">
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="dropstep">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {steps.map((step, index) => (
                            <Draggable
                              key={step.name + index}
                              draggableId={step.name + index}
                              index={index}
                              id={index}
                              isDragDisabled={
                                index > 2 ? true : false && mode === "view"
                              }
                            >
                              {(provided) => (
                                <SequenceCard
                                  key={step.name + index}
                                  keyConnect={(index + 10) * 3}
                                  key2={step.name + (index + 30) * 2}
                                  sequence={step}
                                  cardRef={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={{
                                    ...provided.draggableProps.style,
                                    padding: 10,
                                  }}
                                  toggleSequence={() => toggleSequence(index)}
                                  changeTimeoutNumber={changeTimeoutNumber(
                                    index
                                  )}
                                  changeTimeoutUnit={changeTimeoutUnit(index)}
                                  setMessage={setMessage(index)}
                                  toggleStopPrevDetected={() =>
                                    toggleStopPrevDetected(index)
                                  }
                                  indexOn={steps
                                    .slice(0, index + 1)
                                    .reduce(
                                      (sum, step) =>
                                        (sum += step.toggle ? 1 : 0),
                                      0
                                    )}
                                  draggable={mode === "new" || mode === "edit" || mode === "copy"}
                                  controllable={mode !== "view"}
                                />
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>

                  <Col xs={11}>
                    <Row>
                      <div className="col-md-12 text-center">
                        <Button
                          onClick={() => addMessage()}
                          className="btn-round text-center btn add-btn btn-icon"
                        >
                          <i className="nc-icon nc-simple-add" />
                        </Button>
                      </div>
                    </Row>
                  </Col>
                </TabPane>
                <TabPane tabId="inmail" role="tabpanel">
                  <InmailCard
                    inmail={inMail}
                    handleInMailToggle={handleInMailToggle}
                    handleInMailSubject={handleInMailSubject}
                    handleInMailMsg={handleInMailMsg}
                    handleSendOpenProfilesToggle={handleSendOpenProfilesToggle}
                  ></InmailCard>
                </TabPane>
              </TabContent>}
              <Col xs={11}>
                <Row>
                  {formError && (
                    <InputGroup>
                      <Input hidden invalid />
                      <FormFeedback>
                        <Alert color="danger" fade={true}>
                          <span>{formError}</span>
                        </Alert>
                      </FormFeedback>
                    </InputGroup>
                  )}
                </Row>

                <Row
                  className="pull-right"
                  style={{ float: "right", marginTop: 20 }}
                >
                  <div>
                    {/*<Button onClick={goBack}>
                      <i className="nc-icon nc-minimal-left"></i>Back
                    </Button>*/}
                    {mode !== "view" && <Button color="primary">Save</Button>}
                  </div>
                </Row>
              </Col>
            </Col>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  liaccount: state.liaccounts.liaccount,
  sequences: state.sequences.sequences,
  loading: state.sequences.loading,
  successMessage: state.sequences.successMessage,
});

const mapDispatchToProps = (dispatch) => ({
  requestUpdateSequence: (sequence, account) =>
    dispatch(requestUpdateSequence(sequence, account)),
  requestHideSuccess: () => dispatch(hideSuccessMessage()),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps)(SequenceAddEditForm)
);
